import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function OnBoardApp() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [oktaAppIDArray, setOktaAppIDArray] = useState([])
  const history = useHistory()
  const resData = JSON.parse(localStorage.getItem('okta-token-storage'));
  const ConsumerAppIds = resData.accessToken.claims.pepappconsumerroles.map( appid => appid.split('-')[1])
  const roles = resData?.accessToken.claims.pepappconsumerroles;
  let roleNames = roles?.map((role) => role.split("consumeriam-")[1]);
  let adminFind = roleNames?.includes("admin");
  const AccessToken = resData.accessToken.accessToken;
  const [oktaAppId, setOktaAppId] = useState('');
  const [dateChange, setDateChange] = useState('');
  const [phaseName, setPhaseName] = useState('');
  const [stagekey, setStagekey] = useState('')
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)

  const BasicValidationSchema = yup.object({
    appName: yup.string().required("please Select Application"),
    startDate: yup.string().required("Please Select Date"),
    currentStage: yup.string()
  })
  const {control,  formState: {errors}, handleSubmit, setValue} = useForm({
    mode: 'onChange',
    defaultValues:{
      appName: '',
      startDate: '',
      currentStage: ''
    },
    resolver: yupResolver(BasicValidationSchema)
  })


  const getOktaAppID = async () => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-dashboard-applications`,{
        "consumerList" : adminFind ? [] : ConsumerAppIds
      },
      {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => {
        let optionsOktaAppArr = []
        res.data.map((item) => {
          if(adminFind){
            optionsOktaAppArr.push({
              'label': item.label,
              'id': item.id,
              'appKey':item.appKey
            })
          }else if(!adminFind){
                optionsOktaAppArr.push({
                  'label': item.label,
                  'id': item.id,
                  'appKey': item.appKey
                })
          }  
          return item
        })
        setOktaAppIDArray(optionsOktaAppArr)
      })
      .catch(err => console.log(err))
  }

  const getStages = async (appKey) => {
      await axios.get( `${REACT_APP_API_ENDPOINT}admin/dashboard/get-apps-next-stage/${appKey}`,{
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then( res => {
         setValue('appName', appKey)
         setPhaseName(res.data[0]?.phaseName)
         setStagekey(res.data[0].stageKey)
      }).catch( err => {
         console.log(err)
      })
  }

  useEffect( () => {
    setValue('currentStage', '')
     if(oktaAppId){
       getStages(oktaAppId)
     }
     //eslint-disable-next-line
  }, [oktaAppId])

  const onSubmit = async (data) =>{
    let DateObject = new Date(dateChange)
    let year = DateObject.getFullYear()
    let month = DateObject.getMonth() + 1
    let day = DateObject.getDate()
    let FormattedDate = year+ '-'+ month +'-'+day
    await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/insert-apps-stags`,{
      appKey: oktaAppId,
      stageKey: stagekey,
      startedOn: FormattedDate
    }, {
      headers: {
        Authorization: `Bearer ${AccessToken}`,
      }
    }).then(res => {
      if(res.data.responseStatus === 'SUCCESS'){
        alert(res.data.messgae)
        history.push('/dashboard')
      }else{
        alert(res.data.messgae)
      }
    }).catch( err => {
       console.log(err)
    })
    
  }

  

  useEffect( () => {
     getOktaAppID()
     //eslint-disable-next-line
  },[])

  return (
    <div
        className={IconFlag ? "content-inner" : "content-inner-compress-data"}
    >
    <div className="container-fluid">
      <div className="page-name">
          <h4>OnBoard Application</h4>
      </div>
            <div className="widget has-shadow">
                <div className="widget-body">
                  <form className="form-card">
                  <div className="row d-flex justify-content-between align-items-center text-left">
                        <div className=" form-group col-sm-6">
                      <Controller
                        control={control}
                        name='appName'
                        required
                        
                        render={ ( {field}) => (
                          <FormControl   fullWidth>
                            <InputLabel id="demo-multiple-chip-label">Okta Application Name*</InputLabel>
                          <Select
                            {...field}
                            label="Okta Application Name*"
                            MenuProps={MenuProps}
                            onChange={ (data) => {
                              field.onChange(data.target.value)
                              setOktaAppId(data.target.value)
                            }}
                           
                          >
                            {
                              oktaAppIDArray.length > 0 ?
                              oktaAppIDArray.map( item => (
                                <MenuItem key={item.label} value={item.appKey}>{item.label}</MenuItem>
                              ))
                              : null
                            }
                          </Select>
                          </FormControl>
                        )}
                      />
                      <Typography variant='inherit' color="error">{errors?.appName?.message}</Typography>
                        </div>
                        <div className=" form-group col-sm-6 " style={{ position:'relative', top:'-4px'}} >
                        <Controller
                      name="startDate"
                      control={control}
                      defaultValue={null}
                      render={({ field, ...props }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                  label={stagekey > 4 ? 'End Date*' : 'Start Date*'}
                                  onChange={ (date) => {
                                    field.onChange(new Date(date))
                                    setDateChange(new Date(date))
                                 }}
                                />
                              </DemoContainer>
                          </LocalizationProvider>
                        );
                      }}
                    />
                          <Typography variant='inherit' color="error">{errors?.startDate?.message}</Typography>
                        </div>
                        <div className="contactEmails form-group col-sm-6 flex-column d-flex">
                          <Controller
                            control={control}
                            name='currentStage'
                            render= { ( {field}) => (
                              <TextField {...field}
                                value={phaseName}
                                label="Stage Name" 
                                disabled
                                
                              />
                            )}
                          />
                <Typography variant='inherit' color="error"> {errors.currentStage?.message} </Typography>
              </div>
                  </div>
              <hr />
            <div className="row text-left">
              <div className="form-group col-sm-3 flex-column d-flex">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
              </div>
            </div>
                  </form>
                </div>
            </div>
    </div>
    </div>
  )
}

export default OnBoardApp