import '../../Email-Template-Preview/emailTempPreview.css'
import { useEffect, useState } from 'react';
import JSONInput from 'react-json-editor-ajrm';

// Modal.setAppElement('#modal');

function CustomCreateUser({ paragraph, emailLogo, id }) {

    let paragraphInnerHTML = paragraph.innerHTML.replaceAll("{{logoURL}}", emailLogo);

    const [content, setContent] = useState(paragraphInnerHTML);

    const createUserPayload = {
        firstName: "",
        lastName: "",
        consumerAppName: "",
        email: "",
        logoURL: emailLogo
    }

    const forgotPasswordPayload = {
        firstName:"",
        lastName:"",
        email:"",
        consumerAppName: '',
        //eslint-disable-next-line
        resetURL: 'javascript:void(0)',
        logoURL: emailLogo
    }

    const changePassword = {
        consumerAppName: '',
        logoURL: emailLogo
    }

    const CreateUserActivatepayload = {
        consumerAppName: "",
        firstName: "",
        lastName: "",
        resetURL: "",
        logoURL: emailLogo
      }

    let payload;

    if (id === '1') {
        payload = createUserPayload
    } else if (id === '2') {
        payload = forgotPasswordPayload
    } else if (id === '3') {
        payload = changePassword
    } else if (id === '4') {
        payload = CreateUserActivatepayload
    }

    //eslint-disable-next-line
    const [data, setData] = useState(payload);
   

    const handleChange = (e) => {
        try {
            const json = e.jsObject;
            const iFrame = document.getElementById('hidden-pane');
            const iBody = iFrame.contentDocument.getElementsByTagName('body')[0];
            let iContent = iBody.innerHTML.toString();
            Object.keys(json).forEach(function (key) {
                iContent = iContent.replaceAll("{{" + key + "}}", json[key])
            })
            setContent(iContent);
        } catch (e) {
            console.log("Invalid json");
        }
    }

    useEffect(() => {
        const iFrame = document.getElementById('hidden-pane');
        if (iFrame.contentDocument.getElementsByTagName('style').length > 0) {
            const iStyle = iFrame.contentDocument.getElementsByTagName('style');
            for(let i=0;i<iStyle.length;i++){
                const styleNode = document.createElement('style');
                const styleText = document.createTextNode(iStyle[i].innerHTML);
                styleNode.appendChild(styleText);
                document.getElementById('preview-pane').contentWindow.document.head.appendChild(styleNode);
            }
        }
        document.getElementById('preview-pane').contentWindow.document.body.innerHTML = content;
    })

    return (
        <div className="App">
            <iframe style={{ "display": "none" }} title="createuser-hidden" id="hidden-pane" width="100%" height="500px" srcDoc={paragraphInnerHTML}>
            </iframe>
            <div>
                <div >
                    <div style={{ "width": "30%", "float": "left" }}>
                        <JSONInput
                            id='json_input'
                            placeholder={data}
                            height='550px'
                            onChange={handleChange}
                        />
                    </div>
                    <div style={{ "width": "70%", "float": "left" }}>
                        <iframe id="preview-pane" title="createuser-dsiplayed" width="100%" height="500px">
                        </iframe>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default CustomCreateUser;
