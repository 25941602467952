import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, useParams } from 'react-router';

import { useLocation } from 'react-router'
import { TextField } from '@mui/material';
import '../language-style.css'

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';

function AddAppNewLanguages() {

    const { REACT_APP_API_ENDPOINT } = process.env;

    // const UserID = resData.accessToken.claims.uid;
    const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
    const Data = useParams()
    let conAppId = DOMPurify.sanitize(Data.conAppId)
    const roles = ["uh-admin"];
    let roleNames = roles.map(role => role.split('uh-')[1])
    let adminFind = roleNames.includes('admin')

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const langName = DOMPurify.sanitize(query.get("langName"))
    const langCode = DOMPurify.sanitize(query.get("langCode"))

    const [file, setFile] = useState()
    const [fileName, setFileName] = useState('')

    function handleChange(e) {
        setFile(e.target.files[0])
        setFileName(e.target.files[0].name)
    }

    useEffect(() => {

    }, [file])

    const history = useHistory()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            langName: langName ? langName : "",
            langCode: langCode ? langCode : ""
        },
        validationSchema: yup.object({
            langName: yup
                .string()
                .required("Language Name is Required"),
            langCode: yup
                .string()
                .required("Language Code is Required")
        }),
        onSubmit: (userInputData) => {

            appconfigSubmit(userInputData.langName, userInputData.langCode)
            
        },
    });

    async function appconfigSubmit(langName, langCode) {
        const formData = new FormData();
        formData.append('file', file)
        formData.append('langName', langName)
        formData.append('langCode', langCode)
        await axios
            .post(`${REACT_APP_API_ENDPOINT}admin/i18n/uploadFile/${conAppId}`,
                formData)
            .then((res) => {
                const path = `/appconfig`
                history.push(path)
            }).catch( err => {
                console.log({err})
            })
    }

  return (
    <>
        <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
            <div className="container-fluid" style={{ padding: '30px'}}>
                {
                    adminFind ? <><Link to='/appconfig' style={{ color: '#0047BA' }} className='addLink'>
                        <span style={{ color: '#0047BA' }}>
                            <ArrowLeftIcon className='MuiSvgIcon-fontSizeLarge' />
                        </span>
                        <span>
                            <p className="name" style={{ marginBottom: '0', marginLeft: '5px' }}>Back to Applications</p>
                        </span>
                    </Link></> : <></>
                }
                <div className='page-name'>
                    <h4 style={{paddingLeft:'15px'}}>
                        Add Language
                    </h4>
                </div>
                <div className="widget has-shadow">

                    <div className="widget-body">

                        <form className='form-card'>
                            <div className="row  text-left">

                                <div className='langName form-group col-sm-4 flex-column d-flex'>
                                    <TextField
                                        label="Language"
                                        variant="outlined"
                                        name="langName"
                                        size='medium'
                                        value={formik.values.langName}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.langName ? (
                                        <span style={{ color: 'red', fontSize: '10px' }}>
                                            {formik.errors.langName}
                                        </span>
                                    ) : null}
                                </div>
                                <div className='langCode form-group col-sm-4 flex-column d-flex'>
                                    <TextField
                                        label="Language Code"
                                        variant="outlined"
                                        name="langCode"
                                        size='medium'
                                        value={formik.values.langCode}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.langCode ? (
                                        <span style={{ color: 'red', fontSize: '10px' }}>
                                            {formik.errors.langCode}
                                        </span>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row  text-left align-items-center">
                                <div className='langFile form-group col-sm-4 flex-column d-flex'>
                                    <input type="file" className="form-control custom-file-input" id={3} onChange={handleChange} style={{ display: 'none' }} />

                                    <TextField label='Language File'
                                        value={fileName}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" color="primary">
                                                        <label htmlFor='3' className='fileUpload-browse'><span>Browse</span></label>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}

                                    />


                                </div>
                                <div className='langFile form-group col-sm-3 flex-column d-flex '>
                                    <button className='btn btn-primary' type="button" onClick={() => window.location.href =  `${REACT_APP_API_ENDPOINT.replace('/api/', '/assets/')}masterData/lang/labels_en.properties`} >Download Sample File</button>
                                </div>

                            </div>

                            <span className='input-group-btn' style={{ width: '20px' }}></span>
                            <hr />
                            <div className="row justify-content-start text-center d-flex align-items-start" >
                                <button className='btn btn-primary ml-3' type="submit" onClick={formik.handleSubmit}>Submit</button>
                                <button className='btn btn-secondary ml-3 col-lg-1' type="submit" onClick={formik.handleReset}>Clear</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>

        </div>
    </>
  )
}

export default AddAppNewLanguages