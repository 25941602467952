import { useOktaAuth } from '@okta/okta-react'
import React, {useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardIcon from '../../../assets/icons/adminpanel-icons/SVGs/Dashboard Blue.svg'
import AppIcon from '../../../assets/icons/adminpanel-icons/SVGs/Cosumer Blue.svg'
// import LanguageIcon from '../../../assets/icons/adminpanel-icons/SVGs/Language Blue.svg'

import {useSelector} from 'react-redux'

function Sidebar() {
  const { oktaAuth } = useOktaAuth()
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
  
  const [id, setId] = useState(1)
  const [auth, setAuth] = useState(false);
  //eslint-disable-next-line
  const [adminFind, setAdminFind] = useState(false);

  oktaAuth.isAuthenticated().then(data => {
    setAuth(data);
    if(localStorage.getItem("okta-token-storage")){
      const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
      const roles = resData?.accessToken?.claims?.pepappconsumerroles;
      let roleNames = roles?.map((role) => role.split("consumeriam-")[1]);
      setAdminFind(roleNames?.includes("admin"));
    }
  })

  const onClick = (id) => {
    setId(id)
  }


  return (
    auth ?
      <>
        <div className={IconFlag ? 'default-sidebar-open default-sidebar' : 'default-sidebar'} >
          <nav className="side-navbar box-scroll sidebar-scroll" >
            <ul className="list-unstyled">
              <li className='list-element dashboard-list-element'>
                <Link to="/dashboard" key="1" className={id === 1 ? 'active' : ''} onClick={() => onClick(1)}>
                  <img src={DashboardIcon} alt="Dashboard Icon" style={{ marginRight: '10px' }}></img>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className='list-element consumerApp-list-element'>
                <Link to="/appconfig" key="2" className={id === 2 ? 'active' : ''} onClick={() => onClick(2)}>
                  <img src={AppIcon} alt="Consumer App Icon" style={{ marginRight: '10px' }}></img>
                  <span>Applications</span>
                </Link>
              </li>
              {/* {adminFind ?
                <>
                  <li className='list-element language-list-element'>
                    <Link to="/appconfig/displayLanguages" key="4" className={id === 4 ? 'active' : ''} onClick={() => onClick(4)}>
                      <img src={LanguageIcon} alt="Language Icon" style={{ marginRight: '10px' }}></img>
                      <span>Language</span>
                    </Link>
                  </li>
                </> : <></>} */}
            </ul>
          </nav>
        </div>

      </>
      :
      <>
      </>
  )
}

export default Sidebar
