import React, { useState } from "react";
import "./styles.css";
import { useParams } from "react-router";
import classNames from "classnames";
import { useHistory } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { addAppId } from "../../Redux/Actions/add_app_id";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ChangepasswordU from "./Preview-Pages/ChangePasswordU";
import ForgotU from "./Preview-Pages/ForgotPasswordU";
import ResetU from "./Preview-Pages/ResetU";
import UpdateprofileU from "./Preview-Pages/UpdateProfileU";
import SignInU from "./Preview-Pages/SignInU";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Attribute from "./Attribute/Attribute";
import BasicAppDetails from "./Basic-App-Details/BasicAppDetails";
import CustomConfigDetails from "./Custom-Config-Details/CustomConfigDetails";
import EmailConfigDetails from "./Email-Config-Details/EmailConfigDetails";
import AppAssetConfigDetails from "./App-Asset-Config-Details/AppAssetConfigDetails";
import Register from "./Preview-Pages/DynamicRegister";
import OktaConfigDetails from "./Okta-config-details/OktaConfigDetails";
import DOMPurify from 'dompurify'

function Appconfigform() {

  const Data = useParams()
  const conAppId = DOMPurify.sanitize(Data.conAppId);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)

  // ********* ATTRIBUTE PAGE FLAGS ************

  const [Attribute1, setAttribute1] = useState(false)
  const [oktaConfig, setOktaConfig] = useState(false);
  const [customConfig, setCustomConfig] = useState(false);
  const [emailConfig, setEmailConfig] = useState(false);
  const [appAssetConfig, setAppAssetConfig] = useState(false);

  //eslint-disable-next-line
  const [fullWidth, setFullWidth] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(addAppId(conAppId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const path = `/appconfig`;
    history.push(path);
  };

  const handleChangeAttribute = (value) => {
    setTab(4)
    setCustomConfig(true)
  }

  const handleBasicAppChange = (value) => {
    setTab(2)
    setOktaConfig(true)
  }

  const handleCustomConfigChange = (value) => {
    setTab(5)
    setEmailConfig(true)
  }

  const handleEmailConfigChange = (value) => {
    setTab(6)
    setAppAssetConfig(true)
  }

  const handleAppAssetChange = (value) => {
    setTab(7)
  }

  const handleOktaConfigChange = () => {
    setTab(3)
    setAttribute1(true)
  }

  const [tab, setTab] = useState(1)

  const basicClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 1,
  });

  const basicCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 1
  });

  const oktaClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 2,
  });

  const oktaCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 2
  });

  const fieldClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 3,
  });

  const fieldCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 3
  });
  const CustomClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 4,
  });

  const CustomCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 4
  });

  const EmailClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 5,
  });

  const EmailCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 5
  });

  const AppAssetClass = classNames({
    "card-body": true,
    collapse: true,
    "pt-0": true,
    show: tab === 6,
  });

  const AppAssetCardClass = classNames({
    "card-header": true,
    "d-flex": true,
    "align-items-center": true,
    collapsed: tab === 6
  });

  return (
    <>
      <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
        <div className="container-fluid">
          <div>
            <div id="accordion-icon-left" className="accordion-icon icon-01">
              <div className="widget has-shadow">
                {/* Basic App Details */}
                <a
                  className={basicCardClass}
                  data-toggle="collapse"
                  href="#BasicAppDetails"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setTab(1)
                    }, 100);
                  }}
                >
                  <div className="card-title">Basic Application Details</div>
                </a>
                <div
                  id="BasicAppDetails"
                  className={basicClass}
                  data-parent="#accordion-icon-left"
                >
                  {tab === 1 ? <BasicAppDetails onBasicAppChange={handleBasicAppChange} /> : <></>} 
                </div>

                { /* *****  OKTA CONFIGURATION DETAILS **************** */}
                <a
                  className={oktaCardClass}
                  data-toggle="collapse"
                  href="#OKTACONFIGURATIONDETAILS"
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setTab(2)
                      setOktaConfig(true)
                    }, 100);
                  }}
                >
                  <div className="card-title">Configuration Details</div>
                </a>
                <div
                  id="OKTACONFIGURATIONDETAILS"
                  className={oktaClass}
                  data-parent="#accordion-icon-left"
                >
                  {
                    oktaConfig && tab === 2 ? <><OktaConfigDetails onOktaConfigChange={handleOktaConfigChange} /></> : <div>Loading...</div>

                  }
                </div>


                {/* ATTRIBUTE */}
                <a className={fieldCardClass}
                  data-toggle="collapse"
                  href="#ATTRIBUTE"
                  style={{ borderRadius: '5px' }}
                  onClick={() => {
                    setTimeout(() => {
                      setTab(3)
                      setAttribute1(true)
                    }, 100)
                  }}
                >
                  <div className="card-title">Custom Attributes</div>
                </a>
                <div
                  id="ATTRIBUTE"
                  className={fieldClass}
                  data-parent="#accordion-icon-left"
                >
                  {
                    Attribute1 && tab === 3 ? <><Attribute onAttributeChange={handleChangeAttribute} /> </> : <div>Loading...</div>
                  }
                </div>
                {/* Custom App Config Details */}
                <a
                  className={CustomCardClass}
                  data-toggle="collapse"
                  href="#CustomAppConfigDetails"
                  onClick={() => {
                    setTimeout(() => {
                      setTab(4)
                      setCustomConfig(true)
                    }, 100);
                  }}
                >
                  <div className="card-title">Color and Font Palette</div>
                </a>
                <div
                  id="CustomAppConfigDetails"
                  className={CustomClass}
                  data-parent="#accordion-icon-left"
                >
                  {
                    customConfig && tab === 4 ? <><CustomConfigDetails onCustomConfigChange={handleCustomConfigChange} /></> : <div>Loading...</div>
                  }


                </div>

                {/* Email Config Details  */}
                <a
                  className={EmailCardClass}
                  data-toggle="collapse"
                  href="#EmailConfigDetails"
                  onClick={() => {
                    setTimeout(() => {
                      setTab(5)
                      setEmailConfig(true)
                    }, 100);
                  }}
                >
                  <div className="card-title">Email Configuration Details</div>
                </a>
                <div
                  id="EmailConfigDetails"
                  className={EmailClass}
                  data-parent="#accordion-icon-left"
                >
                  {
                    emailConfig && tab === 5 ?  <><EmailConfigDetails onEmailConfigChange={handleEmailConfigChange} /></> : <div>Loading...</div>
                  }
                </div>
                {/* App Asset ConfigDetails */}
                <a
                  className={AppAssetCardClass}
                  data-toggle="collapse"
                  href="#AppAssetConfigDetails"
                  onClick={() => {
                    setTimeout(() => {
                      setTab(6)
                      setAppAssetConfig(true)
                    }, 100);
                  }}
                >
                  <div className="card-title">Branding</div>
                </a>
                <div
                  id="AppAssetConfigDetails"
                  className={AppAssetClass}
                  data-parent="#accordion-icon-left"
                >
                  {appAssetConfig && tab === 6 ? <><AppAssetConfigDetails onAppAssetChange={handleAppAssetChange} /></> : <div></div>}
                </div>
                {/* Back to Home, Preview buttons */}
                <div className="widget has-shadow">
                  <div className="row text-left buttons-sticky">
                    <div
                      className="form-group col-sm-3 flex-column d-flex"
                      style={{ marginBottom: "0" }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleClickOpen}
                      >
                        Preview
                      </button>
                      <Dialog
                        fullWidth={fullWidth}
                        maxWidth={false}
                        open={open}
                        onClose={handleClose}
                      >
                        <DialogActions>
                          <CancelOutlinedIcon
                            style={{
                              position: "relative",
                              left: "0",
                              cursor: "pointer",
                            }}
                            onClick={handleClose}
                          />
                        </DialogActions>
                        <Carousel 
                        >
                          <Register />
                          <ChangepasswordU />
                          <ForgotU />
                          <ResetU />
                          <SignInU />
                          <UpdateprofileU />
                        </Carousel>
                      </Dialog>
                    </div>
                    <div
                      className="form-group col-sm-3 flex-column d-flex"
                      style={{ marginBottom: "0" }}
                    >
                      <button
                        className="btn btn-secondary"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Back To Home
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appconfigform;
