// import logo from './logo.svg';
import "./emailTempPreview.css";
import React, { useEffect, useState } from "react";
import JSONInput from "react-json-editor-ajrm";
import { Modal, Button } from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button1 from "@mui/material/Button";


function CreateUserEmailTemp(props) {


  const [content, setContent] = useState("");
  const createUserPayload = {
    firstName: "",
    lastName: "",
    consumerAppName: "",
    email: "",
    logoURL: props.emailLogo,
  };
  const forgotPasswordPayload = {
    firstName:'',
    email:'',
    consumerAppName: "",
    //eslint-disable-next-line
    resetURL: `javascript:void(0)`,
    logoURL: props.emailLogo,
  }
  const changePasswordPayload = {
    consumerAppName: "",
    logoURL: props.emailLogo,
  }

  const CreateUserActivatepayload = {
    consumerAppName: "",
    firstName: "",
    lastName: "",
    resetURL: "",
    logoURL: props.emailLogo
  }

  const [data, setData] = useState();


  useEffect(() => {
    if (props.templateId === 1) {
      setData(createUserPayload);
    } else if (props.templateId === 2) {
      setData(forgotPasswordPayload);
    } else if (props.templateId === 3) {
      setData(changePasswordPayload);
    } else if( props.templateId === 4){
       setData(CreateUserActivatepayload)
    }
    //eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    try {
      const json = e.jsObject;
      const iFrame = document.getElementById("hidden-pane");
      const iBody = iFrame.contentDocument.getElementsByTagName("body")[0];
      const iImage = iFrame.contentDocument.getElementById("img-logo");
      iImage.src = props.emailLogo
      let iContent = iBody.innerHTML.toString();
      Object.keys(json).forEach(function (key) {
        iContent = iContent.replaceAll("{{" + key + "}}", json[key]);
      });
      setContent(iContent);
      setData(json);
    } catch (e) {
      console.log("Invalid json", e);

    }
  };

  const [modalShow, setModalShow] = React.useState(true);

  const onSampleTestClose = () => {
    setModalShow(false);
    const iFrame = document.getElementById("hidden-pane");
    if (iFrame.contentDocument.getElementsByTagName("style").length > 0) {
      const iStyle = iFrame.contentDocument.getElementsByTagName("style");
      for( let i=0;i<iStyle.length;i++){
        const styleNode = document.createElement("style");
        const styleText = document.createTextNode(iStyle[i].innerHTML);
        styleNode.appendChild(styleText);
        document
        .getElementById("preview-pane")
        .contentWindow.document.head.appendChild(styleNode);
      }
    document.getElementById(
      "preview-pane"
    ).contentWindow.document.body.innerHTML = content;
      }
      
    if (!content) {
      const iFrame = document.getElementById("hidden-pane");
      const iBody = iFrame.contentDocument.getElementsByTagName("body")[0];
      const iImage = iFrame.contentDocument.getElementById("img-logo");
      iImage.src = props.emailLogo
      let iContent = iBody.innerHTML.toString();
      document.getElementById(
        "preview-pane"
      ).contentWindow.document.body.innerHTML = iContent;
    }
  };

  const onSampleTestOpen = () => {
    setModalShow(true);
  };

  return (
    <div className="App">
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Enter Email Template Preview Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <JSONInput
              id="json_input"
              placeholder={data}
              height="200px"
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onSampleTestClose}>Submit</Button>
        </Modal.Footer>
      </Modal>

      <iframe
        style={{ display: "none" }}
        title="Emailtemp-hidden"
        id="hidden-pane"
        srcDoc={props.HtmlFile}
        width="100%"
        height="500px"
        sandbox="allow-downloads allow-forms allow-scripts allow-same-origin"
      ></iframe>


      <div>
        <div>
          <div style={{ width: "100%" }}>
            <Box sx={{ flexGrow: 1 }} >
              <AppBar position="static" style={{ backgroundColor: '#0047BA' }}>
                <Toolbar   >
                  <Button1 onClick={onSampleTestOpen} style={{ color: 'white', fontWeight: 'bolder', fontSize: '20px', border: '1px solid #424770' }}>
                    Email Template Preview Details
                  </Button1>
                </Toolbar>
              </AppBar>
            </Box>
          </div>
          <div style={{ width: "100%", float: "left", marginTop: '15px' }}>
            <iframe id="preview-pane" title="Emailtemp-display-all" width="100%" height="500px" style={{ border: 'none' }} ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUserEmailTemp;
