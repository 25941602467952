import React from "react";
import Table from "./table";
import { Link } from 'react-router-dom'
import AddAppIconB from '../../assets/icons/adminpanel-icons/SVGs/Add ConsumerApp_Blue.svg'
import { useSelector } from "react-redux";

function Appconfig() {

  const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
  const roles = resData?.accessToken.claims.pepappconsumerroles;
  let roleNames = roles?.map((role) => role.split("consumeriam-")[1]);
  let adminFind = roleNames?.includes("admin");

  return (
    <>
      <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
        <div className="container-fluid">
            <div className="page-header" style={{paddingBottom: '0'}}>
            <div className="row justify-content-between ">
              <div className="form-group col-sm-4 flex-column d-flex ">
              <h2 className="page-header-title">
                  <p style={{ margin: "0", padding: "15px" }}>
                    Applications
                  </p>
                </h2>
              </div>

              {adminFind ? <>
              
                <div className="form-group col-sm-4 flex-column d-flex" style={{padding:'15px'}}>
                <div className="row justify-content-end" style={{paddingRight: '10px'}}>
                <Link to='/appconfig/addNewConsumerApp'>
                <div className='addClass'>
                  <img src={AddAppIconB} alt="Add Consumer App Icon" style={{marginRight:'10px'}}></img>
                  <span>Add New Consumer App</span>
                  </div>
                  </Link>
                  </div>
              </div>
                
              </> 
              : <></>}
              </div>
              
            </div>
            <Table />
        </div>
      </div>
    </>
  );
}

export default Appconfig;
