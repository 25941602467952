import React from "react";
import PepsiCoLogo from '../../assets/img/PepsiCoLogo.png';
import AdminImg from '../../assets/img/AdminConsoleImg.png'
import './login.css'
import "../../assets/vendors/css/base/elisyam-1.1.css";
import { useOktaAuth } from "@okta/okta-react"
import { useHistory } from "react-router";

function AdminLogin() {
    const { oktaAuth } = useOktaAuth()
    const history = useHistory();
    oktaAuth.isAuthenticated().then(data => {
        if (data) {
            history.push('/appconfig');
        }
    });

    const handleSubmit = () => {
        oktaAuth.signInWithRedirect()
    }

    return (
        <>
            <div className="container-div">
                <div className="box background-div">
                    <div className="firsthalf">
                    </div>
                    <div className="secondhalf">
                    </div>
                </div>
                <div className="box stack-top">
                    <div className="logo">
                        <img src={PepsiCoLogo} alt="logo" style={{ marginBottom: '0px', height: '70px' }} />
                    </div>
                    <div className="login-page-img">
                        <img src={AdminImg} style={{ marginBottom: '0px', height: '100px' }} alt='adminimage' />
                    </div>
                    <div className="signin-title" style={{ textAlign: 'center' }}>
                        <p style={{ fontWeight: '700', color: 'black', margin: '0', fontSize: '40px', paddingTop: '10px' }}>Unified Admin App</p>
                        <p style={{ color: 'black', fontSize: '40px', position: 'relative', top: '50%' }}>Admin Console</p>
                    </div>
                    <button className="signin-btn" onClick={handleSubmit}>Sign In</button>
                </div>
            </div>
        </>
    );
}

export default AdminLogin;
