import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "./global1.css";
import DefaultLogo from '../../../assets/img/LogoBg/DefaultLogo.png'
import DefaultBack from '../../../assets/img/LogoBg/background-img.png'

import { useSelector } from "react-redux";

function UpdateprofileU() {
  const { REACT_APP_API_ENDPOINT } = process.env;

  const [data4, setData4] = useState({});
  const [attributeConfigDetails, setAttributeConfigDetails] = useState();
  const [oktaConfigDetails, setoktaConfigDetails] = useState({})

  const appID_redux_updated = useSelector((state) => state)

  async function data2() {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/rendering-service/${appID_redux_updated.allFiles.APPID}`)
      .then((res) => {
        setData4(res.data);
        setAttributeConfigDetails(res.data.attributeConfigDetails);
        setoktaConfigDetails(res.data.oktaConfigDetails)
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor = data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const Header_Color = data4.custom_properties?.headerColor 
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor

  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  return (
    <div>
      <>
        <Helmet>
          <link type="text/css" rel="stylesheet" href={cssFileLink} />
        </Helmet>
        <div className="body" style={{ fontFamily: fontFamily }}>
          <div
            className="main-bg update-sec"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
          >
            <div className="white-wrapper" >
              <div className="text-center">
                <div className="snacks-logo">
                  <img src={logo ? logo : DefaultLogo} alt="Logo" />
                </div>
                <h2 className="snacks-title" style={{ fontFamily: fontFamily, color: Header_Color }}>
                  Update Profile
                </h2>
              </div>
              <div className="wrapper-inner">
              {
                          attributeConfigDetails ? attributeConfigDetails?.baseAttributes.filter( item => item?.isSelect).map((e,i)=>{
                           if(e.key==="login"){
                            return (
                              null
                            )
                           }
                           else if(e.key==="email"){
                            return(
                              <div key={i}>                             
                                 <div className="form-group">
                                   <input type="text" className="custom-input" name={e.key}
                                   value=""
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   { e.title +  (e?.required?"*":"") }
                                   </label>
                                 </div> 
                               {
                                !oktaConfigDetails.identityFlag
                               ?
                                 <div className= "form-group" >
                                   <input
                                     className="custom-input"
                                     type="password"
                                     style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   {"Password" + (e?.required?"*":"")}
                                   </label>
                                 </div>
                              : null
                              }
                             </div>
                            )
                          }else if(e.key==="userAddress"){
                            return(
                                 <div className= "form-group" >
                                   <textarea type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   {    e.title  +  (e?.required?"*":"") }
                                   </label>
                                 </div>  
                            )
                           }
                           else if(e.type === 'array' && e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                                <div className="form-group" >
                                  <select multiple={true} className="custom-input" name={e.key}
                                    style={{ color: Input_color }}
                                  >
                                    <option value="">Please Select</option>
                                    {
                                      e.oneOf.map((option,i)=>{
                                        return(
                                          <option key={option.constVal} value={option.constVal} >{option.title}</option>
                                        )
                                      })
                                    } 
                                  </select>
                                </div>  
                            )
                           }else if(e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                                <div className="form-group" >
                                  <select  className="custom-input" name={e.key}
                                    style={{ color: Input_color }}
                                  >
                                    <option value="">Please Select</option>
                                    {
                                      e.oneOf.map((option,i)=>{
                                        return(
                                          <option key={option.constVal} value={option.constVal} >{option.title}</option>
                                        )
                                      })
                                    }
                                    
                                  </select>
                                </div>  
                            )
                           }
                           else if(e?.type === "boolean"){
                            return(
                                 <div className="form-group" >
                                   <label className="custom-checkbox">{  e.title  +  (e?.required?"*":"") } 
                                   <input 
                                      type="checkbox" 
                                      name={e.key}
                                      style={{ color: Input_color }}
                                   />
                                    <span className="checkmark"></span>
                                   </label>
                                 </div> 
                            )
                           }else if(e?.type === "array"){
                            return(
                                 <div className="form-group" >
                                   <input type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   required
                                   />
                                   <label className="custom-label">
                                     {  e.title   + (e?.required?"*":"")}
                                   </label>
                                 </div>  
                                
                               )

                           }
                           else {
                            return(
                                 <div className="form-group" >
                                   <input type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                     {  e.title + (e?.required?"*":"")}
                                   </label>
                                 </div>  
                               )
                           }  
                          }):
                          null
                        }
                        {
                          oktaConfigDetails.mfaFlag ?
                                 <div className="form-group" >
                                   <label className="custom-checkbox">Enroll for MFA
                                   <input 
                                      type="checkbox" 
                                      name="isMFA"
                                      required
                                   />
                                    <span className="checkmark"></span>
                                   </label>
                                 </div>  
                         : null
                        }

                <div className="form-group">
                  <button className="btn btn-yellow btn-block "
                    type="submit"
                    style={{
                      backgroundColor: SignIn_button_backgroundColor,
                      color: Input_color,
                    }}                  >
                    Update Account
                  </button>
                </div>
                <div className="form-group">
                  <button className="btn btn-yellow-brd btn-block"
                  style={{ color: SignIn_button_color, background: SecondaryButtonColor}}
                  >
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default UpdateprofileU;
