import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import TextField from "@mui/material/TextField";
import { oktaappid } from '../../../Redux/Actions/add_app_id';
import { useDispatch } from "react-redux";

import {Controller, useForm} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { Box, OutlinedInput,InputLabel,MenuItem,FormControl,Chip,Select } from '@mui/material';

import * as yup from "yup";
import DOMPurify from 'dompurify';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function BasicAppDetails({ onBasicAppChange }) {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const Data = useParams()
  const  conAppId  = DOMPurify.sanitize(Data.conAppId);
  const [allLang, setAllLang] = useState([]);
  const resData = JSON.parse(localStorage.getItem('okta-token-storage'));
  const roles = resData?.accessToken.claims.pepappconsumerroles;
  let roleNames = roles?.map((role) => role.split("consumeriam-")[1]);
  let adminFind = roleNames?.includes("admin");
 
  let oktaID;
  const dispatch = useDispatch();
  const [groupArr, setGroupArr] = useState([])
  const [AllGroupString, setAllGroupString]= useState([])
  //OKTA Application and group ID
  const [oktaAppIDArray, setOktaAppIDArray] = useState([])
  const [groupIDArray, setGroupIDArray] = useState([])
  const [defaultOktaID, setDefaultOktaID] = useState(null)
  const [BasicAppDetails, setBasicAppDetails] = useState(null) 

  const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim());
  //eslint-disable-next-line
  const URLvalid = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;


  const BasicValidationSchema = yup.object({
    appName: yup.string().required("App Name is Required"),
    appDescription: yup.string().required("Description is Required"),
    appRedirectionURL: yup.string().required("App URL is required").matches(URLvalid, "Enter valid URL"),
    // appRedirectionURL: yup.string(),
    consumerName: yup.string().required("App Owner is required"),
    privacyPolicy: yup.string().test('privacy policy', 'URL Must be valid', (val) =>{
      if(val){
          if(val?.match(URLvalid)){
            return true
          }else{
            return false
          }
      }
      return true
    }),
    termsAndConditions: yup.string().test('Terms & Conditions', 'URL Must be valid' , (val) => {
      if(val){
         if(val?.match(URLvalid)){
           return true
         }else{
           return false
         }
      }
      return true
    }),
    otherIssueURL: yup.string().required("Other Help Link is Required").matches(URLvalid, "Enter Valid URL"),
    OktaApplicationId: yup.string().required("Okta Application is Required"),
    oktaGroupID: yup.string().required("Okta GroupId is Required"),
    contactEmails: yup.string()
      .required('Contact emails is required')
      .transform((value) => {
        if(typeof(value) === 'string'){
          
        }else{
          value = value.toString()
        }
        return Array.from(new Set(value.length > 0 ? value?.split(',') : '')).join(',')
      })
      .test(
        'emails',
        'Invalid email address',
        (value) => value && value.split(',').every(isEmail) 
      ),
      createAccountInfo: yup.string().required("Create Account Info is Required"),
      policyMessage:yup.string(),
      createRedirectURL:yup.string(),
      resetRedirectURL:yup.string(),
      activatedRedirectURL:yup.string(),
      signInRedirectURL: yup.string().required("signInRedirectURL is Required"),
      cancelRedirectURL:yup.string(),
      enableLoginDropdown: yup.bool(),
      enableGoogleCaptcha: yup.bool(),
      enableMobileValidation: yup.bool(),
      dateOfBirth:yup.number().typeError('Age must be a number')
      .min(13, "Age must be greater than 13 years")
      .max(20, 'Age must be less than 20 years'),
      enableGmailValidation: yup.bool(),
      enableRememberMe:yup.bool()
  })

  const {control, reset, formState: {errors}, handleSubmit} = useForm({
    mode: 'onChange',
    defaultValues:{
      appName: "",
      contactEmails: [],
      appRedirectionURL: "",
      consumerName: "",
      appDescription: "",
      languages: [],
      OktaApplicationId: '',
      oktaGroupID:"",
      privacyPolicy: "",
      termsAndConditions: "",
      otherIssueURL:"",
      createAccountInfo:"",
      policyMessage:"",
      createRedirectURL:"",
      resetRedirectURL:"",
      activatedRedirectURL:"",
      signInRedirectURL:"",
      cancelRedirectURL:"",
      enableLoginDropdown: false,
      enableGoogleCaptcha:false,
      enableMobileValidation: false,
      dateOfBirth: 13,
      enableGmailValidation:false,
      enableRememberMe:false,
    },
    resolver: yupResolver(BasicValidationSchema)
  })

  const onSubmit = (data) => {
    //eslint-disable-next-line
     const OktaApplication = oktaAppIDArray.filter( item => {
       if(item.id === data.OktaApplicationId){
        return item
       }  
     })
    const OktaApplicationName =  OktaApplication[0].label
    
      if (typeof(data.contactEmails) === 'string'){

      }else{
        data.contactEmails = data.contactEmails.toString()
      }
      data.contactEmails = data.contactEmails.split(",").map( item => item.trim())
      let languagesObject = []
      if(data.languages){
        allLang.map( item => {
          data.languages.map( langauge => {
             if(item.langName === langauge){
                languagesObject.push({
                  langName: item.langName,
                  langCode: item.langCode
                })
             }
             return null
          })
          return null
        })
      }
      let OktaGroupObject = ''
      if(data.oktaGroupID){
        groupArr.map( item => {
            if( item.label === data.oktaGroupID){
              OktaGroupObject = item.id
            }
            return null
          })  
      }
      
      appconfigSubmit(
      data.appName,
      data.contactEmails,
      data.appRedirectionURL,
      data.consumerName,
      data.appDescription,
      OktaGroupObject,
      data.OktaApplicationId,
      languagesObject,
      conAppId,
      data.privacyPolicy,
      data.termsAndConditions,
      data.otherIssueURL,
      data.oktaGroupID,
      data.createAccountInfo,
      data.policyMessage,
      data.createRedirectURL,
      data.resetRedirectURL,
      data.activatedRedirectURL,
      data.signInRedirectURL,
      data.cancelRedirectURL,
      data.enableLoginDropdown,
      data.enableGoogleCaptcha,
      data.enableMobileValidation,
      data.dateOfBirth,
      data.enableGmailValidation,
      data.enableRememberMe,
      OktaApplicationName   
      )
  }
  const languagesStringList = BasicAppDetails?.languages &&  BasicAppDetails.languages.map( language => language.langName)
  const AlllanguagesString =  allLang.length > 0 && allLang.map( language =>  language.langName) 

  const [OktaGroupId, setOktaGroupId] = useState('')

  useEffect( () => {
     if(BasicAppDetails && groupArr){
      groupArr?.filter( item =>{
        if(item.id === BasicAppDetails?.oktaGroupID){
          setOktaGroupId(item.label)
        }
        return null
      })
     }
  },[BasicAppDetails, groupArr])

  useEffect(() => {
    reset({
      appName: BasicAppDetails?.appName,
      contactEmails: BasicAppDetails?.contactEmails,
      appRedirectionURL:  BasicAppDetails?.appRedirectionURL,
      consumerName: BasicAppDetails?.consumerName,
      appDescription: BasicAppDetails?.appDescription,
      OktaApplicationId: BasicAppDetails?.oktaApplicationID,
      oktaGroupID: OktaGroupId,
      languages: languagesStringList,
      privacyPolicy: BasicAppDetails?.privacyPolicy,
      termsAndConditions: BasicAppDetails?.termsAndConditions,
      otherIssueURL: BasicAppDetails?.otherIssueURL,
      createAccountInfo:BasicAppDetails?.createAccountInfo,
      policyMessage:BasicAppDetails?.policyMessage,
      createRedirectURL:BasicAppDetails?.createRedirectURL,
      resetRedirectURL:BasicAppDetails?.resetRedirectURL,
      activatedRedirectURL:BasicAppDetails?.activatedRedirectURL,
      signInRedirectURL:BasicAppDetails?.signInRedirectURL,
      cancelRedirectURL:BasicAppDetails?.cancelRedirectURL,
      enableLoginDropdown: BasicAppDetails?.otherIssue,
      enableGoogleCaptcha: BasicAppDetails?.enableGoogleCaptcha,
      enableMobileValidation: BasicAppDetails?.enableMobileCheck,
      dateOfBirth:BasicAppDetails?.minimumAge,
      enableGmailValidation:BasicAppDetails?.isGmailDomain,
      enableRememberMe:BasicAppDetails?.rememberMe,
    })
    //eslint-disable-next-line
  }, [BasicAppDetails, OktaGroupId])


  const handleReset = () => {
    reset({
      appName: "",
      contactEmails: [],
      appRedirectionURL: "",
      consumerName: "",
      appDescription: "",
      languages: [],
      OktaApplicationId: '',
      oktaGroupID:[],
      privacyPolicy: "",
      termsAndConditions: "",
      otherIssueURL:"",
      createAccountInfo:"",
      policyMessage:"",
      createRedirectURL:"",
      resetRedirectURL:"",
      activatedRedirectURL:"",
      signInRedirectURL:"",
      cancelRedirectURL:"",
      enableLoginDropdown:false,
      enableGoogleCaptcha:false,
      enableMobileValidation:false,
      dateOfBirth:13,
      enableGmailValidation:false,
      enableRememberMe:false,
    })
  }


  async function appconfigSubmit(
    appName,
    emails,
    appRedirectionURL,
    appOwner,
    appDescription,
    groupID,
    oktaID,
    langArray,
    conAppId,
    privacyPolicy,
    termsAndConditions,
    otherIssueURL,
    oktaGroupName,
    createAccountInfo,
    policyMessage,
    createRedirectURL,
    resetRedirectURL,
    activatedRedirectURL,
    signInRedirectURL,
    cancelRedirectURL,
    enableLoginDropdown,
    enableGoogleCaptcha,
    enableMobileValidation,
    dateOfBirth,
    enableGmailValidation,
    enableRememberMe,
    oktaApplicationName
  ) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}admin/save-consumner-app-basic-detail`,
        {
          appName: appName,
          contactEmails: emails,
          appRedirectionURL: appRedirectionURL,
          consumerName: appOwner,
          appDescription: appDescription,
          oktaGroupID: groupID,
          oktaApplicationID: oktaID,
          languages: langArray,
          appId: conAppId,
          privacyPolicy: privacyPolicy,
          termsAndConditions: termsAndConditions,
          otherIssueURL:otherIssueURL,
          oktaGroup: oktaGroupName,
          oktaApplication: oktaApplicationName,
          createAccountInfo: createAccountInfo,
          policyMessage:policyMessage,
          createRedirectURL:createRedirectURL,
          resetRedirectURL:resetRedirectURL,
          activatedRedirectURL:activatedRedirectURL,
          signInRedirectURL:signInRedirectURL,
          cancelRedirectURL:cancelRedirectURL,
          otherIssue: enableLoginDropdown,
          enableGoogleCaptcha:enableGoogleCaptcha,
          enableMobileCheck:enableMobileValidation,
          minimumAge:dateOfBirth,
          isGmailDomain:enableGmailValidation,
          rememberMe:enableRememberMe,
        })
      .then((res) => {
        if (res.data.responseStatus === 'SUCCESS') {
          onBasicAppChange(true)
        }
      });
  }

  const ConsumerAppDetails = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/appBasicDetail`)
      .then((res) => {
        setBasicAppDetails(res.data.appBasicDetails)
        // setLang(res.data.appBasicDetails.languages);
        setDefaultOktaID(res.data.appBasicDetails.oktaApplicationID);
        // setDefaultGroupID(res.data.appBasicDetails.oktaGroupID);
      })
      .catch((err) => { console.log(err); })
  }
  
  useEffect(() => {
    ConsumerAppDetails();
    //eslint-disable-next-line
  }, [])

  const getAllLanguage = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/i18n/getAllLanguage/${conAppId}`)
      .then((res) => {
        setAllLang(res.data.lang.map( language =>  { 
          return {
          langCode: language.langCode, langName: language.langName
          }
        }));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllLanguage();
    //eslint-disable-next-line
  }, []);

  const getOktaAppID = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-all-apps?limit=200`)
      .then((res) => {
        let optionsOktaAppArr = []
        res.data.map((item) => {
          optionsOktaAppArr.push({
            'label': item.label,
            'id': item.id
          })
          return item
        })

        const defLabelObj = optionsOktaAppArr.find(i => i.id === defaultOktaID)
        if (defLabelObj) {
          // setDefLabel({ label: defLabelObj.label, id: defaultOktaID })
          oktaID = defaultOktaID
          getGroupsByApp()
        }
        setOktaAppIDArray(optionsOktaAppArr)
      })
      .catch(err => console.log(err))
  }
  
  useEffect(() => {
    getOktaAppID();
    //eslint-disable-next-line
  }, [defaultOktaID])

  // selectedOptionAppID ? oktaID = selectedOptionAppID.id : oktaID = defaultOktaID
  // selectedOptionGroupID ? groupID = selectedOptionGroupID[0]?.id : groupID = defaultGroupID
  // // selectedOptionGroupID ? groupID = selectedOptionGroupID[0]?.id : groupID = selectedOptionGroupID?.id

  // if (!groupID) {
  //   groupID = selectedOptionGroupID?.id;
  // }

  const getGroupsByApp = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-groups-by-app/${oktaID}?limit=200`)
      .then((res) => {
        setGroupIDArray(res.data)
      })
      .catch(err => console.log(err))
  }



  useEffect( () => {
    if(groupIDArray){
     const data = groupIDArray?.map( group => group?._embedded?.group?.profile?.name)
     setAllGroupString(data)
    }
    
  },[groupIDArray])
  
  

  const getAllGroups = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/groups/get-groups`)
      .then((res) => {
        const groupArrNew = []
        res.data.map((i) => {
          groupArrNew.push({
            'id': i.id,
            'label': i.profile.name
          })
          return i
        })
        setGroupArr(groupArrNew)
      })
  }


  useEffect(() => {
    getAllGroups()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(oktaappid(defaultOktaID))
    //eslint-disable-next-line
  }, [defaultOktaID])


  return (
    <div className="container-fluid">
      <div className="widget has-shadow">
        <div className="widget-body">
          <form className="form-card">
            <div className="row justify-content-between text-left">
              <div className="appIdReadOnly form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="contactEmails"
                  render={ ( { field }) => (
                    <TextField {...field} label="Application ID"  value={conAppId} readOnly disabled />
                  )}
                />
              </div>

              <div className="consumerName form-group col-sm-4 flex-column d-flex">
              <Controller
                  control={control}
                  name="consumerName"
                  render={ ( { field}) => (
                    <TextField {...field} label="Application Owner*" error={errors.consumerName} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.consumerName?.message} </Typography>
              </div>

              <div className="appDescription form-group col-sm-4 flex-column d-flex">
              <Controller
                  control={control}
                  name="appDescription"
                  render={ ( { field}) => (
                    <TextField {...field} label="Description*" error={errors.appDescription} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.appDescription?.message} </Typography>
              </div>
            </div>
            
            <div className="row justify-content-between text-left" >
              <div className="languages form-group col-sm-4 flex-column d-flex">
              <Controller 
      control={control}
      name="languages"  
      render = {(props) => {
        return(
          <FormControl   fullWidth>
            <InputLabel id="demo-multiple-chip-label">Language</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={ props.field.value}
              onChange={(data) => props.field.onChange(data)}
              input={<OutlinedInput id="select-multiple-chip" label="Language" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value}  />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              
              {  AlllanguagesString.length > 0 && AlllanguagesString.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  {name}
                </MenuItem>
              )
              
              )}
            </Select>
          </FormControl>
        )
      }}
    />
    <Typography variant='inherit' color="error">{errors?.languages?.message}</Typography>
              </div>
              <div className="appName form-group col-sm-4 flex-column d-flex">
                  <Controller
                    control={control}
                    name='appName'
                    render = { ({ field}) => (
                      <TextField  {...field} label="Application Name*" error={errors.appName}/>
                    )}
                  />
                  <Typography variant='inherit' color="error"> {errors.appName?.message} </Typography>
              </div>
              <div className="contactEmails form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name='contactEmails'
                  render= { ( {field}) => (
                    <TextField  {...field}
                      label="Contact Email*" 
                      error={errors.contactEmails}
                    />
                  )}
                />
                <Typography variant='inherit' color="error"> {errors.contactEmails?.message} </Typography>
              </div>
            </div>

            <div className="row  text-left" >
              <div className="oktaAppID form-group col-sm-4 flex-column d-flex">
                <Controller 
                  control={control}
                  name='OktaApplicationId'
                  render={ ( {field}) => (
                    <FormControl   fullWidth>
                      <InputLabel id="demo-multiple-chip-label">Okta Application Name*</InputLabel>
                    <Select
                      {...field}
                      label="Okta Application Name*"
                      error={errors.OktaApplicationId}
                      MenuProps={MenuProps}
                      onChange={ (data) => {
                        field.onChange(data)
                        setDefaultOktaID(data.target.value)
                      } }
                      disabled={adminFind ? false : true}
                    >
                      {
                        oktaAppIDArray.length > 0 ?
                        oktaAppIDArray.map( item => (
                          <MenuItem value={item.id}>{item.label}</MenuItem>
                        ))
                        : null
                      }
                    </Select>
                    </FormControl>
                    
                  )}
                />
                 <Typography variant='inherit' color="error">{errors?.OktaApplicationId?.message}</Typography>
              </div>
              <div className="groupID form-group col-sm-4 flex-column d-flex">
              <Controller
                                    control={control}
                                    name="oktaGroupID"
                                    render={({ field }) => (
                                      <Box>
                                          <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-label1" >Group Name*</InputLabel>
                                              <Select
                                                  {...field} 
                                                  labelId="demo-simple-select-label1"
                                                  id="demo-simple-select1"
                                                  label="oktaGroupName*" 
                                                  error={errors.oktaGroupID} 
                                                  MenuProps={MenuProps}
                                                  value={field.value}
                                                  onChange={ (data) => field.onChange(data)}
                                                  input= { <OutlinedInput id="select-multiple-chip1" label="Group Name*"  />}
                                                  // renderValue= { (selected) => (
                                                  //   <Box>
                                                  //       {
                                                  //       selected.map((value) => (
                                                  //         <Chip key={value} label={value} />
                                                  //       ))}
                                                  // </Box>
                                                  // )}
                                              >
                                                  <MenuItem value="" style={{display: 'none'}}>PLease Select</MenuItem>
                                                  {
                                                      AllGroupString.length > 0 && groupIDArray.length > 0 ?   
                                                          AllGroupString?.map((item, i) => {
                                                              return (
                                                                  <MenuItem key={i} value={item}>{item}</MenuItem>
                                                              )
                                                          })  : <MenuItem value="">No Group</MenuItem>
                                                          
                                                  }
                                              </Select>
                                            </FormControl>
                                        </Box>
                                    )}
                                />
                                <Typography variant="inherit" color="error">
                                    {errors.oktaGroupID?.message}
                                </Typography>
              </div>
              
            </div>

            
            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>

            <h5 className='heading'>Create Account Form</h5>
            <hr />

            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>

            <div className='row text-left'>
              {/* <div className="policyMessage form-group col-sm-4 flex-column d-flex">
                  <Controller
                    control={control}
                    name='policyMessage'
                    render={({field}) => (
                      <TextField
                        {...field}
                        label="Policy Message"
                        error={errors.policyMessage}
                      />
                    )}
                  />
                  <Typography variant='inherit' color="error">{errors?.policyMessage?.message}</Typography>
              </div> */}
              <div className="createAccountInfo form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="createAccountInfo"
                  render={ ( { field}) => (
                    <TextField {...field} label="Create Account Info*" error={errors.createAccountInfo} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.createAccountInfo?.message} </Typography>
              </div>
              <div className="privacyPolicy form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name='privacyPolicy'
                  render={({field}) => (
                    <TextField
                      {...field}
                      label="Privacy Policy"
                      error={errors.privacyPolicy}
                    />
                  )}
                />
                <Typography variant='inherit' color="error">{errors?.privacyPolicy?.message}</Typography>
              </div>

              <div className="termsAndConditions form-group col-sm-4 flex-column d-flex">

                <Controller
                  control={control}
                  name='termsAndConditions'
                  render={({field}) =>(
                    <TextField
                      {...field}
                      label="Terms and Conditions"
                      error={errors.termsAndConditions}
                    />
                  )}
                />
                <Typography variant='inherit' color="error">{errors?.termsAndConditions?.message}</Typography>
              </div>
              <div className="otherIssueURL form-group col-sm-3 flex-column d-flex">

                <Controller
                  control={control}
                  name='otherIssueURL'
                  render={({field}) =>(
                    <TextField
                      {...field}
                      label="Other Help URL*"
                      error={errors.otherIssueURL}
                    />
                  )}
                />
                <Typography variant='inherit' color="error">{errors?.otherIssueURL?.message}</Typography>
              </div>
              <div className="DateofBirth form-group col-sm-2 flex-column d-flex">

                <Controller
                  control={control}
                  name='dateOfBirth'
                  render={({field}) =>(
                    <TextField
                      {...field}
                      label="DOB Age"
                      error={errors.dateOfBirth}
                      type='number'
                    />
                  )}
                />
                <Typography variant='inherit' color="error">{errors?.dateOfBirth?.message}</Typography>
              </div>
              <div className='enableLoginDropdown form-group col-sm-2 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableLoginDropdown"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Enable Login Dropdown"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableLoginDropdown}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableLoginDropdown?.message}
                                  </Typography>
              </div>
              <div className='enableGoogleCaptcha form-group col-sm-2 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableGoogleCaptcha"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Enable Google Captcha"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableGoogleCaptcha}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableGoogleCaptcha?.message}
                                  </Typography>
              </div>

              <div className='enableMobileValidation form-group col-sm-2 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableMobileValidation"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Phone Validation"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableMobileValidation}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableMobileValidation?.message}
                                  </Typography>
              </div>
              <div className='enableGmailValidation form-group col-sm-2 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableGmailValidation"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Gmail Validation"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableGmailValidation}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableGmailValidation?.message}
                                  </Typography>
              </div>
              <div className='enableRememberMe form-group col-sm-2 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableRememberMe"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Remember Me"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableRememberMe}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableRememberMe?.message}
                                  </Typography>
              </div>
              
              

            </div>

            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>
            
            <h5 className='heading'>Redirect URI</h5>
            <hr />
            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>

            <div className="row justify-content-between text-left">
              <div className="appRedirectionURL form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="appRedirectionURL"
                  render={ ( { field}) => (
                    <TextField {...field} label="Application URL*" error={errors.appRedirectionURL} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.appRedirectionURL?.message} </Typography>
              </div>
              <div className="signInRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="signInRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="SignIn Redirect URL*" error={errors.signInRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.signInRedirectURL?.message} </Typography>
              </div>
              <div className="createRedirectURL form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="createRedirectURL"
                  render={ ( { field}) => (
                    <TextField {...field} label="Create Account URL" error={errors.createRedirectURL} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.createRedirectURL?.message} </Typography>
              </div>
              <div className="resetRedirectURL form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="resetRedirectURL"
                  render={ ( { field}) => (
                    <TextField {...field} label="Reset Password URL" error={errors.resetRedirectURL} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.resetRedirectURL?.message} </Typography>
              </div>
              <div className="activatedRedirectURL form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="activatedRedirectURL"
                  render={ ( { field}) => (
                    <TextField {...field} label="Activate Account URL" error={errors.activatedRedirectURL} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.activatedRedirectURL?.message} </Typography>
              </div>
              
              <div className="cancelRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="cancelRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="Cancel Redirect URL" error={errors.cancelRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.cancelRedirectURL?.message} </Typography>
              </div>
            </div> 

            <div className="row justify-content-between text-left">
              
            </div>

            <hr />

            <div className="row text-left">
              <div className="form-group col-sm-3 flex-column d-flex">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Update Basic Details
                </button>
              </div>
              <div className="form-group col-sm-2 flex-column d-flex">
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={handleReset}
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default BasicAppDetails;
