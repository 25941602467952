import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Carousel } from 'react-responsive-carousel';
import { addAppId } from '../../../Redux/Actions/add_app_id';
import { useDispatch } from 'react-redux';
import RegisterNew from '../Preview-Pages/DynamicRegAttributePr';
import DOMPurify from 'dompurify';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';


function Attribute({ onAttributeChange }) {
  const { REACT_APP_API_ENDPOINT } = process.env;

  let oktaID;
  const Data = useParams();
  const conAppId = DOMPurify.sanitize(Data.conAppId);
  const dispatch = useDispatch();

  //eslint-disable-next-line
  const [fullWidth, setFullWidth] = useState(true);

  // const handleClickOpen = () => {
  //   setOpen(true);
  //   dispatch(addAppId(conAppId));
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const oktaID_redux = useSelector((state) => state);
  oktaID = oktaID_redux.allFiles.OKTAAPPID;

  const [attributesRes, setAttributesRes] = useState([]);
  const [attributeFlag,setAttributeFlag] = useState(false)

  const ConsumerAppDetails = async () => {
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/attributeFile`)
      .then((res) => {
        let attributeData
        if(res.data.attributeConfigDetails?.baseAttributes.length > 0){
          attributeData =
          res.data.attributeConfigDetails?.baseAttributes.map((i) => {
            return { key: i?.key, mappedBy: i?.mappedBy, order: i?.order, isSelect: i?.isSelect, modifyField: i?.modifyField, regexValidator:i?.regexValidator, dropDownURL: i?.dropDownURL};
          });
        setAttributesRes(attributeData);
        }else{
          setAttributesRes(0)
        }
        setAttributeFlag(true)
      })
      .catch();
  };

  useEffect(() => {
    ConsumerAppDetails();
    //eslint-disable-next-line
  }, []);

  const [basePropertyArray, setBasePropertyArray] = useState([]);
  const [customFlag, setCustomFlag] = useState(false);
  const [baseflag, setBaseFlag]= useState(false)

  const getAllAttributes = async () => {
     await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-default-user-schema`)
      .then((res) => {
        setBaseFlag(true)
        let baseArray = [];
        Object.keys(res.data.definitions.base.properties).forEach((e, i) => {
          let checked = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e && item.isSelect)?.length > 0 : false ;
          let modifyFieldChecked = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e && item.modifyField)?.length > 0 : false ;
          let orderArray = attributesRes && attributesRes.filter( (item) => item.key === e)
          let order = orderArray.length > 0 ? (orderArray[0].order !== undefined ? orderArray[0].order : i) : i
          let requiredChecked =
            res.data.definitions.base.properties[e]?.required;
          let regexFieldValue = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.regexValidator : '' ;
          let fetchingUrlData = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.dropDownURL : '' ;
          const obj = {
            isSelect: checked ? checked : (requiredChecked ? requiredChecked : false),
            key: e,
            order: order,
            title: res.data.definitions.base.properties[e].title,
            type: res.data.definitions.base.properties[e].type,
            required: res.data.definitions.base.properties[e].required
              ? res.data.definitions.base.properties[e].required
              : null,
            minLength: res.data.definitions.base.properties[e].minLength
              ? res.data.definitions.base.properties[e].minLength
              : null,
            maxLength: res.data.definitions.base.properties[e].maxLength
              ? res.data.definitions.base.properties[e].maxLength
              : null,
            enumList: res.data.definitions.base.properties[e].enum
              ? res.data.definitions.base.properties[e].enum
              : null,
            oneOf: res.data.definitions.base.properties[e].oneOf
              ? res.data.definitions.base.properties[e].oneOf
              : null,
            itemType: null,
            mappedBy: e,
            modifyField: modifyFieldChecked ? modifyFieldChecked : false,
            regexValidator:regexFieldValue ? regexFieldValue : '',
            dropDownURL:fetchingUrlData ? fetchingUrlData:''
          };
          if (res.data.definitions.base.properties[e].type === 'array') {
            obj.enumList = res.data.definitions.base.properties[e].items.enum
              ? res.data.definitions.base.properties[e].items.enum
              : null;
            obj.oneOf = res.data.definitions.base.properties[e].items.oneOf
              ? res.data.definitions.base.properties[e].items.oneOf
              : null;
            obj.itemType = res.data.definitions.base.properties[e].items?.type
              ? res.data.definitions.base.properties[e].items?.type
              : null;
          }
          baseArray.push(obj);
        });
        baseArray.sort( (a,b) => a.order - b.order) 
        
        setBasePropertyArray(baseArray);

        // let baseCustomArray = [];
        // Object.keys(res.data.definitions.custom.properties).forEach((e, i) => {
        //   const obj = {
        //     key: e,
        //     title: res.data.definitions.custom.properties[e].title,
        //     type: res.data.definitions.custom.properties[e].type,
        //     required: res.data.definitions.custom.properties[e].required
        //       ? res.data.definitions.custom.properties[e].required
        //       : null,
        //     minLength: res.data.definitions.custom.properties[e].minLength
        //       ? res.data.definitions.custom.properties[e].minLength
        //       : null,
        //     maxLength: res.data.definitions.custom.properties[e].maxLength
        //       ? res.data.definitions.custom.properties[e].maxLength
        //       : null,
        //     enumList: res.data.definitions.custom.properties[e].enum
        //       ? res.data.definitions.custom.properties[e].enum
        //       : null,
        //     oneOf: res.data.definitions.custom.properties[e].oneOf
        //       ? res.data.definitions.custom.properties[e].oneOf
        //       : null,
        //     itemType: null,
        //     mappedBy: '',
        //   };
        //   if (res.data.definitions.custom.properties[e].type === 'array') {
        //     obj.enumList = res.data.definitions.custom.properties[e].items.enum
        //       ? res.data.definitions.custom.properties[e].items.enum
        //       : null;
        //     obj.oneOf = res.data.definitions.custom.properties[e].items.oneOf
        //       ? res.data.definitions.custom.properties[e].items.oneOf
        //       : null;
        //     obj.itemType = res.data.definitions.custom.properties[e].items?.type
        //       ? res.data.definitions.custom.properties[e].items?.type
        //       : null;
        //   }
        //   baseCustomArray.push(obj);
        // });
        // setBaseCustomPropertyArray(baseCustomArray);
        
      })
      .catch((err) => {
        console.log({err})
      });
  };
  const [customPropertyArray, setCustomPropertyArray] = useState([]);

  const applicationId = oktaID;
  const getAllCustomAttributes = async () => {
     await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/apps/get-user-schema-by-app/${applicationId}`)
      .then( (res) => {
        let customArray = [];
        Object.keys(res.data.definitions.custom.properties).forEach((e, i) => {
          let checked = attributesRes !== 0 ?  attributesRes?.filter((item) => item.key === e && item.isSelect)?.length > 0 : false;
          let modifyFieldChecked= attributesRes !== 0 ?  attributesRes?.filter((item) => item.key === e && item.modifyField)?.length > 0 : false;
          let orderArray = attributesRes && attributesRes?.filter( (item) => item.key === e && item.isSelect === true)
          let order = orderArray.length > 0 ? (orderArray[0].order !== undefined ? orderArray[0].order : i) : i;
          let regexFieldValue = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.regexValidator : '' ;
          let fetchingUrlData = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.dropDownURL : '' ;
          // const mappedByObj = attributesRes &&  attributesRes?.filter((item) => item.key === e);
          let requiredFields =
            res.data.definitions.custom.properties[e]?.required;
          const obj = {
            isSelect: checked ? checked : (requiredFields ? requiredFields : false),
            key: e,
            order : order,
            title: res.data.definitions.custom.properties[e].title,
            type: res.data.definitions.custom.properties[e].type,
            required: res.data.definitions.custom.properties[e].required
              ? res.data.definitions.custom.properties[e].required 
              : null,
            minLength: res.data.definitions.custom.properties[e].minLength
              ? res.data.definitions.custom.properties[e].minLength
              : null,
            maxLength: res.data.definitions.custom.properties[e].maxLength
              ? res.data.definitions.custom.properties[e].maxLength
              : null,
            enumList: res.data.definitions.custom.properties[e].enum
              ? res.data.definitions.custom.properties[e].enum
              : null,
            oneOf: res.data.definitions.custom.properties[e].oneOf
              ? res.data.definitions.custom.properties[e].oneOf
              : null,
            itemType: null,
            mappedBy:
              mappingProperties[e]?.expression &&
              mappingProperties[e]?.expression.length > 0
                ? (mappingProperties[e]?.expression?.toString().indexOf('==') >= 0 ? mappingProperties[e]?.expression?.split('==')[0]?.trim()?.split('.')[1] : mappingProperties[e]?.expression?.split('.')[1] )
                : '',
            modifyField: modifyFieldChecked ? modifyFieldChecked : false,
            regexValidator: regexFieldValue ? regexFieldValue : '',
            dropDownURL: fetchingUrlData ? fetchingUrlData : ''
          };
          if (res.data.definitions.custom.properties[e].type === 'array') {
            obj.enumList = res.data.definitions.custom.properties[e].items.enum
              ? res.data.definitions.custom.properties[e].items.enum
              : null;
            obj.oneOf = res.data.definitions.custom.properties[e].items.oneOf
              ? res.data.definitions.custom.properties[e].items.oneOf
              : null;
            obj.itemType = res.data.definitions.custom.properties[e].items?.type
              ? res.data.definitions.custom.properties[e].items?.type
              : null;
          }
          customArray.push(obj);
        });
        Object.keys(res.data.definitions.base.properties).forEach((e, i) => {
          let checked = attributesRes !== 0 ?  attributesRes?.filter((item) => item.key === e && item.isSelect)?.length > 0 : false;
          let modifyFieldChecked= attributesRes !== 0 ?  attributesRes?.filter((item) => item.key === e && item.modifyField)?.length > 0 : false;
          let orderArray = attributesRes && attributesRes?.filter( (item) => item.key === e)
          let order = orderArray.length > 0 ? (orderArray[0].order !== undefined ? orderArray[0].order : i) : i
          let requiredFields =
            res.data.definitions.base.properties[e]?.required && mappingProperties[e]?.expression.length > 0;
            let regexFieldValue = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.regexValidator : '' ;
            let fetchingUrlData = attributesRes !== 0 ? attributesRes?.filter((item) => item.key === e)[0]?.dropDownURL : '' ;
            const obj = {
            isSelect: basePropertyArray.filter( item => item.key === e).length === 0 ? ( checked  ? checked : (requiredFields ? requiredFields : false)) : false,
            key: e,
            order : order,
            title: res.data.definitions.base.properties[e].title,
            type: res.data.definitions.base.properties[e].type,
            required: res.data.definitions.base.properties[e].required
              ? res.data.definitions.base.properties[e].required
              : null,
            minLength: res.data.definitions.base.properties[e].minLength
              ? res.data.definitions.base.properties[e].minLength
              : null,
            maxLength: res.data.definitions.base.properties[e].maxLength
              ? res.data.definitions.base.properties[e].maxLength
              : null,
            enumList: res.data.definitions.base.properties[e].enum
              ? res.data.definitions.base.properties[e].enum
              : null,
            oneOf: res.data.definitions.base.properties[e].oneOf
              ? res.data.definitions.base.properties[e].oneOf
              : null,
            itemType: null,
            mappedBy:
              mappingProperties[e]?.expression &&
              mappingProperties[e]?.expression.length > 0
                ? (mappingProperties[e]?.expression?.toString().indexOf('==') >= 0 ? mappingProperties[e]?.expression?.split('==')[0]?.trim()?.split('.')[1] : mappingProperties[e]?.expression?.split('.')[1] )
                : '',
            modifyField: modifyFieldChecked ? modifyFieldChecked : false,
            regexValidator: regexFieldValue ? regexFieldValue : '',
            dropDownURL: fetchingUrlData ? fetchingUrlData : ''
          };
          
          if (res.data.definitions.base.properties[e].type === 'array') {
            obj.enumList = res.data.definitions.base.properties[e].items.enum
              ? res.data.definitions.base.properties[e].items.enum
              : null;
            obj.oneOf = res.data.definitions.base.properties[e].items.oneOf
              ? res.data.definitions.base.properties[e].items.oneOf
              : null;
            obj.itemType = res.data.definitions.base.properties[e].items?.type
              ? res.data.definitions.base.properties[e].items?.type
              : null;
          }
          if(basePropertyArray.filter( item => item.key === obj.key).length === 0){
            customArray.push(obj);
          }
        });
        customArray.sort((a,b) => a.order - b.order)
        
        setCustomPropertyArray(customArray);
      })
      .catch((err) => {
        console.log({err})
      });
  };
  const [mappingProperties, setMappingProperties] = useState(null);
  const getMappingByAppId = async () => {
     await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/apps/get-mapping-by-app/${applicationId}`)
      .then((res) => {
        setMappingProperties(res.data.data.properties);
        setCustomFlag(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if(baseflag){
     
      getMappingByAppId();
    }
    //eslint-disable-next-line
  }, [oktaID, baseflag]);

  useEffect(() => {
    if(attributeFlag){
      getAllAttributes()
    }
    //eslint-disable-next-line
  }, [attributeFlag]);

  useEffect(() => {
    if(customFlag){
      getAllCustomAttributes();
    }
    //eslint-disable-next-line
  }, [customFlag]);

  const handleBaseCheckBox = (e) => {
    if (e.target.checked) {
      const checkArray = basePropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            isSelect: true,
          };
          return temp;
        } else {
          return item;
        }
      });
      setBasePropertyArray(checkArray);
    } else {
      const uncheckArray = basePropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            isSelect: false,
          };
          return temp;
        } else {
          return item;
        }
      });
      setBasePropertyArray(uncheckArray);
    }
    if(e?.target?.name === "modifiedField"){
    if (e.target.checked) {
      const checkArray = basePropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            modifyField: true,
          };
          return temp;
        } else {
          return item;
        }
      });
      setBasePropertyArray(checkArray);
    } else {
      const uncheckArray = basePropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            modifyField: false,
          };
          return temp;
        } else {
          return item;
        }
      });
      setBasePropertyArray(uncheckArray);
    }
  }
  };


  const handleCustomCheckBox = (e) => {
    if (e.target.checked) {
      const checkArray = customPropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            isSelect: true,
          };
          return temp;
        } else {
          return item;
        }
      });
      setCustomPropertyArray(checkArray);
    } else {
      const uncheckArray = customPropertyArray.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            ...item,
            isSelect: false,
          };
          return temp;
        } else {
          return item;
        }
      });
      setCustomPropertyArray(uncheckArray);
    }
    if(e.target?.name === "modifiedField"){
      if (e.target.checked) {
        const checkArray = customPropertyArray.map((item) => {
          if (item.key === e.target.value) {
            const temp = {
              ...item,
              modifyField: true,
            };
            return temp;
          } else {
            return item;
          }
        });
        setCustomPropertyArray(checkArray);
      } else {
        const uncheckArray = customPropertyArray.map((item) => {
          if (item.key === e.target.value) {
            const temp = {
              ...item,
              modifyField: false,
            };
            return temp;
          } else {
            return item;
          }
        });
        setCustomPropertyArray(uncheckArray);
      }
    }
  };

  const handleBaseInput = (e) => {
    if(e?.target?.name === "regexField"){
        const checkArray = basePropertyArray.map((item) => {
          if (item.key === e.target.id) {
            const temp = {
              ...item,
              regexValidator: e.target.value,
            };
            return temp;
          } else {
            return item;
          }
        });
        setBasePropertyArray(checkArray);
    }else if(e?.target?.name === 'dataFetching'){
      const checkArray = basePropertyArray.map((item) => {
        if (item.key === e.target.id) {
          const temp = {
            ...item,
            dropDownURL: e.target.value,
          };
          return temp;
        } else {
          return item;
        }
      });
      setBasePropertyArray(checkArray);
    }
  }

  const handleCustomInput = (e) => {
    if(e.target?.name === "regexField"){
        const checkArray = customPropertyArray.map((item) => {
          if (item.key === e.target.id) {
            const temp = {
              ...item,
              regexValidator: e.target.value,
            };
            return temp;
          } else {
            return item;
          }
        });
        setCustomPropertyArray(checkArray);
    }else if(e?.target?.name === 'dataFetching'){
      const checkArray = customPropertyArray.map((item) => {
        if (item.key === e.target.id) {
          const temp = {
            ...item,
            dropDownURL: e.target.value,
          };
          return temp;
        } else {
          return item;
        }
      });
      setCustomPropertyArray(checkArray);
    }
  }

  // const handleCustomSelect = (event, key) => {
  //   const array = customPropertyArray.map((item) => {
  //     if (item.key === key) {
  //       item.mappedBy = event.target.value;
  //     }
  //     return item;
  //   });
  //   setCustomPropertyArray(array);
  // };
  const [attBean, setAttBean] = useState(null);
  const handleSubmit = () => {
    setOpen(true);
    dispatch(addAppId(conAppId));
    let finalArray = [];
    let baseArray = basePropertyArray.filter((item) => item.isSelect === true);

    baseArray.map((array) => {
      array['enumList'] = array.enumList ? array.enumList : null;
      if (array?.oneOf && array?.oneOf?.length > 0) {
        array?.oneOf.map((item) => {
          item['constVal'] = item.const;
          return item;
        });
      }
      finalArray.push(array);
      return array;
    });

    let customArray = customPropertyArray.filter(
      (item) => item.isSelect === true
    );
    customArray.map((array) => {
      array['enumList'] = array.enumList ? array.enumList : null;
      if (array?.oneOf && array?.oneOf?.length > 0) {
        array?.oneOf.map((item) => {
          item['constVal'] = item.const;
          return item;
        });
      }
      finalArray.push(array);
      return array;
    });

    const attributeBean = {
      oktaApplicationId: '',
      consumerAppId: conAppId,
      noOfAttributes: baseArray.length + customArray.length,
      baseAttributes: finalArray,
    };


    setAttBean(attributeBean);
    //configSubmit(attributeBean);
  };

  const handleApply = () => {
    dispatch(addAppId(conAppId));

    let finalArray = [];
    // let baseArray = basePropertyArray.filter((item) => item.isSelect === true);

    basePropertyArray.map((array) => {
      array['enumList'] = array.enumList ? array.enumList : null;
      if (array?.oneOf && array?.oneOf?.length > 0) {
        array?.oneOf.map((item) => {
          item['constVal'] = item.const;
          return item;
        });
      }
      if(array.mappedBy !== '' && customPropertyArray.filter( item => item.key === array.key).length === 0){

      }else{
        array.isSelect = false;
      }
      finalArray.push(array);
      return array;
    });

    // let customArray = customPropertyArray.filter(
    //   (item) => item.isSelect === true
    // );
    customPropertyArray.map((array) => {
      array['enumList'] = array.enumList ? array.enumList : null;
      if (array?.oneOf && array?.oneOf?.length > 0) {
        array?.oneOf.map((item) => {
          item['constVal'] = item.const;
          return item;
        });
      }
      
      finalArray.push(array);
      return array;
    });

    const attributeBean = {
      oktaApplicationId: '',
      consumerAppId: conAppId,
      noOfAttributes: basePropertyArray.length + customPropertyArray.length,
      baseAttributes: finalArray,
    };
    setAttBean(attributeBean);
    configSubmit(attributeBean);
  };

  async function configSubmit(bean) {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}admin/save-attribute-config`, bean)
      .then((res) => {});
  }


  const handleExchange = (result) =>{
    if(result?.source?.droppableId !== result?.destination?.droppableId){
      alert('Cant move Base attributes into custom attributes')
      return 
    }
    if(result?.source?.droppableId === 'baseAttributes'){
      let tempBaseAttData = [...basePropertyArray]
      let [SelectedRow] = tempBaseAttData.splice(result.source.index, 1)
      SelectedRow.order = result.destination.index
      tempBaseAttData.splice(result.destination.index, 0, SelectedRow)
      tempBaseAttData.map( (item, index) => {
        item.order = index
        return item
      })
      setBasePropertyArray(tempBaseAttData) 
    }else if(result?.source?.droppableId === 'customAttributes'){
      let tempCustomAttData = [...customPropertyArray]
      let [SelectedRow] = tempCustomAttData.splice(result.source.index,1)
      SelectedRow.order = result.destination.index
      tempCustomAttData.splice(result.destination.index, 0, SelectedRow)
      tempCustomAttData.map( (item, index) => {
        item.order = index 
        return item
      })
      setCustomPropertyArray(tempCustomAttData)
    }
  }

  const grid = 12
  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    position:'static',
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250
  });

 
  

  return (
    <>
      {/* Field Mapping */}
      <div className="container-fluid">
        <div className="widget has-shadow">
          <div className="widget-body">
            {/* <div className="table-responsive"> */}
              <DragDropContext onDragEnd={(result) =>handleExchange(result) }>
                <table className="table table-bordered  table-striped mb-0 HtmlTable" id="tb2">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Title</th>
                      <th>Key</th>
                      <th>Mapped By</th>
                      <th>Required</th>
                      <th>Type</th>
                      <th>Minimum Length</th>
                      <th>Maximum Length</th>
                      <th>Enum</th>
                      <th>Modify Field</th>
                      <th>Regex Validation</th>
                      <th>Drop Down Url</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="baseAttributes">
                    {(provided,snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                         <tr>
                                <td colSpan="9" style={{ textAlign: 'center' }}>
                                  <h5 style={{ color: '#1947BA' }}>
                                    Base Attributes
                                  </h5>
                                </td>
                          </tr>
                        
                              {basePropertyArray.map((e, i) => {
                                if(e?.mappedBy !== '' && customPropertyArray.filter( item => item.key === e.key).length === 0){
                                  return (
                                    <Draggable key={e.key} draggableId={e.key} index={i} >
                                      {
                                        (provided, snapshot) => (
                                          <tr key={i} ref={provided.innerRef} {...provided.draggableProps} 
                                          style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          )}
                                          >
                                      <td {...provided.dragHandleProps}>
                                        <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                                          <DragIndicatorOutlinedIcon />
                                        <input
                                          style={{height:'1.3rem', width:'1.3rem'}}
                                          type="checkbox"
                                          onChange={handleBaseCheckBox}
                                          value={e?.key}
                                          checked={e?.isSelect}
                                          disabled={e?.required}
                                        />
                                        </div>
                                      </td>
                                      <td>{e?.title}</td>
                                      <td>{e.key}</td>
                                      <td>{e?.mappedBy}</td>
                                      <td>{e.required ? 'Yes' : 'No'}</td>
                                      <td>{e.type}</td>
                                      <td>{e?.minLength}</td>
                                      <td>{e?.maxLength}</td>
                                      <td>{e?.enum?.length}</td>
                                      <td> 
                                        <input 
                                          style={{height:'1.3rem', width:'1.3rem', color:'yellow', visibility: e.key === 'login' || e.key === 'email' ? 'hidden' : 'visible'}}
                                          name="modifiedField"
                                          type='checkbox' 
                                          value={e?.key} 
                                          onChange={handleBaseCheckBox}
                                          checked={e?.modifyField}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td> 
                                        <td> 
                                        <input 
                                          style={{width:'100%', visibility: e.key === 'login' || e.key === 'email' ? 'hidden' : 'visible'}}
                                          name="regexField"
                                          type='text' 
                                          id={e.key}
                                          value={e?.regexValidator}
                                          onChange={handleBaseInput}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td>
                                        <td> 
                                        <input 
                                          style={{ width:'100%', visibility: e.key === 'login' || e.key === 'email' ? 'hidden' : 'visible'}}
                                          name="dataFetching"
                                          type='text' 
                                          id={e.key}
                                          value={e?.dropDownURL}
                                          onChange={handleBaseInput}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td>
                                    </tr>
                                        )
                                      }
                                    </Draggable>
                                  );
                                }
                                return null;
                              })}
                              {provided.placeholder}   
                      </tbody>
                    )}
                  </Droppable>
                  <Droppable droppableId='customAttributes'>
                      {
                         (provided) => (
                          <tbody ref={provided.innerRef} {...provided.droppableProps}>
                             {customPropertyArray.length > 0 ? (
                                <>
                                  <tr>
                                    <td
                                      colSpan="8"
                                      style={{ textAlign: 'center' }}
                                    >
                                      <h5 style={{ color: '#1947BA' }}>
                                        Custom Attributes
                                      </h5>
                                    </td>
                                  </tr>
                                  {customPropertyArray && customPropertyArray.sort( (item1, item2) => item1?.order - item2?.order ).map((e, i) => {
                                    // && basePropertyArray.filter(item=>item.key===e.key).length===0
                                      if (e.mappedBy !== '') {
                                        return (
                                          <Draggable key={e.key} draggableId={e.key} index={i} >
                                          {
                                            (provided, snapshot) => (
                                              <tr key={i} ref={provided.innerRef} {...provided.draggableProps} 
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                              >
                                          <td {...provided.dragHandleProps}>
                                            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                                              <DragIndicatorOutlinedIcon />
                                            <input
                                              style={{height:'1.3rem', width:'1.3rem'}}
                                              type="checkbox"
                                              onChange={handleCustomCheckBox}
                                              value={e?.key}
                                              checked={e?.isSelect}
                                              disabled={e?.required}
                                            />
                                            </div>
                                          </td>
                                          <td>{e?.title}</td>
                                          <td>{e.key}</td>
                                          <td>{e?.mappedBy}</td>
                                          <td>{e.required ? 'Yes' : 'No'}</td>
                                          <td>{e.type}</td>
                                          <td>{e?.minLength}</td>
                                          <td>{e?.maxLength}</td>
                                          <td>{e?.enum?.length}</td>
                                          <td> 
                                        <input 
                                          style={{height:'1.3rem', width:'1.3rem', color:'yellow'}}
                                          name="modifiedField"
                                          type='checkbox' 
                                          value={e?.key} 
                                          onChange={handleCustomCheckBox}
                                          checked={e?.modifyField}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td>
                                        <td> 
                                        <input 
                                          style={{width:'100%'}}
                                          name="regexField"
                                          type='text'
                                          id={e.key}
                                          value={e?.regexValidator}
                                          onChange={handleCustomInput}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td>
                                        <td>
                                        <input 
                                          style={{width:'100%'}}
                                          name="dataFetching"
                                          type='text' 
                                          id={e.key}
                                          value={e?.dropDownURL}
                                          onChange={handleCustomInput}
                                          disabled={e?.isSelect ? false : true}
                                        /> 
                                        </td>
                                        
                                        </tr>
                                            )
                                          }
                                        </Draggable>
                                          
                                        );
                                      }
                                      return null;
                                      })}
                                </>
                              ) : (
                                <></>
                              )}
                      
                              
                            {provided.placeholder}
                          </tbody>
                         )
                      }
                  </Droppable>
                </table>
              </DragDropContext>
            {/* </div> */}

            <div className="row text-left">
              {/* <div className='form-group col-sm-3 flex-column d-flex' style={{ marginTop: '30px' }}  >
                <button type="submit" className='btn btn-primary' onClick={handleSubmit}>Check Preview</button>
              </div> */}
              <div
                className="form-group col-sm-3 flex-column d-flex"
                style={{ marginTop: '30px' }}
              >
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Preview
                </button>
                <Dialog
                  fullWidth={fullWidth}
                  maxWidth={false}
                  open={open}
                  onClose={handleClose}
                >
                  <DialogActions>
                    <CancelOutlinedIcon
                      style={{
                        position: 'relative',
                        left: '0',
                        cursor: 'pointer',
                      }}
                      onClick={handleClose}
                    />
                    {/* <button className="btn btn-secondary" onClick={handleClose} >Close</button> */}
                  </DialogActions>
                  <Carousel>
                    <RegisterNew attributes={attBean} />
                  </Carousel>
                </Dialog>
              </div>

              <div
                className="form-group col-sm-3 flex-column d-flex"
                style={{ marginTop: '30px' }}
              >
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleApply}
                >
                  Apply Changes
                </button>
              </div>

              <div
                className="form-group col-sm-3 flex-column d-flex"
                style={{ marginTop: '30px' }}
              >
                <button
                  className="btn btn-secondary"
                  onClick={() => onAttributeChange(true)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Attribute;
