import React from 'react'
import PepsiCoLogo from '../../../assets/img/pepsico-logo-png-transparent-cropped.png'

function PageNotFound() {
    return (
        <div className='page-not-found'>
            <div className='page-not-found-logo'>
                    <img src={PepsiCoLogo} alt="logo" className="logo-small-transparentBg" />
            </div>
            <div className='page-not-found-content d-flex'>
                <div className='container-404' style={{margin:'0'}}>
                    404
                </div>
                <div className='justify-content-center container-message' style={{margin:'0'}}>
                    <div className='pnf-message'>Oops! We can't find that page</div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;
