import React from 'react'
import './unauthorised.css'
import { useHistory } from 'react-router'
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';

function Unauthorised() {
  const history = useHistory();
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch()
  const AppSection = () => {
     history.push('/appconfig')
  }
  const LogOut = async () => {
    localStorage.clear()
    await oktaAuth.signOut()
    dispatch({type: 'USER_LOGOUT'})
  }
  return (
    <>
      <div className='unauthorised-container'>
         <div className='unauthorised-card'>
            <div className='unauthorised-content'>
              <div className='unauthorised-text'>
              <h3>🚫 Not Authorised!</h3>
              </div>
              <div className='unauthorised-buttons'>
                <button className='btn btn-primary' onClick={AppSection}>Go To App Section</button>
                <button className='btn btn-danger' onClick={LogOut}>LogOut</button>
              </div>
            </div>
         </div>
      </div>
    </>
  )
}

export default Unauthorised;