import React, { useState, useEffect } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router";
import * as Yup from "yup";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import GenerateUrl from "./GenerateUrl";
import { Field, Form, Formik } from "formik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function OktaConfigDetails({ onOktaConfigChange }) {
  const state = useSelector((state) => state);

  const OktaAppId = state.allFiles.OKTAAPPID;
  const authStateRoles = state?.authState?.roles?.map(role => role.split('-')[1]);
  const [redirectURL, setRedirectURL] = useState([]);

  const [AllGroups, setAllGroups] = useState([]);
  const [formikValues, setFormikValues] = useState({})

  const [MFAGroup, setMFAGroup] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const GetRedirectURL = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-app/${OktaAppId}`)
      .then((res) => {
        let optionsArray = [{ id: "", label: "Select Redirect URL" }];
        res.data.settings.oauthClient.redirect_uris.map((item, key) => {
          optionsArray.push({
            label: item,
            id: key,
          });
          return item;
        });
        setRedirectURL(optionsArray);
      });
  };

  useEffect(() => {
    GetRedirectURL();
    //eslint-disable-next-line
  }, []);

  const oktaValidationSchema = Yup.object().shape({
    issuer: Yup.string().required("Issuer is Required"),
    clientId: Yup.string().required("ClientId is Required"),
    redirectUri: Yup.string().required("Redirect Url is Required"),
    enableSocialLogin: Yup.bool(),
    googleIdp: Yup.string().notOneOf(
      [Yup.ref("clientId"), null],
      "Client ID and google IDP cannot not be same"
    ),
    facebookIdp:Yup.string().notOneOf(
      [Yup.ref("clientId"), null],
      "Client ID and facebook IDP cannot not be same"
    ),
    appleIdp:Yup.string().notOneOf(
      [Yup.ref("clientId"), null],
      "Client ID and Apple IDP cannot not be same"
    ),
    audience: Yup.string().required("Audience URL is Required"),
    eventHookURL: Yup.string(),
    events: Yup.array(),
    identityFlag: Yup.bool(),
    authFlag: Yup.bool(),
    mfaFlag: Yup.bool(),
    identityFlagPassword: Yup.bool(),
    identityWithMfaFlag: Yup.bool()
  });

  let initValues = {}
   initValues = {
    issuer: "",
    clientId: "",
    redirectUri: "",
    enableSocialLogin: false,
    googleIdp: "",
    facebookIdp:"",
    appleIdp:"",
    audience: "",
    eventHookURL: '',
    events:[],
    identityFlag: false,
    mfaFlag: false,
    authFlag: false,
    identityFlagPassword: false,
    identityWithMfaFlag: false,
  };

//   const {
//     control,
//     handleSubmit,
//     reset,
//     getValues,
//     formState: { errors },
//   } = useForm({
//     mode: "onChange",
//     defaultValues: {
//       issuer: "",
//       clientId: "",
//       redirectUri: "",
//       enableSocialLogin: false,
//       googleIdp: "",
//       audience: "",
//       identityFlag: false,
//       mfaFlag: false,
//       authFlag: false,
//       identityFlagPassword: false,
//     },
//     resolver: yupResolver(oktaValidationSchema),
//   });

  const { REACT_APP_API_ENDPOINT } = process.env;
  const Data = useParams();
  const conAppId = DOMPurify.sanitize(Data.conAppId);


  const [oktaConfigDetails, setOktaConfigDetails] = useState(null);
  const [BasicAppDetailName, setBasicAppDetailName] = useState(null);

  const ConsumerAppDetails = async () => {
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/oktaConfigFile`)
      .then((res) => {
        setBasicAppDetailName(res.data.appBasicDetails.appName);
        setOktaConfigDetails(res.data.oktaConfigDetails);
        if (res.data.oktaConfigDetails?.oktaMfaGroupId) {
          setMFAGroup(res.data.oktaConfigDetails?.oktaMfaGroupId);
        }
      })
      .catch();
  };

  useEffect(() => {
    ConsumerAppDetails();
    GetAllGroups();
    //eslint-disable-next-line
  }, []);

  if(oktaConfigDetails){
    initValues = {
        issuer: oktaConfigDetails?.issuer,
      clientId: oktaConfigDetails?.clientID,
      redirectUri: oktaConfigDetails?.redirectURI,
      enableSocialLogin: oktaConfigDetails?.enableSocialLogin
        ? oktaConfigDetails?.enableSocialLogin
        : false,
      googleIdp: oktaConfigDetails?.googleIDP,
      facebookIdp: oktaConfigDetails?.facebookIDP,
      appleIdp:oktaConfigDetails?.appleIDP,
      audience: oktaConfigDetails?.audience,
      eventHookURL: oktaConfigDetails?.eventHookURL,
      events: oktaConfigDetails?.events ? oktaConfigDetails?.events : [],
      identityFlag: oktaConfigDetails?.identityFlag,
      mfaFlag: oktaConfigDetails?.mfaFlag,
      authFlag: oktaConfigDetails?.authFlag,
      identityFlagPassword: oktaConfigDetails?.identityFlagPassword
        ? oktaConfigDetails?.identityFlagPassword
        : false,
        identityWithMfaFlag: oktaConfigDetails?.identityWithMfaFlag
        ? oktaConfigDetails?.identityWithMfaFlag
        : false,
    }
  }

//   // effect runs when user state is updated
//   useEffect(() => {
//     // reset form with user data
//     reset({
//       issuer: oktaConfigDetails?.issuer,
//       clientId: oktaConfigDetails?.clientID,
//       redirectUri: oktaConfigDetails?.redirectURI,
//       enableSocialLogin: oktaConfigDetails?.enableSocialLogin
//         ? oktaConfigDetails?.enableSocialLogin
//         : false,
//       googleIdp: oktaConfigDetails?.googleIDP,
//       audience: oktaConfigDetails?.audience,
//       identityFlag: oktaConfigDetails?.identityFlag,
//       mfaFlag: oktaConfigDetails?.mfaFlag,
//       authFlag: oktaConfigDetails?.authFlag,
//       identityFlagPassword: oktaConfigDetails?.identityFlagPassword
//         ? oktaConfigDetails?.identityFlagPassword
//         : false,
//     });
//     //eslint-disable-next-line
//   }, [oktaConfigDetails]);

//   const handleReset = () => {
//     reset({
//       issuer: "",
//       clientId: "",
//       redirectUri: "",
//       enableSocialLogin: false,
//       googleIdp: "",
//       audience: "",
//       identityFlag: false,
//       mfaFlag: false,
//       authFlag: false,
//       identityFlagPassword: false,
//     });
//   };
//   const mfaFlag_value = getValues("mfaFlag");
//   const authFlag_value = getValues("authFlag");
//   const identityFlag_value = getValues("identityFlag");
//   const identityFlagPassword_value = getValues("identityFlagPassword");
//   const enableSocialLogin_value = getValues("enableSocialLogin");

  const onSubmit = (data) => {
    OktaConfigDetails(
      conAppId,
      data.issuer,
      data.clientId,
      data.redirectUri,
      data.enableSocialLogin,
      data.googleIdp,
      data.facebookIdp,
      data.appleIdp,
      data.audience,
      data.eventHookURL,
      data.events,
      data.identityFlag,
      data.mfaFlag,
      data.authFlag,
      data.identityFlagPassword,
      data.identityWithMfaFlag,
      MFAGroup
    );
  };

  const OktaConfigDetails = async (
    appid,
    issuer,
    clientId,
    redirectUri,
    enableSocialLogin,
    googleIdp,
    facebookIdp,
    appleIdp,
    audience,
    eventHookURL,
    events,
    identityFlag,
    mfaFlag,
    authFlag,
    identityFlagPassword,
    identityWithMfaFlag,
    oktaMfaGroupId
  ) => {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}admin/save-okta-config-detail`,
        {
          appId: appid,
          issuer: issuer,
          clientID: clientId,
          redirectURI: redirectUri,
          enableSocialLogin: enableSocialLogin,
          googleIDP: googleIdp,
          facebookIDP:facebookIdp,
          appleIDP:appleIdp,
          audience: audience,
          eventHookURL:eventHookURL,
          events:events,
          identityFlag: identityFlag,
          mfaFlag: mfaFlag,
          authFlag: authFlag,
          identityFlagPassword: identityFlagPassword,
          identityWithMfaFlag: identityWithMfaFlag,
          oktaMfaGroupId: oktaMfaGroupId,
        })
      .then((res) => {
        onOktaConfigChange(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetAllGroups = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/groups/get-groups`)
      .then((res) => {
        setAllGroups(res.data);
      })
      .catch((err) => console.log(err));
  };
  const GetMFAGroup = () => {
    const GroupName = conAppId + "-MFA";
    AllGroups.length > 0 &&
      AllGroups.map((item) => {
        if (item.profile.name === GroupName) {
          setMFAGroup(item.id);
        }
        return item;
      });
  };
  useEffect(() => {
    if (formikValues.mfaFlag || formikValues.identityWithMfaFlag) {
      GetMFAGroup();
    }
    
    //eslint-disable-next-line
  }, [formikValues.mfaFlag,formikValues.identityWithMfaFlag]);
  
  useEffect(() => {
    if (formikValues.identityWithMfaFlag && MFAGroup === "") {
      GetMFAGroup();
    }
    //eslint-disable-next-line
  }, [formikValues.identityWithMfaFlag]);

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="container-fluid">
      <div className="widget has-shadow">
        <div className="widget-body">
          <Formik
            enableReinitialize
            initialValues={initValues}
            onSubmit={onSubmit}
            validationSchema={oktaValidationSchema}
            innerRef={(formikActions) =>
                formikActions
                  ? setFormikValues(formikActions.values)
                  : setFormikValues({})
              }
          >
            {(props) => (
              <Form>
                <div className="row justify-content-between text-left">
                  <div className=" form-group col-sm-4 flex-column d-flex">
                  <Field
                        as={TextField}
                        variant="outlined"
                        label="Issuer"
                        name="issuer"
                        error={Boolean(props.errors.issuer && props.touched.issuer)}
                        required
                        helperText={props.errors.issuer && props.touched.issuer && String(props.errors.issuer)}
                        margin="dense"
                        fullWidth
                    />
                  </div>
                  <div className=" form-group col-sm-4 flex-column d-flex">
                  <Field
                    as={TextField}
                    variant="outlined"
                    label="Client ID"
                    name="clientId"
                    error={Boolean(props.errors.clientId && props.touched.clientId)}
                    required
                    helperText={props.errors.clientId && props.touched.clientId && String(props.errors.clientId)}
                    margin="dense"
                    fullWidth
                   />
                  </div>
                  <div className=" form-group col-sm-4 flex-column d-flex mt-2">
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">
                            Redirect URL
                        </InputLabel>
                        <Field 
                        as={Select}
                        name="redirectUri"
                        MenuProps={MenuProps}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"   
                        label="Redirect URL"
                        required
                        error={Boolean(props.errors.redirectUri && props.touched.redirectUri)}
                        >
                            {redirectURL ? (
                              redirectURL.map((item, i) => {
                                return (
                                  <MenuItem key={i} value={item.label}>
                                    {item.label}
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem value="">Please select</MenuItem>
                            )}
                        </Field>

                    </FormControl>
                    <Typography variant="inherit" color="error">
                      {props.errors.redirectUri && props.touched.redirectUri ? props.errors.redirectUri : null}
                    </Typography>
                    
                  </div>
                </div>
                <span
                  className="input-group-btn"
                  style={{ width: "20px" }}
                ></span>

                <div className="row  text-left">
                  <div className="form-group col-sm-2 flex-column d-flex">
                    <FormControlLabel
                        control={<Checkbox checked={props.values.enableSocialLogin} />}
                        label="Enable Social Login"
                        name="enableSocialLogin"
                        onChange={props.handleChange}
                    />
                  </div>
                  {props.values.enableSocialLogin && (
                    <>
                    <div className=" form-group col-sm-2 flex-column d-flex">
                      <Field
                        as={TextField}
                        variant="outlined"
                        label="Google IDP"
                        name="googleIdp"
                        error={Boolean(props.errors.googleIdp && props.touched.googleIdp)}
                        
                        helperText={props.errors.googleIdp && props.touched.googleIdp && String(props.errors.googleIdp)}
                        margin="dense"
                        fullWidth
                    />
                    </div>
                    <div className=" form-group col-sm-2 flex-column d-flex">
                      <Field
                        as={TextField}
                        variant="outlined"
                        label="Facebook IDP"
                        name="facebookIdp"
                        error={Boolean(props.errors.googleIdp && props.touched.googleIdp)}
                        
                        helperText={props.errors.googleIdp && props.touched.googleIdp && String(props.errors.googleIdp)}
                        margin="dense"
                        fullWidth
                    />
                    </div>
                    <div className=" form-group col-sm-2 flex-column d-flex">
                      <Field
                        as={TextField}
                        variant="outlined"
                        label="Apple IDP"
                        name="appleIdp"
                        error={Boolean(props.errors.googleIdp && props.touched.googleIdp)}
                        helperText={props.errors.googleIdp && props.touched.googleIdp && String(props.errors.googleIdp)}
                        margin="dense"
                        fullWidth
                    />
                    </div>
                    </>
                  )}

                  <div className=" form-group col-sm-2 flex-column d-flex">
                  <Field
                    as={TextField}
                    variant="outlined"
                    label="Audience"
                    name="audience"
                    error={Boolean(props.errors.audience && props.touched.audience)}
                    required
                    helperText={props.errors.audience && props.touched.audience && String(props.errors.audience)}
                    margin="dense"
                    fullWidth
                  />
                  </div>
                  <div className=" form-group col-sm-3 flex-column d-flex">
                  <Field
                    as={TextField}
                    variant="outlined"
                    label="Event Hook End Point"
                    name="eventHookURL"
                    error={Boolean(props.errors.eventHookURL && props.touched.eventHookURL)}
                    helperText={props.errors.eventHookURL && props.touched.eventHookURL && String(props.errors.eventHookURL)}
                    margin="dense"
                    fullWidth
                  />
                  </div>
                  <div className=" form-group col-sm-4 flex-column d-flex mt-2">
                    <FormControl fullWidth >
                        <InputLabel id="demo-multiple-chip-label">
                          Select Events
                        </InputLabel>
                        <Field 
                        as={Select}
                        name="events"
                        multiple
                        MenuProps={MenuProps}
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"   
                        label="Select Events"
                        required
                        error={Boolean(props.errors.events && props.touched.events)}
                        >
                              <MenuItem value="">Please select</MenuItem>
                            <MenuItem key="create" value="event_user_create">
                                    Create
                            </MenuItem>
                            <MenuItem key="update" value="event_user_update">
                                    Update
                            </MenuItem>
                            <MenuItem key="activate" value="event_user_set_password">
                                    Activate
                            </MenuItem>
                        </Field>

                    </FormControl>
                    <Typography variant="inherit" color="error">
                      {props.errors.redirectUri && props.touched.redirectUri ? props.errors.redirectUri : null}
                    </Typography>
                    
                  </div>
                </div>
                <hr />
                {/* <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span> */}
                <h5 className="heading" >Authentication Module</h5>
                <div className={authStateRoles && authStateRoles?.includes('admin') ? "row justify-content-space-evenly text-left " : "row justify-content-space-evenly text-left authModuleRestrict" } title={authStateRoles && authStateRoles?.includes('admin') ? "Authentication Module" : " Contact Okta Team to Change Authentication Type"}  >
                  <div className="form-group col-sm-3 flex-column d-flex authModule" >
                    <FormControlLabel
                        control={<Checkbox checked={props.values.identityFlag}/>}
                        label="Email Based Activation"
                        name="identityFlag"
                        onChange={props.handleChange}
                        disabled={
                            props.values.authFlag ||
                            props.values.mfaFlag ||
                            props.values.identityFlagPassword ||
                            props.values.identityWithMfaFlag
                        }
                    />
                    <Typography variant="inherit" color="error">
                      {props.errors.identityFlag}
                    </Typography>
                  </div>
                 
                  <div className="form-group col-sm-2 flex-column d-flex">
                    <FormControlLabel
                        control={<Checkbox checked={props.values.identityFlagPassword}/>}
                        label="Email Based Credentials"
                        name="identityFlagPassword"
                        onChange={props.handleChange}
                        disabled={
                            props.values.authFlag ||
                            props.values.mfaFlag ||
                            props.values.identityFlag|| 
                            props.values.identityWithMfaFlag
                        }
                    />
                    
                    <Typography variant="inherit" color="error">
                      {props.errors.identityFlagPassword}
                    </Typography>
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex">
                    <FormControlLabel
                        control={<Checkbox checked={props.values.authFlag}/>}
                        label="Basic Activation"
                        name="authFlag"
                        onChange={props.handleChange}
                        disabled={
                            props.values.identityFlag || props.values.identityFlagPassword || props.values.identityWithMfaFlag
                        }
                    />
                    
                    <Typography variant="inherit" color="error">
                      {props.errors.authFlag}
                    </Typography>
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex">
                    <FormControlLabel
                        control={<Checkbox checked={props.values.mfaFlag}/>}
                        label="Self Multifactor Enroll"
                        name="mfaFlag"
                        onChange={props.handleChange}
                        disabled={
                            props.values.identityFlag || props.values.identityFlagPassword  || props.values.identityWithMfaFlag
                        }
                    />
                    <Typography variant="inherit" color="error">
                      {props.errors.mfaFlag}
                    </Typography>
                  </div>
                  <div className="form-group col-sm-3 flex-column d-flex">
                    <FormControlLabel
                        control={<Checkbox checked={props.values.identityWithMfaFlag}/>}
                        label="Email Based MFA Activation"
                        name="identityWithMfaFlag"
                        onChange={props.handleChange}
                        disabled={
                            props.values.identityFlag || props.values.identityFlagPassword || props.values.mfaFlag || props.values.authFlag
                        }
                    />
                    <Typography variant="inherit" color="error">
                      {props.errors.identityWithMfaFlag}
                    </Typography>
                  </div>
                </div>
                  <div className="form-group col-sm-3 flex-column d-flex">
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={handleOpen}
                      >
                        Generate Application URL's
                      </button>
                      <Dialog
                        open={openDialog}
                        onClose={handleClose}
                      >
                        <DialogTitle
                          id="alert-dialog-title"
                          style={{ textAlign: "center", fontSize: "2rem" }}
                        >
                          {BasicAppDetailName + " User Interface URL"}
                          <CancelOutlinedIcon
                            style={{
                              position: "absolute",
                              right: "0",
                              cursor: "pointer",
                              marginRight: "1%",
                            }}
                            onClick={handleClose}
                          />
                        </DialogTitle>
                        <GenerateUrl
                          conAppId={conAppId}
                          clientId={oktaConfigDetails?.clientId}
                        />
                      </Dialog>
                  </div>
                <span
                  className="input-group-btn"
                  style={{ width: "20px" }}
                ></span>
                <div className="row text-left">
                  <div className="form-group col-sm-4 flex-column d-flex">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={props.handleSubmit}
                    >
                      Update Okta Config Details
                    </button>
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={props.handleReset}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OktaConfigDetails;
