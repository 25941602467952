import { ActionTypes } from "../Constants/ActionTypes"

export const addAppId = (id) => {
    return{
        type: ActionTypes.APP_ID,
        payload: id
    }
}

export const oktaappid = (id) => {
    return{
        type: ActionTypes.OKTAAPPID,
        payload: id
    }
}

export const emaillogo = (id) => {
    return{
        type: ActionTypes.EMAILLOGO,
        payload: id
    }
}

export const hamIconFlag = (flag) => {
    return{
        type: ActionTypes.HAM_ICON,
        payload: flag
    }
}

