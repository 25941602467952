import './App.css';
import { Route, Switch } from 'react-router'
import { BrowserRouter } from "react-router-dom"
import AdminLogin from './Components/SignIn/AdminLogin';
import Appconfigform from './Components/Appconfig/Appconfigform';
import Appconfig from './Components/Appconfig/Appconfig';
import BasicAppConfigDetails from './Components/Appconfig/Configforms/BasicAppConfigDetails';

import { useHistory } from 'react-router';
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react"
import { OktaAuth } from "@okta/okta-auth-js"
// import Languages from './Components/Appconfig/Languages/Languages';
// import DispLang from './Components/Appconfig/Languages/DisplayLang';
import Sidebar from './Components/Common/Sidebar/Sidebar';
import Header from './Components/Common/Header/Header';
import Dashboard from './Components/DashBoard/Dashboard';
import PageNotFound from './Components/Common/PageNotFound/PageNotFound';
import AuthProvider from './Components/auth-provider/AuthProvider';
import AddAppNewLanguages from './Components/Appconfig/Languages/LanguageLabelsByApp/AddAppNewLanguages';
import DisplayAppLang from './Components/Appconfig/Languages/LanguageLabelsByApp/DisplayAppLang';
import SimpleDialog from './Components/DashBoard/SimpleDialog';
import OnBoardApp from './Components/DashBoard/OnBoardApp';
import interceptor from './Interceptor/interceptor';
import store from './Redux/store';
import Unauthororised from './Components/Un-Authorised/Unauthorised';
interceptor.interceptor(store)

function App() {
  const history1 = useHistory();
  const onAuthRequired = () => {
    history1.push("/login");
  };
  const {REACT_APP_CLIENT_ID,REACT_APP_ISSUER,REACT_APP_REDIRECT_URI,REACT_APP_POST_REDIRECT_URI} = process.env
  const clientId = REACT_APP_CLIENT_ID
  const issuer= REACT_APP_ISSUER
  const redirectUrl= REACT_APP_REDIRECT_URI
  const postLogoutUrl= REACT_APP_POST_REDIRECT_URI
  const oktaAuth = new OktaAuth({
    issuer: issuer,
    clientId: clientId,
    redirectUri: redirectUrl,
    postLogoutRedirectUri: postLogoutUrl,
    pkce: true,
    onAuthRequired: onAuthRequired,
    scopes: [
      "profile",
      "email",
      "openid",
      "wsp-scope"
    ]
  });


  const restoreOriginalUri = async () => {
    window.location.href = window.location.origin + '/dashboard'
  };

  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
        <Switch>
          <Route exact path="/">
              <Header />
              <Sidebar />
              <AdminLogin />
          </Route>
          <SecureRoute exact path="/dashboard">
          <AuthProvider route="/">
              <Header />
              <Sidebar />
              <Dashboard /> 
           </AuthProvider>  
          </SecureRoute>
          <SecureRoute exact path='/dashboard/appstatus'>
            <Header />
            <Sidebar />
            <SimpleDialog />
          </SecureRoute>
          <SecureRoute exact path='/dashboard/onboardapp'>
            <Header />
            <Sidebar />
            <OnBoardApp />
          </SecureRoute>
          <SecureRoute exact path="/appconfig">
            <AuthProvider route="/">
              <Header />
              <Sidebar />
              <Appconfig />
            </AuthProvider>
          </SecureRoute>
          <SecureRoute exact path="/appconfig/appconfigforms/:conAppId">
            <AuthProvider route="/">
              <Header />
              <Sidebar />
              <Appconfigform/>
            </AuthProvider>
          </SecureRoute>
          <SecureRoute exact path="/appconfig/addNewConsumerApp">
            <Header />
            <Sidebar />
           <AuthProvider route='/login' role="ADMIN" > <BasicAppConfigDetails /> </AuthProvider> 
          </SecureRoute>
          <SecureRoute exact path="/appconfig/addLanguages/:conAppId">
            
            {/* <Languages /> */}
           <AuthProvider route="/">
            <Header />
            <Sidebar />
            <AddAppNewLanguages />
          </AuthProvider> 
          </SecureRoute>
          {/* <SecureRoute exact path="/appconfig/displayLanguages">
            <Header />
            <Sidebar />
            <DispLang />
          </SecureRoute> */}
          <SecureRoute exact path="/appconfig/langconfig/:conAppId">
            <AuthProvider route='/'>
              <Header />
              <Sidebar />
              <DisplayAppLang />
          </AuthProvider>
          </SecureRoute>
          <Route exact path="/unauthorised"> <Unauthororised /></Route>
          <Route exact path="/authenticate" component={LoginCallback} />
         
          <SecureRoute exact path="*">
            <PageNotFound />
          </SecureRoute>
        </Switch>
      </Security>
    </BrowserRouter>
  );
}

export default App;
