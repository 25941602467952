import React, { useEffect, useState } from 'react'
import axios from 'axios';
import TextField from "@mui/material/TextField";
import { useParams } from 'react-router';
import { useFormik } from "formik";
import * as yup from "yup";
import ModalImage from "react-modal-image";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogContent";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CreateUserEmailTemp from '../Preview-Pages/Email-Template-Preview/CreateUserEmailTemp'
import CustomCreateUser from '../Preview-Pages/Email-Template-Preview/CustomPreview/CustomCreateUser';
import { Checkbox } from '@mui/material';
import DOMPurify from 'dompurify';
import forge from "node-forge";
import CryptoJS from 'crypto-js'

const publicKey = `-----BEGIN RSA PUBLIC KEY-----
    MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDSQ62oIy9/q20Glj32k0wM/tXL
    pbSV8E5XEUmaIRl554wmUZDRB5OaP3MYxcZQdh1Sfrd3yJCkPj4sYlxrlSNQXlBh
    8Qv3kI2969pATjpRbJVVOl4YMx4d/6Mao7XBUDlmpguhsLnLyj1AH7+0U3s13/+R
    rtRVVPQ7yYpGDBMLJwIDAQAB
    -----END RSA PUBLIC KEY-----`;

export const encrypt = (plainText, key) => {
    const publicKey = forge.pki.publicKeyFromPem(key);
    return forge.util.encode64(
        publicKey.encrypt(plainText, "RSA-OAEP", {
          md: forge.md.md5.create(),
          mgf1: {
            md: forge.md.sha1.create()
          }
        })
      )
  };
  
  export const decrypt = (cipherText, key) => {
    const privateKey = forge.pki.privateKeyFromPem(key);
    return privateKey.decrypt(
      forge.util.decode64(cipherText),
      "RSA-OAEP",
      {
          md: forge.md.md5.create(),
          mgf1: {
              md: forge.md.sha1.create()
          }
      }
    );
  };

  export const encryptAES = (msg) => {
        const key = CryptoJS.lib.WordArray.random(8).toString();
        const iv =  CryptoJS.lib.WordArray.random(8).toString();
    
        // encrypt some bytes using GCM mode
        const cipher = forge.cipher.createCipher('AES-GCM', key);
        cipher.start({
            iv: iv, 
             additionalData: 'nvn', // optional
             tagLength: 128 // optional, defaults to 128 bits
        }); 
        cipher.update(forge.util.createBuffer(msg));
        cipher.finish();
        const encrypted = cipher.output;
        const encodedB64 = forge.util.encode64(encrypted.data);
        const tag = cipher.mode.tag; 
        const tagB64 = forge.util.encode64(tag.data);
        return {
            key: encrypt(key, publicKey),
            iv : iv,
            tag: tagB64,
            encrypt: encodedB64,
        }
    };

function EmailConfigDetails({ onEmailConfigChange }) {

   useEffect( () => {
   console.log(encryptAES("Roha"))
   }, [])



    const { REACT_APP_API_ENDPOINT } = process.env;
    const Data = useParams()
    const conAppId= DOMPurify.sanitize(Data.conAppId);

    const [file, setFile] = useState({});
    const [fileName, setFileName] = useState("");
    const [fileNameEmailLogo, setFileNameEmailLogo] = useState("");
    const [emailLogoPreview, setEmailLogoPreview] = useState();
    const [emailLogoFileName, setEmailLogoFileName] = useState([]);
    //eslint-disable-next-line
    const URLvalid = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{3,5}(:[0-9]{1,5})?(\/.*)?$/;


    //Formik validation
    const formik = useFormik({
        initialValues: {
            consumerAppName: "",
            emailFrom: "",
            emailFromName: "",
            EmailDomainUrl:"",
            CustomEmailConfig: false,
            userName:"",
            password:"",
            hostName:"", 
            SmtpEmailFrom: "",
            port:"",
            isSSL1: false,
            createUserSubject: "",
            forgotUserSubject:"",
            changePasswordSubject:"",
            activateUserSubject:"",
        },
        validationSchema: yup.object({
            consumerAppName: yup.string().required("Consumer App Name is Required"),
            emailFrom: yup.string().required("Email From is Required"),
            emailFromName: yup.string().required("Email From Name is required"),
            EmailDomainUrl: yup.string().required("Domain URL is required").matches(URLvalid, "Enter valid URL"),
            CustomEmailConfig: yup.bool(),
            userName:yup.string().when("CustomEmailConfig",{
                is: true,
                then: yup.string().required("UserName is Required"),
                otherwise: yup.string()
            }),
            password: yup.string().when("CustomEmailConfig",{
                is: true,
                then: yup.string().required("password is Required"),
                otherwise: yup.string()
            }),
            hostName: yup.string().when("CustomEmailConfig",{
                is: true,
                then: yup.string().required("hostName is required"),
                otherwise: yup.string()
            }),
            SmtpEmailFrom: yup.string().when("CustomEmailConfig",{
                is: true,
                then: yup.string().required("SmtpEmailFrom is required"),
                otherwise: yup.string()
            }),
            port: yup.string().when("CustomEmailConfig",{
                is: true,
                then: yup.string().required("Port is required"),
                otherwise: yup.string()
            }),
            isSSL1: yup.bool(),
            createUserSubject: yup.string(),
            forgotUserSubject: yup.string(),
            changePasswordSubject: yup.string(),
            activateUserSubject: yup.string()
        }),
        onSubmit : (userinput) =>{
            const PassingData = {
                ...userinput,
            }
            if(!userinput.CustomEmailConfig){
                PassingData.userName = ''
                PassingData.password = ''
                PassingData.hostName = ''
                PassingData.port= ''
                PassingData.isSSL1= false
                PassingData.SmtpEmailFrom = ''
            }

            const EncrptedData = encryptAES(PassingData.password)
            
            appconfigSubmit1(
                    PassingData.consumerAppName,
                    PassingData.emailFrom,
                    PassingData.emailFromName,
                    PassingData.EmailDomainUrl,
                    PassingData.userName,
                    EncrptedData.key,
                    EncrptedData.tag,
                    EncrptedData.iv,
                    EncrptedData.encrypt,
                    PassingData.hostName,
                    PassingData.port,
                    PassingData.isSSL1,
                    PassingData.SmtpEmailFrom,
                    PassingData.CustomEmailConfig,
                    PassingData.createUserSubject,
                    PassingData.forgotUserSubject,
                    PassingData.changePasswordSubject,
                    PassingData.activateUserSubject,
                    conAppId
            );
        }
    });
   
    async function appconfigSubmit1(
        consumerAppName,
        emailFrom,
        emailFromName,
        EmailDomainUrl,
        userName,
        key,
        tag,
        iv,
        encrpt,
        hostName,
        port,
        isSSL,
        smtpEmailFrom,
        isCustomSMTP,
        createUserSubject,
        forgotUserSubject,
        changePasswordSubject,
        activateUserSubject,
        conAppId
    ) {
        await axios
            .post(
                `${REACT_APP_API_ENDPOINT}admin/save-email-config`,
                {
                    consumerAppName: consumerAppName,
                    emailFrom: emailFrom,
                    emailFromName: emailFromName,
                    domainURL: EmailDomainUrl,
                    userName:userName,
                    key: key,
                    tag: tag,
                    iv: iv,
                    encrypt: encrpt,
                    hostName:hostName,
                    port:port,
                    isSSL:isSSL,
                    smtpEmailFrom:smtpEmailFrom,
                    isCustomSMTP:isCustomSMTP,
                    createUserSubject:createUserSubject,
                    forgotUserSubject:forgotUserSubject,
                    changePasswordSubject:changePasswordSubject,
                    activateUserSubject:activateUserSubject,
                    appId: conAppId,
                })
            .then((res) => {
                onEmailConfigChange(true)
            });
    }

   
    const ConsumerAppDetails = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/emailFile`)
            .then((res) => {
                formik.setFieldValue(
                    "consumerAppName",
                    res.data.emailConfigDetails.consumerAppName
                );
                formik.setFieldValue(
                    "emailFrom",
                    res.data.emailConfigDetails.emailFrom
                );
                formik.setFieldValue(
                    "emailFromName",
                    res.data.emailConfigDetails.emailFromName
                );
                formik.setFieldValue("EmailDomainUrl",res.data.emailConfigDetails?.domainURL);
                formik.setFieldValue("userName",res.data.emailConfigDetails.userName);
                formik.setFieldValue("password",res.data.emailConfigDetails.password);
                formik.setFieldValue("hostName",res.data.emailConfigDetails.hostName);
                formik.setFieldValue("port",res.data.emailConfigDetails.port);
                formik.setFieldValue("isSSL1",res.data.emailConfigDetails.isSSL ? true : false);
                formik.setFieldValue("CustomEmailConfig", res.data.emailConfigDetails.isCustomSMTP)
                formik.setFieldValue("SmtpEmailFrom",res.data.emailConfigDetails.smtpEmailFrom)
                formik.setFieldValue("createUserSubject",res.data.emailConfigDetails.createUserSubject)
                formik.setFieldValue("forgotUserSubject",res.data.emailConfigDetails.forgotUserSubject)
                formik.setFieldValue("changePasswordSubject",res.data.emailConfigDetails.changePasswordSubject)
                formik.setFieldValue("activateUserSubject",res.data.emailConfigDetails.activateUserSubject)
            })
            .catch()
    }

    useEffect(() => {
        ConsumerAppDetails();
        //eslint-disable-next-line
    }, [])

    const [fileType, setFileType] = useState('');


    function handleChange(e) {
        e.preventDefault();
        setFile(e?.target?.files[0]);
        setFileName(e?.target?.files[0]?.name);
        setFileNameEmailLogo(e?.target?.files[0]?.name);
        setFileType(e?.target?.name);

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setEmailLogoPreview(reader.result);

        });
        reader.readAsDataURL(e?.target?.files[0]);
    }

    useEffect(() => {
        if (file && fileType) {
            appconfigSubmit(file, fileType, conAppId);
        }
        //eslint-disable-next-line
    }, [file, fileName]);

    async function appconfigSubmit(file, fileType, conAppId) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", fileType);
        formData.append("appId", conAppId);
        await axios
            .post(`${REACT_APP_API_ENDPOINT}admin/uploadFile`, formData)
            .then((res) => {
                if (res.data.responseStatus === "SUCCESS") {
                    ConsumerAppDetails();
                    ConsumerAppDetailsAllAssets();
                }

            });
    }

    const [openCustomCreateUserPreview, setOpenCustomCreateUserPreview] =
        useState(false);
    const [openForgotPasswordPreview, setOpenForgotPasswordPreview] =
        useState(false);
    const [openChangePasswordPreview, setOpenChangePasswordPreview] =
        useState(false);

    const [openCreateUserFileUpload, setOpenCreateUserFileUpload] =
        useState(false);
    const [openForgotPasswordFileUpload, setOpenForgotPasswordFileUpload] =
        useState(false);
    const [openChangePasswordFileUpload, setOpenChangePasswordFileUpload] =
        useState(false);

    const [htmlstring, setHtmlstring] = useState("");
    //eslint-disable-next-line
    const [fullWidth, setFullWidth] = useState(true);
    const [templateId, setTemplateId] = useState(0);
    const [openEmailPreview1, setopenEmailPreview1] = useState(false);
    const [openEmailPreview2, setopenEmailPreview2] = useState(false);
    const [openEmailPreview3, setopenEmailPreview3] = useState(false);
    const [openEmailPreview4, setopenEmailPreview4] = useState(false);

    const [htmlfile, setHtmlFile] = useState(null);

    const [emailLogo, setEmailLogo] = useState(null);

    const ConsumerAppDetailsAllAssets = async () => {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/allAssets`)
            .then((res) => {
                res.data.allAssetsDetails.emailLogoFiles
                    ? setEmailLogo(res.data.allAssetsDetails.emailLogoFiles[0])
                    : setEmailLogo("");

            })
            .catch()
    }

    useEffect(() => {
        ConsumerAppDetailsAllAssets();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        const EmailLogoURL = emailLogo?.split('/emailLogo/')
        if(EmailLogoURL){
            setEmailLogoFileName(EmailLogoURL[1]?.split('_'))
        } 
        setEmailLogoPreview(emailLogo);
    }, [emailLogo])


    const EmailLink = async () => {
        if(templateId !== 0){
            await axios
            .get(
                `${REACT_APP_API_ENDPOINT}admin/emailTemplateData/${conAppId}/${templateId}`)
            .then((res) => {
                setHtmlFile(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        }
        
    };

    useEffect(() => {
        EmailLink();
        //eslint-disable-next-line
    }, [templateId]);

    const handleCreateUserPreviewClick = (e) => {
        e.preventDefault();
        setTemplateId(1);
        setopenEmailPreview1(true);
    };

    const handleClickOpenEmailTemp = (e) => {
        e.preventDefault();
        setOpenCreateUserFileUpload(true);
    };


    const handleCloseEmailTemp = () => {
        setOpenCreateUserFileUpload(false);
    };

    const handleClickOpenEmailTemp1 = (e) => {
        e.preventDefault();
        setOpenForgotPasswordFileUpload(true);
    };

    const handleCloseEmailTemp1 = () => {
        setOpenForgotPasswordFileUpload(false);
    };

    const handleClickOpenEmailTemp2 = (e) => {
        e.preventDefault();
        setOpenChangePasswordFileUpload(true);
    };

    const handleCloseEmailTemp2 = () => {
        setOpenChangePasswordFileUpload(false);
    };

    const handleClickCustomCreateUserPreview = (e) => {
        e.preventDefault();
        setOpenCustomCreateUserPreview(true);
    };

    const handleCloseCustomCreateUserPreview = (e) => {
        e.preventDefault();
        setOpenCustomCreateUserPreview(false);
    };

    const handleClickCustomForgotPasswordPreview = (e) => {
        e.preventDefault();
        setOpenForgotPasswordPreview(true);
    };

    const handleCloseForgotPasswordPreview = (e) => {
        e.preventDefault();
        setOpenForgotPasswordPreview(false);
    };

    const handleClickCustomChangePasswordPreview = (e) => {
        e.preventDefault();
        setOpenChangePasswordPreview(true);
    };

    const handleCloseChangePasswordPreview = (e) => {
        e.preventDefault();
        setOpenChangePasswordPreview(false);
    };

    const handleCreateUserPreviewClose = (e) => {
        e.preventDefault();
        setopenEmailPreview1(false);
    };

    const handleForgotPasswordPreviewClick = (e) => {
        e.preventDefault();
        setTemplateId(2);
        setopenEmailPreview2(true);
    };

    const handleForgotPasswordPreviewClose = (e) => {
        e.preventDefault();
        setopenEmailPreview2(false);
    };

    const handleChangePasswordPreviewClick = (e) => {
        e.preventDefault();
        setTemplateId(3);
        setopenEmailPreview3(true);
    };

    const handleChangePasswordPreviewClose = (e) => {
        e.preventDefault();
        setopenEmailPreview3(false);
    };

    //Create User Activation Event handling
    const [openCreateUserFileUploadForActivateUser, setOpenCreateUserFileUploadForActivateUser] = useState(false)
    const [openCustomCreateUserPreviewForActivateUser, setOpenCustomCreateUserPreviewForActivateUser] = useState(false);
     
    const handleCreateUserPreviewClickForActivateUser = (e) => {
        e.preventDefault();
        setTemplateId(4);
        setopenEmailPreview4(true);
    };

    
    const handleClickOpenEmailTempForActivateUser = (e) => {
        e.preventDefault();
        setOpenCreateUserFileUploadForActivateUser(true);
    };

    const handleCloseEmailTempForActivateUser = () => {
        setOpenCreateUserFileUploadForActivateUser(false);
    };

    const handleClickCustomCreateUserPreviewForActivateUser = (e) => {
        e.preventDefault();
        setOpenCustomCreateUserPreviewForActivateUser(true);
    };

    const handleCloseCustomCreateUserPreviewForActivateUser = (e) => {
        e.preventDefault();
        setOpenCustomCreateUserPreviewForActivateUser(false);
    };

    const handleCreateUserPreviewCloseForActivateUser = (e) => {
        e.preventDefault();
        setopenEmailPreview4(false);
    };



    const [uploadFile, setUploadFile] = useState({});
    const [previewFileName1, setPreviewFileName1] = useState("");
    const [previewFileName2, setPreviewFileName2] = useState("");
    const [previewFileName3, setPreviewFileName3] = useState("");
    const [previewFileName4, setPreviewFileName4] = useState("");


   
    function onChange(event) {
        var file = event.target.files[0];
        if (event.target.id === "create-user-file") {
            setPreviewFileName1(event.target.files[0].name);
        } else if (event.target.id === "forgot-password-file") {
            setPreviewFileName2(event.target.files[0].name);
        } else if (event.target.id === "change-password-file") {
            setPreviewFileName3(event.target.files[0].name);
        } else if(event.target.id === "create-user-activate-file"){
            setPreviewFileName4(event.target.files[0].name)
        }

        setUploadFile(file);
        var reader = new FileReader();
        reader.onload = function (event) {
            // The file's text will be printed here
            setHtmlstring(event.target.result);
        };

        reader.readAsText(file);
    }
    if (htmlstring) {
        var tempdiv = document.createElement("div");
        tempdiv.innerHTML = htmlstring;
    }

    const handleClickPreviewSubmit = (e) => {
        fileUpload(e.target.id);
        setOpenCreateUserFileUpload(false);
        setOpenForgotPasswordFileUpload(false);
        setOpenChangePasswordFileUpload(false);
        setOpenCreateUserFileUploadForActivateUser(false)
    };

    async function fileUpload(id) {
        const formData = new FormData();
        formData.append("file", uploadFile);
        formData.append("appId", conAppId);
        formData.append("fileType", id);
        await axios
            .post(
                `${REACT_APP_API_ENDPOINT}admin/uploadEmailTemplateFile`,
                formData)
            .then((res) => {
                EmailLink()
            });
    }
    return (
        <div className="container-fluid">
            <div className="widget has-shadow">
                <div className="widget-body">
                            <form className="form-card">
                        <div className="row  text-left">
                            <div className="emailFromName form-group col-sm-4 flex-column d-flex">
                                <TextField
                                    name="emailFromName"
                                    label="Email From Name*"
                                    value={formik.values.emailFromName}
                                    onChange={formik.handleChange}
                                    error={formik.errors.emailFromName}
                                />
                                {formik.errors.emailFromName ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.emailFromName}
                                    </span>
                                ) : null}
                            </div>
                             
                            <div className="consumerAppName form-group col-sm-4 flex-column d-flex">
                                <TextField
                                    name="consumerAppName"
                                    label="Consumer Application Name*"
                                    value={formik.values.consumerAppName}
                                    onChange={formik.handleChange}
                                    error={formik.errors.consumerAppName}
                                />
                                {formik.errors.consumerAppName ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.consumerAppName}
                                    </span>
                                ) : null}
                            </div>
                            {
                                formik.values.CustomEmailConfig ? null :
                                <div className="emailFrom form-group col-sm-4 flex-column d-flex">
                                    <TextField
                                        name="emailFrom"
                                        label="Email From*"
                                        value={formik.values.emailFrom}
                                        onChange={formik.handleChange}
                                        error={formik.errors.emailFrom}
                                        disabled={formik.values.CustomEmailConfig} 
                                    />
                                    {formik.errors.emailFrom ? (
                                        <span
                                            style={{ color: "red", fontSize: "10px" }}
                                        >
                                            {formik.errors.emailFrom}
                                        </span>
                                    ) : null}
                                </div>
                            }
                        </div>
                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span>
                        <div className="row text-left">
                            <div className="emailLogo form-group col-sm-4 flex-column d-flex">
                                <input
                                    type="file"
                                    label="Email Logo"
                                    id={5}
                                    name="emailLogo"
                                    onChange={handleChange}
                                    style={{ display: "none" }}
                                />
                                <TextField
                                    label="Email Logo*"
                                    value={fileNameEmailLogo}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" color="primary">
                                                    <label
                                                        htmlFor="5"
                                                        className="fileUpload-browse"
                                                    >
                                                        <span>Browse</span>
                                                    </label>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {emailLogoPreview && emailLogoFileName && emailLogoFileName.length > 0 ? <><p style={{ fontSize: '13px', color: '#707070', marginTop: '5px' }}>Uploaded Email Logo - {emailLogoFileName[1]}</p></> : <></>}
                                <div className="image-preview">
                                    <ModalImage
                                        small={emailLogoPreview}
                                        large={emailLogoPreview}
                                        hideDownload={true}
                                        hideZoom={true}
                                        className="modal-image"
                                    />
                                </div>
                            </div>
                            <div className="emailLogo form-group col-sm-4 flex-column d-flex">
                            <TextField
                                    name="EmailDomainUrl"
                                    label="Email Domain URL*"
                                    value={formik.values.EmailDomainUrl}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.EmailDomainUrl && formik.errors.EmailDomainUrl}
                                />
                                { formik.touched.EmailDomainUrl && formik.errors.EmailDomainUrl ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.EmailDomainUrl}
                                    </span>
                                ) : null} 
                            </div>
                            <div className="customEmailConfig form-group col-sm-4">
                                    <Checkbox
                                        inputProps={{'aria-label': 'Checkbox demo' }}
                                        name="CustomEmailConfig"
                                        value={formik.values.CustomEmailConfig}
                                        checked={formik.values.CustomEmailConfig}
                                        onChange={formik.handleChange}
                                    />Custom Email Config
                            </div> 
                        </div>
                        { formik.values.CustomEmailConfig ? 
                        <>
                        <div className="row text-left">
                            <div className="hostName form-group col-sm-4 flex-column d-flex">
                                <TextField
                                    name="hostName"
                                    label="SMTP Host*"
                                    value={formik.values.hostName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={ formik.touched.hostName && formik.errors.hostName}
                                />
                                { formik.touched.hostName && formik.errors.hostName ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.hostName}
                                    </span>
                                ) : null}
                            </div> 
                            <div className="port form-group col-sm-4 flex-column d-flex">
                                <TextField
                                    name="port"
                                    label="SMTP Port*"
                                    value={formik.values.port}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.port && formik.errors.port}
                                />
                                { formik.touched.port && formik.errors.port ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.port}
                                    </span>
                                ) : null}
                            </div>
                            <div className="userName form-group col-sm-4 flex-column d-flex">
                                <TextField
                                    name="userName"
                                    label="SMTP User Name"
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.userName && formik.touched.userName}
                                />
                                {formik.touched.userName && formik.errors.userName ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.userName}
                                    </span>
                                ) : null}
                            </div>
                            
                        </div>
                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span>
                        <div className="row text-left">
                            <div className="password form-group col-sm-4 flex-column d-flex ">
                                <TextField
                                    type='password'
                                    name="password"
                                    label="SMTP Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.password && formik.touched.password}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.password}
                                    </span>
                                ) : null}
                            </div>
                            <div className="SmtpEmailFrom form-group col-sm-4 flex-column d-flex ">
                                <TextField
                                    name="SmtpEmailFrom"
                                    label="SMTP Email From*"
                                    value={formik.values.SmtpEmailFrom}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.errors.SmtpEmailFrom && formik.touched.smtpEmailFrom}
                                />
                                {formik.touched.smtpEmailFrom && formik.errors.SmtpEmailFrom ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.SmtpEmailFrom}
                                    </span>
                                ) : null}
                            </div>
                            <div className="isSSL form-group col-sm-4 flex-column d-flex pl-1">
                                <div>
                                <Checkbox
                                    inputProps={{'aria-label': 'isSSL1' }}
                                    name="isSSL1"
                                    value={formik.values.isSSL1}
                                    checked={formik.values.isSSL1}
                                    onChange={formik.handleChange}
                                />SSL Required
                                </div>
                            
                                {
                                formik.errors.isSSL1 ? (
                                    <span
                                        style={{ color: "red", fontSize: "10px" }}
                                    >
                                        {formik.errors.isSSL1}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span> 
                        </>
                        : null
                        }

                        <div className="row text-left">
                            <div className="create-user form-group col-sm-8 flex-column d-flex">
                                {/*Dialog for custom files - S */}
                                <div className='d-flex'>
                                    <div className='col-sm-6'>
                                        <TextField
                                            name='createUserSubject'
                                            label="Create User Email Subject"
                                            value={formik.values.createUserSubject}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.createUserSubject}
                                            fullWidth
                                        />
                                        {formik.errors.createUserSubject ? (
                                        <span
                                            style={{ color: "red", fontSize: "10px" }}
                                        >
                                            {formik.errors.createUserSubject}
                                        </span>
                                ) : null}
                                    </div>
                                     <div className='col-sm-6'>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleClickOpenEmailTemp}
                                            style={{ height: "51px", width: "100%" }}
                                        >
                                            Click to upload custom email file for Create
                                            User
                                        </button>
                                     </div>
                                    
                                    <Dialog
                                        open={openCreateUserFileUpload}
                                        onClose={handleCloseEmailTemp}
                                    >
                                        <DialogTitle
                                            className="CustomEmailPopUp"
                                        >
                                            Upload Custom Email File for Create User
                                            <CancelOutlinedIcon
                                                className="closeButton"
                                                style={{
                                                    height: "35px",
                                                    width: "35px",
                                                    left: "35%",
                                                }}
                                                onClick={handleCloseEmailTemp}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            
                                            <input
                                                type="file"
                                                name="create-user"
                                                id="create-user-file"
                                                className="form-control"
                                                onChange={onChange}
                                                style={{ display: "none" }}
                                            />
                                            <TextField
                                                label="Create User File"
                                                value={previewFileName1}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                color="primary"
                                                            >
                                                                <label
                                                                    htmlFor="create-user-file"
                                                                    className="fileUpload-browse"
                                                                >
                                                                    <span>Browse</span>
                                                                </label>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div className="previewBtns">
                                                <button
                                                    className="btn btn-secondary"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={
                                                        handleClickCustomCreateUserPreview
                                                    }
                                                    disabled={previewFileName1 ? false : true}
                                                >
                                                    Custom Preview
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    id="1"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={handleClickPreviewSubmit}
                                                    disabled={previewFileName1 ? false : true}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={false}
                                                open={openCustomCreateUserPreview}
                                                onClose={
                                                    handleCloseCustomCreateUserPreview
                                                }
                                            >
                                                <DialogActions>
                                                    <CancelOutlinedIcon
                                                        className="closeButton"
                                                        style={{
                                                            height: "35px",
                                                            width: "35px",
                                                            left: "0",
                                                        }}
                                                        onClick={
                                                            handleCloseCustomCreateUserPreview
                                                        }
                                                    />
                                                </DialogActions>
                                                <CustomCreateUser
                                                    paragraph={tempdiv}
                                                    emailLogo={emailLogo}
                                                    id="1"
                                                />
                                            </Dialog>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                {/*Dialog for custom files - E */}
                            </div>
                            <div className="form-group col-sm-3 flex-column d-flex">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleCreateUserPreviewClick}
                                    style={{height: '51px'}}
                                >
                                    Preview
                                </button>
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={false}
                                    open={openEmailPreview1}
                                    onClose={handleCreateUserPreviewClose}
                                >
                                    <CreateUserEmailTemp
                                        emailLogo={emailLogo}
                                        HtmlFile={htmlfile}
                                        templateId={templateId}
                                    />
                                    <DialogActions>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handleCreateUserPreviewClose}
                                        >
                                            Close
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span>
                        <div className="row text-left">
                            <div className="create-user form-group col-sm-8 flex-column d-flex">
                                {/*Dialog for custom files - S */}
                                <div className='d-flex'>
                                    <div className='col-sm-6'>
                                        <TextField
                                            name='forgotUserSubject'
                                            label="Forgot Password Email Subject"
                                            value={formik.values.forgotUserSubject}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.forgotUserSubject}
                                            fullWidth
                                        />
                                        {formik.errors.forgotUserSubject ? (
                                        <span
                                            style={{ color: "red", fontSize: "10px" }}
                                        >
                                            {formik.errors.forgotUserSubject}
                                        </span>
                                        ) : null}
                                    </div>
                                    <div className='col-sm-6'>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleClickOpenEmailTemp1}
                                            style={{ height: "51px", width: "100%" }}
                                        >
                                            Click to upload custom email file for Forgot
                                            Password
                                        </button>
                                    </div>
                                    <Dialog
                                        open={openForgotPasswordFileUpload}
                                        onClose={handleCloseEmailTemp1}
                                    >
                                        <DialogTitle
                                            className="CustomEmailPopUp"
                                        >
                                            Upload Custom Email File for Forgot Password
                                            <CancelOutlinedIcon
                                                className="closeButton"
                                                style={{ height: "35px", width: "35px" }}
                                                onClick={handleCloseEmailTemp1}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            {/* <label className='form-control-label font-weight-bold'>Forgot Password File</label> */}
                                            <input
                                                type="file"
                                                name="create-user"
                                                id="forgot-password-file"
                                                className="form-control"
                                                onChange={onChange}
                                                style={{ display: "none" }}
                                            />
                                            <TextField
                                                label="Forgot Password File"
                                                value={previewFileName2}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                color="primary"
                                                            >
                                                                <label
                                                                    htmlFor="forgot-password-file"
                                                                    className="fileUpload-browse"
                                                                >
                                                                    <span>Browse</span>
                                                                </label>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div className="previewBtns">
                                                <button
                                                    className="btn btn-secondary"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={
                                                        handleClickCustomForgotPasswordPreview
                                                    }
                                                    disabled={previewFileName2 ? false : true}
                                                >
                                                    Custom Preview
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    id="2"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={handleClickPreviewSubmit}
                                                    disabled={previewFileName2 ? false : true}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={false}
                                                open={openForgotPasswordPreview}
                                                onClose={handleCloseForgotPasswordPreview}
                                            >
                                                <DialogActions>
                                                    <CancelOutlinedIcon
                                                        className="closeButton"
                                                        style={{
                                                            height: "35px",
                                                            width: "35px",
                                                            left: "0",
                                                        }}
                                                        onClick={
                                                            handleCloseForgotPasswordPreview
                                                        }
                                                    />
                                                </DialogActions>
                                                <CustomCreateUser
                                                    paragraph={tempdiv}
                                                    emailLogo={emailLogo}
                                                    id="2"
                                                />
                                            </Dialog>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                {/*Dialog for custom files - E */}
                            </div>
                            <div className="form-group col-sm-3 flex-column d-flex">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleForgotPasswordPreviewClick}
                                    style={{height: '51px'}}
                                >
                                    Preview
                                </button>
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={false}
                                    open={openEmailPreview2}
                                    onClose={handleForgotPasswordPreviewClose}
                                >
                                    <CreateUserEmailTemp
                                        emailLogo={emailLogo}
                                        HtmlFile={htmlfile}
                                        templateId={templateId}
                                    />
                                    <DialogActions>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handleForgotPasswordPreviewClose}
                                        >
                                            Close
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span>
                        <div className="row text-left">
                            <div className="create-user form-group col-sm-8 flex-column d-flex">
                                {/*Dialog for custom files - S */}
                                <div className='d-flex'>
                                    <div className='col-sm-6'>
                                        <TextField
                                            name='changePasswordSubject'
                                            label="Change Password Email Subject"
                                            value={formik.values.changePasswordSubject}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.errors.changePasswordSubject}
                                            fullWidth
                                        />
                                        {formik.errors.changePasswordSubject ? (
                                        <span
                                            style={{ color: "red", fontSize: "10px" }}
                                        >
                                            {formik.errors.changePasswordSubject}
                                        </span>
                                        ) : null}
                                    </div>
                                    <div className='col-sm-6'>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleClickOpenEmailTemp2}
                                            style={{ height: "51px", width: "100%" }}
                                        >
                                            Click to upload custom email file for Change
                                            Password
                                        </button>
                                    </div>
                                    <Dialog
                                        open={openChangePasswordFileUpload}
                                        onClose={handleCloseEmailTemp2}
                                    >
                                        <DialogTitle
                                            className="CustomEmailPopUp"
                                        >
                                            Upload Custom Email File for Change Password
                                            <CancelOutlinedIcon
                                                className="closeButton"
                                                style={{ height: "35px", width: "35px" }}
                                                onClick={handleCloseEmailTemp2}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            <div
                                                className="newClass"
                                                style={{ width: "100%" }}
                                            >
                                                <input
                                                    type="file"
                                                    name="create-user"
                                                    id="change-password-file"
                                                    className="form-control"
                                                    onChange={onChange}
                                                    style={{ display: "none" }}
                                                />

                                                <TextField
                                                    label="Change Password File"
                                                    value={previewFileName3}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    edge="end"
                                                                    color="primary"
                                                                >
                                                                    <label
                                                                        htmlFor="change-password-file"
                                                                        className="fileUpload-browse"
                                                                    >
                                                                        <span>Browse</span>
                                                                    </label>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <div className="previewBtns">
                                                <button
                                                    className="btn btn-secondary"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={
                                                        handleClickCustomChangePasswordPreview
                                                    }
                                                    disabled={previewFileName3 ? false : true}
                                                >
                                                    Custom Preview
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    id="3"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={handleClickPreviewSubmit}
                                                    disabled={previewFileName3 ? false : true}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={false}
                                                open={openChangePasswordPreview}
                                                onClose={handleCloseChangePasswordPreview}
                                            >
                                                <DialogActions>
                                                    <CancelOutlinedIcon
                                                        className="closeButton"
                                                        style={{
                                                            height: "35px",
                                                            width: "35px",
                                                            left: "0",
                                                        }}
                                                        onClick={
                                                            handleCloseChangePasswordPreview
                                                        }
                                                    />
                                                </DialogActions>
                                                <CustomCreateUser
                                                    paragraph={tempdiv}
                                                    emailLogo={emailLogo}
                                                    id="3"
                                                />
                                            </Dialog>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                {/*Dialog for custom files - E */}
                            </div>
                            <div className="form-group col-sm-3 flex-column d-flex">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleChangePasswordPreviewClick}
                                    style={{height: '51px'}}
                                >
                                    Preview
                                </button>
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={false}
                                    open={openEmailPreview3}
                                    onClose={handleChangePasswordPreviewClose}
                                >
                                    <CreateUserEmailTemp
                                        emailLogo={emailLogo}
                                        HtmlFile={htmlfile}
                                        templateId={templateId}
                                    />
                                    <DialogActions>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handleChangePasswordPreviewClose}
                                        >
                                            Close
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>

                        <span
                            className="input-group-btn"
                            style={{ width: "20px" }}
                        ></span>
                        {/* Create User Activate Email Template */}
                        <div className="row text-left">
                            <div className="create-user form-group col-sm-8 flex-column d-flex">
                                {/*Dialog for custom files - S */}
                                <div className='d-flex'>
                                        <div className='col-sm-6'>
                                            <TextField
                                                name='activateUserSubject'
                                                label="Activate User Email Subject"
                                                value={formik.values.activateUserSubject}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.errors.activateUserSubject}
                                                fullWidth
                                            />
                                             {formik.errors.activateUserSubject ? (
                                                <span
                                                    style={{ color: "red", fontSize: "10px" }}
                                                >
                                                    {formik.errors.activateUserSubject}
                                                </span>
                                            ) : null}
                                        </div>
                                    <div className='col-sm-6'>
                                        <button
                                            className="btn-secondary"
                                            onClick={handleClickOpenEmailTempForActivateUser} 
                                            style={{ height: "51px", width: "100%" }}
                                        >
                                            Click to upload custom email file for Create
                                            User Through Activation
                                        </button>
                                    </div>
                                    <Dialog
                                        open={openCreateUserFileUploadForActivateUser}
                                        onClose={handleCloseEmailTempForActivateUser}
                                    >
                                        <DialogTitle
                                            className="CustomEmailPopUp"
                                        >
                                            Upload Custom Email File for Create User Activation
                                            <CancelOutlinedIcon
                                                className="closeButton"
                                                style={{
                                                    height: "35px",
                                                    width: "35px",
                                                    left: "35%",
                                                }}
                                                onClick={handleCloseEmailTempForActivateUser}
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            <input
                                                type="file"
                                                name="create-user-activate"
                                                id="create-user-activate-file"
                                                className="form-control"
                                                onChange={onChange}
                                                style={{ display: "none" }}
                                            />
                                            <TextField
                                                label="Create User File"
                                                value={previewFileName4}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                color="primary"
                                                            >
                                                                <label
                                                                    htmlFor="create-user-activate-file"
                                                                    className="fileUpload-browse"
                                                                >
                                                                    <span>Browse</span>
                                                                </label>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <div className="previewBtns">
                                                <button
                                                    className="btn btn-secondary"
                                                    style={{
                                                        marginTop: "10px",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={
                                                        handleClickCustomCreateUserPreviewForActivateUser
                                                    }
                                                    disabled={previewFileName4 ? false : true}
                                                >
                                                    Custom Preview
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    id="4"
                                                    style={{ marginTop: "10px" }}
                                                    onClick={handleClickPreviewSubmit}
                                                    disabled={previewFileName4 ? false : true}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={false}
                                                open={openCustomCreateUserPreviewForActivateUser}
                                                onClose={
                                                    handleCloseCustomCreateUserPreviewForActivateUser
                                                }
                                            >
                                                <DialogActions>
                                                    <CancelOutlinedIcon
                                                        className="closeButton"
                                                        style={{
                                                            height: "35px",
                                                            width: "35px",
                                                            left: "0",
                                                        }}
                                                        onClick={
                                                            handleCloseCustomCreateUserPreviewForActivateUser
                                                        }
                                                    />
                                                </DialogActions>
                                                <CustomCreateUser
                                                    paragraph={tempdiv}
                                                    emailLogo={emailLogo}
                                                    id="4"
                                                />
                                            </Dialog>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                                {/*Dialog for custom files - E */}
                            </div>
                            <div className="form-group col-sm-3 flex-column d-flex">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleCreateUserPreviewClickForActivateUser}
                                    style={{height: '51px'}}
                                >
                                    Preview
                                </button>
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={false}
                                    open={openEmailPreview4}
                                    onClose={handleCreateUserPreviewCloseForActivateUser}
                                >
                                    <CreateUserEmailTemp
                                        emailLogo={emailLogo}
                                        HtmlFile={htmlfile}
                                        templateId={templateId}
                                    />
                                    <DialogActions>
                                        <button
                                            className="btn btn-secondary"
                                            onClick={handleCreateUserPreviewCloseForActivateUser}
                                        >
                                            Close
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </div>
                        <div className="row text-left">
                            <div className="form-group col-sm-3 flex-column d-flex">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={formik.handleSubmit}
                                >
                                    Update Email Details
                                </button>
                            </div>
                            <div className="form-group col-sm-2 flex-column d-flex">
                                <button
                                    className="btn btn-secondary"
                                    type="submit"
                                    onClick={formik.handleReset}
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmailConfigDetails;
