import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { makeStyles } from "@mui/styles"; 
import { TableCell, TableContainer, TableHead, TableRow, Table, TableBody } from "@mui/material";
import Stepper from 'react-stepper-horizontal';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';


const useStyles = makeStyles( () => ({
  table: {
    "& .MuiTable-root":{
      tableLayout:'fixed !important',
      borderRadius: '2px'
    },
    "& .makeStyles-table-1":{
      tableLayout:'fixed',
    },
    "& .MuiTableRow-root": {
      border: '0.5px solid #ececec'
    },
    "& .MuiTableCell-body":{
      padding:'5px 10px',
      border: '0.5px solid #ececec'
    },
    "& .MuiTableCell-head":{
        padding:'15px 15px',
        border: '0.5px solid #ececec',
        borderRadius:'2px',
        fontWeight:1000,
        background: '#4F81BD',
        color: 'white',
        fontSize:'1rem'
    }
  },
}))

function SimpleDialog() {

  const { REACT_APP_API_ENDPOINT } = process.env;
  const classes = useStyles();
  const[ status, setStatus] = useState({})

  const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
  const ConsumerAppIds = resData.accessToken.claims.pepappconsumerroles.map( appid => appid.split('-')[1])
  let adminFind = ConsumerAppIds?.includes("admin");
  const AccessToken = resData.accessToken.accessToken;
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)

  

const [tableData, setTableData] = useState([])
const [stepsData, setStepsData] = useState([])


const getAdminStatus = async () => {
  axios.get(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-apps-stage`,{
    headers: { Authorization: `Bearer ${AccessToken}` },
  }).then( res => {
   let completeState=[];
   let completedTableData = []
  for(let [,value] of Object.entries(res.data)){
        let stepsObject= []
        let tableObject= {appName: value[0].appName, value: value}
       value?.map( item1 => {
           stepsObject.push({title: item1.phaseName})
           return item1;
       })
       completedTableData.push(tableObject)
       completeState.push(stepsObject)
  }
  setTableData(completedTableData)
  setStepsData(completeState)
  }).catch( err => {
    console.log({err})
  })
}

const getAppStatus = async () => {
  axios.get(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-apps-stage-count`,{
    headers: { Authorization: `Bearer ${AccessToken}` },
  }).then( res => {
    setStatus(res.data)
  }).catch( err => {
    console.log({err})
  })
}

useEffect( () => {
    getAdminStatus()
    getAppStatus()
    //eslint-disable-next-line
}, [])




  return (
     <> 
     {
      adminFind ?
      <div
        className={IconFlag ? "content-inner" : "content-inner-compress-data"}
      >
        <div className="container-fluid">
            <div className="page-name">
                <h4>Application Status</h4>
                
            </div>
            <div className="widget has-shadow">
              <div className="d-flex widget-body">
                <div className="col-sm-4 align-items-center mb-1 mr-1">
                        <Card>
                          <CardContent>
                            <Box>
                              <Grid container direction="column">
                                <Grid item >
                                  <Grid container alignItems="center">
                                    <Grid item >
                                      <Typography
                                        sx={{ fontSize: "3rem", fontWeight: 700 }}
                                        
                                      >
                                        {status?.completed?.completed}
                                      </Typography>
                                      {/* //* ********  DIALOG SECTION **********   */}
                                      {/* {
                                        adminFind &&
                                        <SimpleDialog
                                            open={openDialoge}
                                            onClose={handleClose12}
                                        />
                                        } */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Typography gutterBottom>
                                      Completed Applications
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {new Date(status?.completed?.updatedOn).toLocaleString("en-US")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                </div>
                <div className="col-sm-4 align-items-center mb-1 mr-1">
                        <Card>
                          <CardContent>
                            <Box>
                              <Grid container direction="column">
                                <Grid item >
                                  <Grid container alignItems="center">
                                    <Grid item >
                                      <Typography
                                        sx={{ fontSize: "3rem", fontWeight: 700 }}
                                      >
                                        {status?.pending?.inProgress}
                                      </Typography>
                                      {/* //* ********  DIALOG SECTION **********   */}
                                      {/* {
                                        adminFind &&
                                        <SimpleDialog
                                            open={openDialoge}
                                            onClose={handleClose12}
                                        />
                                        } */}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between">
                                  <Grid item>
                                    <Typography gutterBottom>
                                      InProgress Applications 
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {new Date(status?.pending?.updatedOn).toLocaleString("en-US")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </CardContent>
                        </Card>
                </div>
              </div>
              </div>
              <TableContainer  style={{display:'flex',alignItems:'center', justifyContent:'center', marginBottom:'1%'}}>
            <Table
              aria-label= "simple table"
              className={classes.table}
              stickyHeader
              style={{width:'90%'}}
            >
              <TableHead>
                <TableCell align='center' style={{borderTopLeftRadius: '5px'}}>Application Name</TableCell>
                <TableCell align='center' style={{borderTopRightRadius: '5px'}}>Status Of Application</TableCell>
              </TableHead>
              <TableBody>
                {
                  tableData?.length > 0 && tableData?.map( (item,key) => (
                    <TableRow key={key} style ={ key % 2? { background : "white" }:{ background : "#eaf0ff" }}>
                      <TableCell align='center' style={{fontWeight:'bolder'}}>{item.appName}</TableCell>  
                      <TableCell >
                        {
                          stepsData.length > 0 && (
                            <Stepper
                                steps={stepsData[key]}
                                activeStep={item?.value?.filter( (item) => item.isCompleted === 1).length}
                                activeColor={item.value.find( (item) => item.isStarted === 1 && item.isCompleted === 0) ? "#eb8d34" : '#95989e'}
                                completeColor="#8cb544"
                                defaultBarColor="#eb8d34"
                                completeBarColor="#8cb544"
                                barStyle="solid"
                                circleFontSize={16}
                                titleFontSize={13}
                                size={28}
                                defaultColor='#95989e'
                                defaultTitleColor='#000'
                        />
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>

              </TableContainer>
        </div>
      </div>
     : 
     <> 

     </>
     }
    </>
  )
}

export default SimpleDialog