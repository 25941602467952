import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./global1.css";
import DefaultLogo from '../../../assets/img/LogoBg/DefaultLogo.png'
import DefaultBack from '../../../assets/img/LogoBg/background-img.png'
import { useSelector } from "react-redux";

function RegisterNew({attributes}) {
    const { REACT_APP_API_ENDPOINT } = process.env;
    const [data4, setData4] = useState({});
    const appID_redux_updated = useSelector((state) => state)
    // const [attributeConfigDetails, setAttributeConfigDetails] = useState();
    const [passwordComplexity, setPaswordComplexity] = useState({});
    const [oktaConfigDetails, setoktaConfigDetails] = useState({})

    // Getting CSS FILE LINK AND OVER RIDE CSS FROM API

    async function data2() {
        await axios
            .get(`${REACT_APP_API_ENDPOINT}admin/rendering-service/${appID_redux_updated.allFiles.APPID}`)
            .then((res) => {
                setData4(res.data);
                // setAttributeConfigDetails(res.data.attributeConfigDetails);
                setoktaConfigDetails(res.data.oktaConfigDetails)
                passwordPolicies(res.data.appBasicDetails.oktaGroupID)
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        data2();
        //eslint-disable-next-line
    }, []);

  const Header_Color = data4.custom_properties?.headerColor 
   const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  
  const BackgroundImage = data4.backgroundImg? data4.backgroundImg[0]: DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  
    //********* GETTING PASSWORD POLOCIES API */

  const passwordPolicies = async (GroupId) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/policies/get-password-policies`)
      .then((res) => {
        const policyObject = res.data.filter((item) => {
          const groups = item.conditions.people.groups.include;
          if (
            groups.filter((groupId) => groupId === GroupId).length > 0
          ) {
            return true;
          }
          return false;
        });
        if (policyObject.length > 0) {
          setPaswordComplexity(policyObject[0].settings.password.complexity);
        }else{
          setPaswordComplexity(res.data[res.data.length-1].settings.password.complexity);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

    return (
        <div>
            <>
                <Helmet>
                    <link type="text/css" rel="stylesheet" href={cssFileLink} />
                </Helmet>
                <div className="body" style={{ fontFamily: fontFamily }}>
                    <div
                        className="main-bg sign-up-sec"
                        style={{ backgroundImage: `url(${BackgroundImage})` }}
                    >
                        <div className="white-wrapper" style={{ position: 'relative', top: '-20%' }}>
                            <div className="text-center">
                                <div className="snacks-logo">
                                    <img src={logo ? logo : DefaultLogo} alt="Logo" />
                                </div>
                                <h2
                                    className="snacks-title"
                                    style={{ fontFamily: fontFamily, color: Header_Color }}
                                >
                                    Create Account
                                </h2>
                            </div>
                            <p className="text-left sub-title">
                                Create your profile to make it easier to get your Snacks quick!
                            </p>
                            <p className="before-sign-in">
                                Been here before?
                                <button
                                    style={{
                                        textDecoration: "underline",
                                        border: "none",
                                        background: "#fff",
                                        color: "#000",
                                        fontWeight: "bolder",
                                    }}
                                >
                                    Sign In
                                </button>
                            </p>
                            <div className="wrapper-inner">
                            {
                          attributes ? attributes?.baseAttributes.filter( item => item?.isSelect).map((e,i)=>{
                           if(e.key==="login"){
                            return (
                              null
                            )
                           }
                           else if(e.key==="email"){
                            return(
                              <div key={i}>                             
                                 <div className="form-group">
                                   <input type="text" className="custom-input" name={e.key}
                                   value=""
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   { e.title +  (e?.required?"*":"") }
                                   </label>
                                 </div> 
                               {
                                !oktaConfigDetails.identityFlag
                               ?
                               <>
                                 <div className= "form-group" >
                                   <input
                                     className="custom-input"
                                     type="password"
                                     style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   {"Password" + (e?.required?"*":"")}
                                   </label>
                                 </div>
                                 <div className="pwd-requirement" style={{ textAlign: 'initial' }}>
                                          <p className="font-weight-bold">
                                          Password Requirement:
                                          </p>
                                          <ul className="pl-4 mb-0" style={{ listStyleType: 'disc' }}>
                                            {passwordComplexity.minLength > 0 ? (
                                              <li>{ `Atleast ${0} characters are required`.replace(0, passwordComplexity.minLength) }</li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.minLowerCase > 0 ? (
                                              <li>
                                                A lower case letter
                                              </li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.minUpperCase > 0 ? (
                                              <li>
                                                An upper case letter
                                              </li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.minNumber > 0 ? (
                                              <li>A Number </li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.minSymbol > 0 ? (
                                              <li>A Symbol</li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.excludeUsername ? (
                                              <li>
                                                No part of UserName
                                              </li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.excludeAttributes &&
                                            passwordComplexity.excludeAttributes.filter(
                                              (item) => item === "firstName"
                                            ).length > 0 ? (
                                              <li>No part of firstName</li>
                                            ) : (
                                              <></>
                                            )}
                                            {passwordComplexity.excludeAttributes &&
                                            passwordComplexity.excludeAttributes.filter(
                                              (item) => item === "lastName"
                                            ).length > 0 ? (
                                              <li>No Part of LastName</li>
                                            ) : (
                                              <></>
                                            )}
                                          </ul>
                                </div> 
                                </>
                              : null
                              }
                             </div>
                            )
                          }else if(e.key==="userAddress"){
                            return(
                                 <div className= "form-group" >
                                   <textarea type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                   {    e.title  +  (e?.required?"*":"") }
                                   </label>
                                 </div>  
                            )
                           }
                           else if(e.type === 'array' && e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                                <div className="form-group" >
                                  <select multiple={true} className="custom-input" name={e.key}
                                    style={{ color: Input_color }}
                                  >
                                    <option value="">Please Select</option>
                                    {
                                      e.oneOf.map((option,i)=>{
                                        return(
                                          <option key={option.constVal} value={option.constVal} >{option.title}</option>
                                        )
                                      })
                                    } 
                                  </select>
                                </div>  
                            )
                           }else if(e?.enumList && e?.oneOf && e?.enumList.length>0 && e?.oneOf.length>0){
                            return(
                                <div className="form-group" >
                                  <select  className="custom-input" name={e.key}
                                    style={{ color: Input_color }}
                                  >
                                    <option value="">Please Select</option>
                                    {
                                      e.oneOf.map((option,i)=>{
                                        return(
                                          <option key={option.constVal} value={option.constVal} >{option.title}</option>
                                        )
                                      })
                                    }
                                    
                                  </select>
                                </div>  
                            )
                           }
                           else if(e?.type === "boolean"){
                            return(
                                 <div className="form-group" >
                                   <label className="custom-checkbox" style={{textAlign: 'left'}}>{  e.title  +  (e?.required?"*":"") } 
                                   <input 
                                      type="checkbox" 
                                      name={e.key}
                                      style={{ color: Input_color }}
                                   />
                                    <span className="checkmark"></span>
                                   </label>
                                 </div> 
                            )
                           }else if(e?.type === "array"){
                            return(
                                 <div className="form-group" >
                                   <input type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   required
                                   />
                                   <label className="custom-label">
                                     {  e.title   + (e?.required?"*":"")}
                                   </label>
                                 </div>  
                                
                               )

                           }
                           else {
                            return(
                                 <div className="form-group" >
                                   <input type="text" className="custom-input" name={e.key}
                                   style={{ color: Input_color }}
                                   />
                                   <label className="custom-label">
                                     {  e.title + (e?.required?"*":"")}
                                   </label>
                                 </div>  
                               )
                           }  
                          }):
                          null
                        }
                        {
                          oktaConfigDetails.mfaFlag ?
                                 <div className="form-group" >
                                   <label className="custom-checkbox">Enroll for MFA
                                   <input 
                                      type="checkbox" 
                                      name="isMFA"
                                      required
                                   />
                                    <span className="checkmark"></span>
                                   </label>
                                 </div>  
                         : null
                        }
                            
                                <p className="privacy-terms">
                                    By creating an account, you agree to our
                                    {" "}
                                    <span style={{ textDecoration: 'underline' }}>
                                        Privacy Policy
                                    </span>{" "}
                                    and  {" "}
                                    <span style={{ textDecoration: 'underline' }}>
                                        Terms & Conditions
                                    </span>
                                </p>

                                <div className="form-group">
                                    <button
                                        className="btn btn-yellow btn-block "
                                        type="button"
                                        style={{
                                            backgroundColor: SignIn_button_backgroundColor,
                                            color: Input_color,
                                        }}
                                    >
                                        Create Account
                                    </button>
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-yellow-brd btn-block"
                                        style={{ color: SignIn_button_color, backgroundColor: SecondaryButtonColor }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}

export default RegisterNew;
