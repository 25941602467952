import React, { useEffect, useState } from 'react'
import axios from 'axios';
import TextField from "@mui/material/TextField";
import { useParams } from 'react-router';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ModalImage from "react-modal-image";
import DOMPurify from 'dompurify';

function AppAssetConfigDetails({ onAppAssetChange }) {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [logoPreview, setLogoPreview] = useState();
  const [bgPreview, setBgPreview] = useState();
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [fileNameImgLogo, setFileNameImgLogo] = useState("");
  const [fileNameImgBG, setFileNameImgBG] = useState("");
  const [fileNameCSS, setFileNameCSS] = useState("");
  const [fileNameFont, setFileNameFont] = useState("");
  const Data = useParams()
  const  conAppId = DOMPurify.sanitize(Data.conAppId);
  const [cssRes, setCssRes] = useState(false);
  const [fontRes, setFontRes] = useState(false);
  const [cssURL, setCssURL] = useState("");
  const [fontURL, setFontURL] = useState("");
  const [cssFile, setCssFile] = useState([]);
  const [fontFile, setFontFile] = useState([]);

  const [logoURL, setLogoURL] = useState(null);
  const [bgURL, setBgURL] = useState(null);
  const [logoURLName, setLogoURLName] = useState([]);
  const [bgURLName, setBgURLName] = useState('');
  const [fileType, setFileType] = useState('');

  function handleChange(e) {
    e.preventDefault();
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    if (e.target.id === "1") {
      setFileType("imgLogo");
      setFileNameImgLogo(e.target.files[0].name);
    } else if (e.target.id === "2") {
      setFileType("imgBG")
      setFileNameImgBG(e.target.files[0].name);
    } else if (e.target.id === "3") {
      setFileType("css")
      setFileNameCSS(e.target.files[0].name);
    } else if (e.target.id === "4") {
      setFileType("font")
      setFileNameFont(e.target.files[0].name);
    }

    if (
      e.target.name === "logoURL" ||
      e.target.name === "backgroundImage"
    ) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (e.target.name === "logoURL") {
          setLogoPreview(reader.result);
        } else if (e.target.name === "backgroundImage") {
          setBgPreview(reader.result);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (file && fileType) {
      appconfigSubmit(file, fileType, conAppId);
    }
    //eslint-disable-next-line
  }, [file, fileName, fileType]);

  async function appconfigSubmit(file, fileType, conAppId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    formData.append("appId", conAppId);
    await axios
      .post(`${REACT_APP_API_ENDPOINT}admin/uploadFile`, formData)
      .then((res) => {
        if (res.data.responseStatus === "SUCCESS") {
          ConsumerAppDetails();
        }
      });
  }

  const ConsumerAppDetails = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/allAssets`)
      .then((res) => {
        res.data.allAssetsDetails.cssFiles ? setCssRes(true) : setCssRes(false);
        res.data.allAssetsDetails.cssFiles
          ? setCssURL(res.data.allAssetsDetails.cssFiles[0])
          : setCssRes(false);
        res.data.allAssetsDetails.fontFiles
          ? setFontRes(true)
          : setFontRes(false);
        res.data.allAssetsDetails.fontFiles
          ? setFontURL(res.data.allAssetsDetails.fontFiles[0])
          : setFontRes(false);
        res.data.allAssetsDetails.imgLogoFiles ? setLogoURL(res.data.allAssetsDetails.imgLogoFiles[0]) : setLogoURL("")
        res.data.allAssetsDetails.imgBGFiles ? setBgURL(res.data.allAssetsDetails.imgBGFiles[0]) : setBgURL("")
      })
      .catch()
  }

  useEffect(() => {
    ConsumerAppDetails();
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setCssFile(cssURL.split("/css/"));
    setFontFile(fontURL.split("/font/"));
    //    setCssRes(true)
  }, [cssURL, fontURL]);

  useEffect(() => {
    const LogoURLSplit = logoURL?.split('/logo/')
    if(LogoURLSplit){
      setLogoURLName(LogoURLSplit[1]?.split('_'))
    }
    setLogoPreview(logoURL)
  }, [logoURL]) 

  useEffect(() => {
    setBgURLName(bgURL?.split('/bg/'))
    setBgPreview(bgURL)
  }, [bgURL])

  const handleClickNext = (e) => {
    e.preventDefault();
    onAppAssetChange(true)
  }

  return (
    <div className="container-fluid">
      <div className="widget has-shadow">
        <div className="widget-body">
          <form className="form-card">
            <div className="row justify-content-between text-left">
              <div className="logoURL form-group col-sm-4 flex-column d-flex">
                <input
                  type="file"
                  name="logoURL"
                  id={1}
                  className="form-control"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <TextField
                  label="Logo URL"
                  value={fileNameImgLogo}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <label
                            htmlFor="1"
                            className="fileUpload-browse"
                          >
                            <span>Browse</span>
                          </label>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {logoURL ? <><p style={{ fontSize: '13px', color: '#707070', marginTop: '5px' }}>Uploaded Logo - {logoURLName && logoURLName.length > 0 ? logoURLName[1] : null} </p></> : <></>}
                <div className="image-preview">
                  <ModalImage
                    small={logoPreview}
                    large={logoPreview}
                    hideDownload={true}
                    hideZoom={true}
                    className="modal-image"
                  />
                </div>
              </div>
              <div className="backgroundImage form-group col-sm-4 flex-column d-flex">
                <input
                  type="file"
                  name="backgroundImage"
                  id={2}
                  onChange={handleChange}
                  className="form-control"
                  style={{ display: "none" }}
                />
                <TextField
                  label="Background Image"
                  value={fileNameImgBG}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <label
                            htmlFor="2"
                            className="fileUpload-browse"
                          >
                            <span>Browse</span>
                          </label>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {bgURL ? <><p style={{ fontSize: '13px', color: '#707070', marginTop: '5px' }}>Uploaded Background image - {bgURLName && bgURLName.length > 0 ? bgURLName[1] : null}</p></> : <></>}
                <div className="image-preview">
                  <ModalImage
                    small={bgPreview}
                    large={bgPreview}
                    hideDownload={true}
                    hideZoom={true}
                    className="modal-image"
                  />
                </div>
              </div>
              <div className="cssFile form-group col-sm-4 flex-column d-flex">
                <input
                  type="file"
                  name="cssFile"
                  id={3}
                  className="form-control"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <TextField
                  label="CSS File"
                  value={fileNameCSS}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <label
                            htmlFor="3"
                            className="fileUpload-browse"
                          >
                            <span>Browse</span>
                          </label>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {cssRes ? (
                  <span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={cssURL}
                      download={cssURL}
                      style={{ fontSize: '13px', color: '#707070', marginTop: '5px' }}
                    >
                      {"Uploaded file - " + cssFile[1]}
                    </a>
                  </span>
                ) : null}
              </div>
            </div>
            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>
            <div className="row text-left">
              <div className="font form-group col-sm-4 flex-column d-flex">
                <input
                  type="file"
                  name="font"
                  id={4}
                  className="form-control"
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <TextField
                  label="Font File"
                  value={fileNameFont}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" color="primary">
                          <label
                            htmlFor="4"
                            className="fileUpload-browse"
                          >
                            <span>Browse</span>
                          </label>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {fontRes ? (
                  <span>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={fontURL}
                      download={fontURL}
                      style={{ fontSize: '13px', color: '#707070', marginTop: '5px' }}
                    >
                      {" "}
                      {"Uploaded file - " + fontFile[1]}
                    </a>
                  </span>
                ) : null}
              </div>
            </div>
            <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>
            <div className='row text-left'>
              <div className="form-group col-sm-3 flex-column d-flex">
                <button className='btn btn-primary' onClick={handleClickNext}>Next</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AppAssetConfigDetails;
