import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Paper, TableCell, TableContainer, TableHead, TableRow, Table, TableBody, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles"; 
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import LanguageIcon from '@mui/icons-material/Language';
// import { Edit } from 'tabler-icons-react';


const useStyles = makeStyles( () => ({
  table: {
    "& .MuiTable-root":{
      tableLayout:'fixed !important',
      borderRadius:'2px'
    },
    "& .makeStyles-table-1":{
      tableLayout:'fixed',
    },
    "& .MuiTableRow-root": {
      border: '0.5px solid #ececec'
    },
    "& .MuiTableCell-body":{
      padding:'5px 10px',
      border: '0.5px solid #ececec'
    },
    "& .MuiTableCell-head":{
        padding:'15px 15px',
        border: '0.5px solid #ececec',
        borderRadius:'2px',
        fontWeight:1000,
        background: '#4F81BD',
        color: 'white',
        fontSize:'1rem'
    }
  },
}))

function Table1() {
  const { REACT_APP_API_ENDPOINT } = process.env;

  const classes = useStyles()

  const history = useHistory();
  const [search, setSearch] = useState("");


  const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
  const AccessToken = resData?.accessToken.accessToken;
  const roles = resData?.accessToken.claims.pepappconsumerroles;


  const [consumerAppId, setConsumerAppId] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRec, setTotalRec] = useState();
  let classes1, classes2;
  const [sortType, setSortType] = useState("asc");
  const [sortCol, setSortCol] = useState("appId");

  let roleNames = roles?.map((role) => role.split("consumeriam-")[1]);
  // let roleNames = roles;
  let adminFind = roleNames?.includes("admin");

  //eslint-disable-next-line
  adminFind ? (roleNames = ['admin']) : (roleNames = roleNames);


  const ConsumerAppIdCall = async (
    pageNumber,
    sortType,
    sortCol,
    roleNames
  ) => {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}admin/getAllConsumerApp`,
        {
          pageNumber: pageNumber,
          pageSize: "10000",
          sortType: sortType,
          sortColumn: sortCol,
          roleAppName: roleNames,
        },
        {
          headers: { Authorization: `Bearer ${AccessToken}` },
        }
      )
      .then((res) => {
        
        if(adminFind){
          setConsumerAppId(res.data.data);
        }else{
          let AppData = []
          roleNames.map( item => {
             res.data.data.map( appitem => {
               if( item === appitem.appId){
                  AppData.push(appitem)
               }
               return null
             })
             return null
          })
          setConsumerAppId(AppData)
        }
        setTotalRec(res.data.totalRecord);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    ConsumerAppIdCall(pageNumber, sortType, sortCol, roleNames);
    //eslint-disable-next-line
  }, [pageNumber, sortType, sortCol]);

  useEffect(() => { }, [totalRec]);

  let totalPages = Math.ceil(totalRec / 1000);

  const prevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  if (pageNumber === 1) {
    classes1 = classNames({
      "page-item": true,
      disabled: true,
    });
  } else {
    classes1 = classNames({
      "page-item": true,
      disabled: false,
    });
  }

  if (pageNumber === totalPages) {
    classes2 = classNames({
      "page-item": true,
      disabled: true,
    });
  } else {
    classes2 = classNames({
      "page-item": true,
      disabled: false,
    });
  }

  const filteredSearch = consumerAppId.filter(
    (item) =>
      item.appName.toLowerCase().includes(search.toLowerCase()) ||
      item.appId.startsWith(search) ||
      item.contactEmails.filter((email) =>
        email.toLowerCase().includes(search.toLowerCase())
      ).length !== 0 ||
      item.consumerName.toLowerCase().includes(search.toLowerCase())
  );


  const DispData = filteredSearch.length > 0 ?

    filteredSearch.map((info, key) => {
      return (
        <TableRow key={key} style ={ key % 2? { background : "white" }:{ background : "#eaf0ff" }}>
          <TableCell>{key + 1}</TableCell>
          <TableCell>{info.appId}</TableCell>
          <TableCell>{info.appName}</TableCell>
          <TableCell>{info.consumerName}</TableCell>
          <TableCell>
            {info.contactEmails.map((email) => {
              return email + "\n";
            })}
          </TableCell>
          <TableCell>{info.appRedirectionURL}</TableCell>
          <TableCell>
            {moment(new Date(info.createdDate))
              .utc()
              .format("DD MMM YYYY hh:mm A")}
          </TableCell>
          <TableCell align="center" className="d-flex justify-content-around align-items-center">
            <Tooltip title="Configure Application" placement="top">
              <IconButton 
              onClick={() => {
                const path = `/appconfig/appconfigforms/${info.appId}`;
                history.push(path);
              }}
              style={{color:'#4F81BD'}}
              >
                <BorderColorOutlinedIcon />
              </IconButton>
            </Tooltip>
            
            <Tooltip title="Configure Language" placement="top">
                <IconButton
                  onClick={
                    () => {
                      history.push(`/appconfig/langconfig/${info.appId}`)
                    }
                  }
                  style={{color:'#4F81BD'}}
                >
                  <LanguageIcon />
                </IconButton>
            </Tooltip>
            
            {/* <Edit
              // size={30}
              strokeWidth={1.5}
              color={'#0047BA'}
              onClick={() => {
                const path = `/appconfig/appconfigforms/${info.appId}`;
                history.push(path);
              }}
              style={{ cursor: 'pointer' }}
            /> */}
          </TableCell>
        </TableRow>
      );
    })
    : <TableRow style={{ textAlign: 'center' }} > <TableCell colSpan='8' >Not Found</TableCell></TableRow>


  const [isActiveAppId, setIsActiveAppId] = useState(true);
  const [isActiveAppName, setIsActiveAppName] = useState(true);
  const [isActiveConsumerName, setIsActiveConsumerName] = useState(true);
  const [isActiveCreatedDate, setIsActiveCreatedDate] = useState(true);

  const setSortTypeFunc = (e) => {
    setSortCol(e.target.id);
    if (e.target.id === "appId") {
      setIsActiveAppId(!isActiveAppId);
    } else if (e.target.id === "appName") {
      setIsActiveAppName(!isActiveAppName);
    } else if (e.target.id === "consumerName") {
      setIsActiveConsumerName(!isActiveConsumerName);
    } else if (e.target.id === "createdDate") {
      setIsActiveCreatedDate(!isActiveCreatedDate);
    }
  };

  useEffect(() => {
    isActiveAppId ? setSortType("asc") : setSortType("desc");
  }, [isActiveAppId]);

  useEffect(() => {
    isActiveAppName ? setSortType("asc") : setSortType("desc");
  }, [isActiveAppName]);

  useEffect(() => {
    isActiveConsumerName ? setSortType("asc") : setSortType("desc");
  }, [isActiveConsumerName]);

  useEffect(() => {
    isActiveCreatedDate ? setSortType("asc") : setSortType("desc");
  }, [isActiveCreatedDate]);

  return (
    <>
      <div className="col-4 d-flex justify-content-center align-items-center mb-4 mr-3" style={{ float: 'right', position: 'relative', top: '13px' }}>
        <TextField
          id="outlined-basic"
          label="Search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon style={{ color: '#98a8b4' }} />
                </IconButton>
              </InputAdornment>
            )
          }}
          value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="container-fluid">
        <div className="widget has-shadow">
          <div className="widget-body">
            <div className="table-responsive">
              {/* <table className="table table-hover mb-0 mainTable" id="tb2">
                <thead>
                  <tr>
                    <th className="RowNameMain">#</th>
                    <th>
                      Consumer ID
                      {isActiveAppId ? (
                        <i
                          className="la la-long-arrow-up"
                          id="appId"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appId" ? { color: "#5d5386" } : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="appId"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appId" ? { color: "#5d5386" } : null
                          }
                        ></i>
                      )}
                    </th>
                    <th>
                      Name
                      {isActiveAppName ? (
                        <i
                          className="la la-long-arrow-up"
                          id="appName"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appName" ? { color: "#5d5386" } : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="appName"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appName" ? { color: "#5d5386" } : null
                          }
                        ></i>
                      )}
                    </th>
                    <th>
                      <div className="table-thead-th-owner">
                        Owner
                        {isActiveConsumerName ? (
                          <i
                            className="la la-long-arrow-up"
                            id="consumerName"
                            onClick={setSortTypeFunc}
                            style={
                              sortCol === "consumerName"
                                ? { color: "#5d5386" }
                                : null
                            }
                          ></i>
                        ) : (
                          <i
                            className="la la-long-arrow-down"
                            id="consumerName"
                            onClick={setSortTypeFunc}
                            style={
                              sortCol === "consumerName"
                                ? { color: "#5d5386" }
                                : null
                            }
                          ></i>
                        )}
                      </div>
                    </th>
                    <th>Contact email</th>
                    <th>URL</th>
                    <th>
                      <div className="table-thead-th-date"></div>
                      Date
                      {isActiveCreatedDate ? (
                        <i
                          className="la la-long-arrow-up"
                          id="createdDate"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "createdDate"
                              ? { color: "#5d5386" }
                              : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="createdDate"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "createdDate"
                              ? { color: "#5d5386" }
                              : null
                          }
                        ></i>
                      )}
                    </th>
                    <th>Configure</th>
                  </tr>
                </thead>
                <tbody>{DispData}</tbody>
              </table> */}
              <TableContainer component={Paper}>
                <Table
                aria-label= "simple table"
                className={classes.table}
                >
                  <TableHead>
                     <TableRow>
                       <TableCell align="left" className="RowNameMain">#</TableCell>
                       <TableCell>Consumer ID
                      {isActiveAppId ? (
                        <i
                          className="la la-long-arrow-up"
                          id="appId"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appId" ? { color: "white" } : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="appId"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appId" ? { color: "white" } : null
                          }
                        ></i>
                      )}</TableCell>
                      <TableCell>
                      Name
                      {isActiveAppName ? (
                        <i
                          className="la la-long-arrow-up"
                          id="appName"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appName" ? { color: "white" } : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="appName"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "appName" ? { color: "white" } : null
                          }
                        ></i>
                      )}
                      </TableCell>
                      <TableCell align="left">
                        <div className="table-thead-th-owner">
                          Owner
                          {isActiveConsumerName ? (
                            <i
                              className="la la-long-arrow-up"
                              id="consumerName"
                              onClick={setSortTypeFunc}
                              style={
                                sortCol === "consumerName"
                                  ? { color: "white" }
                                  : null
                              }
                            ></i>
                          ) : (
                            <i
                              className="la la-long-arrow-down"
                              id="consumerName"
                              onClick={setSortTypeFunc}
                              style={
                                sortCol === "consumerName"
                                  ? { color: "white" }
                                  : null
                              }
                            ></i>
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">Contact email</TableCell>
                      <TableCell align='left'>URL</TableCell>
                      <TableCell align="left">
                      <div className="table-thead-th-date"></div>
                      Date
                      {isActiveCreatedDate ? (
                        <i
                          className="la la-long-arrow-up"
                          id="createdDate"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "createdDate"
                              ? { color: "white" }
                              : null
                          }
                        ></i>
                      ) : (
                        <i
                          className="la la-long-arrow-down"
                          id="createdDate"
                          onClick={setSortTypeFunc}
                          style={
                            sortCol === "createdDate"
                              ? { color: "white" }
                              : null
                          }
                        ></i>
                      )}
                      </TableCell>
                      <TableCell align='left'>Configure</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>{DispData}</TableBody>
                </Table>
              </TableContainer>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className={classes1}>
                    <Link className="page-link" to="/" onClick={prevPage}>
                      Previous
                    </Link>
                  </li>
                  <li className="page-item">
                    <Link className="page-link" to="/">
                      {pageNumber}
                    </Link>
                  </li>
                  <li className={classes2}>
                    <Link className="page-link" to="/" onClick={nextPage}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table1;
