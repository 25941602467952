import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

// import UpdateMasterLanguage from './UpdateMasterLanguage';
// import UpdateLanguage from './UpdateLanguage'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddLanguage from '../../../../assets/icons/adminpanel-icons/SVGs/Add Language_Blue.svg'
import DOMPurify from 'dompurify';
import UpdateLanguage from './UpdateLanguage';
import { useSelector } from 'react-redux';

function DisplayAppLang() {
    const { REACT_APP_API_ENDPOINT } = process.env;
    const history = useHistory()
    const [lang, setLang] = useState()
    const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    const Data = useParams()
    let conAppId = DOMPurify.sanitize(Data.conAppId)
    const getAllLanguage = async () => {
        await axios.get(`${REACT_APP_API_ENDPOINT}admin/i18n/getAllLanguage/${conAppId}`)
            .then(res => {
                setLang(res.data.lang)
            }
            ).catch(err => console.log(err))
    }

    useEffect(() => {
        getAllLanguage()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
    }, [lang])

  return (
    <>
    <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
                <div className="container-fluid">
                    <div className="widget has-shadow">
                        <div className="widget-body">
                            <div className="form-group col-sm-12 flex-column d-flex " style={{ padding: '15px' }}>
                                <div className='row justify-content-end'>
                                    <Link to={`/appconfig/addLanguages/${conAppId}`}>
                                        <div className='addClass'>
                                            <img src={AddLanguage} alt="Add Language Icon" style={{ marginRight: '10px' }}></img>
                                            <span>Add Language</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <div className='page-name'>
                                        <h4>
                                            Languages
                                        </h4>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Typography> */}
                                    <div className="table-responsive">
                                        <table id="sorting-table" className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Language Name</th>
                                                    <th>Language Code</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lang?.map(
                                                        (info, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{info.langName}</td>
                                                                    <td>{info.langCode}</td>
                                                                    <td className='td-actions'><Link to='/' onClick={(e) => {
                                                                        e.preventDefault()
                                                                        const path = `/appconfig/addLanguages/${conAppId}?langName=${info.langName}&langCode=${info.langCode}`
                                                                        history.push(path)
                                                                    }}><i className="la la-edit edit"></i></Link></td>
                                                                </tr>
                                                            )
                                                        }
                                                    )

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </div>
    </div>
    <UpdateLanguage AppId={conAppId}/>
    </>
  )
}

export default DisplayAppLang