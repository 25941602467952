import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import PepsiCoLogo from '../../../assets/img/PepsiCoLogo.png'
import { useOktaAuth } from '@okta/okta-react'
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close'
import { hamIconFlag } from '../../../Redux/Actions/add_app_id'
import {useDispatch, useSelector } from 'react-redux';


function Header() {
  const { oktaAuth } = useOktaAuth()
  const [auth, setAuth] = useState(false);
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
  const dispatch = useDispatch()
  const[openMenu, setOpenMenu] = useState(false)
  oktaAuth.isAuthenticated().then(data => {
    setAuth(data);
  })

  let name;

  if (auth) {
    const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
    const firstName = resData.idToken.claims.FirstName;
    const lastName = resData.idToken.claims.LastName;
    name = firstName.concat(' ', lastName);
  }

  const Logout = async () => {
    // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    localStorage.clear()
    await oktaAuth.signOut()
    dispatch({type: 'USER_LOGOUT'})
  };

  useEffect(() => {
    dispatch(hamIconFlag(openMenu))
    //eslint-disable-next-line
  },[openMenu])

  return (

    (auth) ?
      <>
        <header className="header">
          <nav className="navbar fixed-top">
            <div className="navbar-holder d-flex align-items-center align-middle justify-content-between">
              <div className="navbar-header">
                  <IconButton
                    className='menuIcon'
                    onClick={ () => setOpenMenu(!openMenu)}
                    size='large'
                  >
                  {
                    IconFlag ? <CloseIcon style={{transform: 'scale(1.3)'}} /> : <MenuIcon  style={{transform: 'scale(1.3)'}} />  
                  }  
                  </IconButton>
                  <Link to="/dashboard" className="navbar-brand" style={{position:'relative', left:'10%'}}>
                    <div className="brand-image brand-big">
                      <img src={PepsiCoLogo} alt="logo" className="logo-big" />
                    </div>
                    <div className="brand-image brand-small">
                      <img src={PepsiCoLogo} alt="logo" className="logo-small" />
                    </div>
                  </Link>
              </div>

              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center pull-right">


                <li className="nav-item profileIcon">
                  <PermIdentityOutlinedIcon />
                </li>

                <li className='nav-item profileName'>
                  {name}
                </li>

                <li className="nav-item logoutBtn">
                  <LogoutIcon onClick={Logout} />
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </>

      : <></>




  )
}

export default Header
