import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../../styles.css"

function UpdateLanguage({AppId}) {

  const { REACT_APP_API_ENDPOINT } = process.env;
  
  const [labelData, setLabelData] = useState([]);
  const history = useHistory();
  const [lang, setLang] = useState();
  const [selectLang, setSelectLang] = useState('nothing');
  const [hide, setHide] = useState(false)
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)

  async function getLanguageLables() {
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/i18n/labels/${AppId}?lang=${selectLang}&pageName=lbl`)
      .then((res) => {
        let ObjArr1 = []
        //eslint-disable-next-line
        Object.entries(res.data.data).map(item => {
          const key = item[0]
          const Obj = {
            key: key,
            value: item[1],
            check: false
          }
          ObjArr1.push(Obj)

        })
        setLabelData(ObjArr1);
      })
      .catch((err) => console.log(err));
  }

  const getAllLanguage = async () => {
    await axios.get(`${REACT_APP_API_ENDPOINT}admin/i18n/getAllLanguage/${AppId}`)
      .then(res => {
        setLang(res.data.lang)
      }
      ).catch(err => console.log(err))
  }

  useEffect(() => {
    getAllLanguage();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectLang === "nothing") {
      setHide(false)
    }
    //eslint-disable-next-line
  }, [])



  useEffect(() => {
    if (selectLang === "nothing") {
      setHide(false)
    } else {

      setHide(true)
      getLanguageLables();
    }
    //eslint-disable-next-line
  }, [selectLang])

  const handleChange = (e, key) => {
    const newArr = labelData.map(obj => {
      if (obj.key === key) {
        obj.value = e.target.value
        obj.check = true
      }
      return obj;
    });
    setLabelData(newArr)
  }

  const handleSelect = (e) => {
    setSelectLang(e.target.value);
  }



  const handleCheckBox = (e) => {

    if (e.target.checked) {
      const ObjArr = labelData.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            key: item.key,
            value: item.value,
            check: true
          }
          return temp
        } else {
          return item
        }
      }
      )
      setLabelData(ObjArr)
    } else {
      const ObjArr1 = labelData.map((item) => {
        if (item.key === e.target.value) {
          const temp = {
            key: item.key,
            value: item.value,
            check: false
          }
          return temp
        } else {
          return item
        }
      }
      )
      setLabelData(ObjArr1)

    }
  }

  const handleSubmit = () => {
    var obj = {};
    const len = labelData.filter(item => item.check === true).length
    labelData.filter(item => item.check === true).forEach(item => {
      obj = {
        ...obj,
        [item.key]: item.value
      }
    });
    if (len === 0) {
      alert("Select Atleast One Label")
    } else {
      updateLableSubmit(obj);
    }
  }



  async function updateLableSubmit(body) {
    await axios.post(`${REACT_APP_API_ENDPOINT}admin/i18n/updateLables/${AppId}?lang=${selectLang}`, body)
      .then(data => {
        history.push('/appconfig');
      })
      .catch(error => {
        console.log(error);
      })
  }
  return (
    <>
      <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
        <div className="container-fluid">
          <div className="row">
            <div className="page-header">
              <div className="d-flex align-items-center">
                <h2 className="page-header-title">
                  <p style={{ margin: "1" }}>LABEL CHANGE</p>
                </h2>
              </div>
              <div className="widget has-shadow">
                <div className="widget-body">
                  <div className="row justify-content-between text-left">
                    <div className='appName form-group col-sm-4 flex-column d-flex'>
                      <label className='form-control-label font-weight-bold'>Language</label>
                      <select name='appName' className='form-control' onChange={handleSelect}>
                        <option value="nothing" >Select Language</option>
                        {
                          lang?.map(item => {
                            return <option key={item.langCode} value={item.langCode}>{item.langName}</option>
                          })
                        }
                      </select>
                    </div>
                  </div>

                  {
                    hide && hide ?
                    <>
                  <div className="table-responsive">
                    <table className="table table-bordered table-striped HtmlTable mb-0" id="tb2">
                      <thead>
                        <tr>
                          <th>
                            <span>#</span>
                          </th>
                          <th>
                            <span>Select</span>
                          </th>
                          <th>
                            <span>Label Key</span>
                          </th>
                          <th>
                            <span>Value</span>
                          </th>
                        </tr>
                      </thead>
                      {hide ?
                        <>
                          <tbody>
                            {
                              labelData.map((item, index) => {

                                return (
                                  <tr key={index}>
                                    <td>{index+1}</td>
                                    <td><input type="checkbox" value={item.key} onChange={handleCheckBox} checked={item.check}/></td>
                                    <td>{item.key}</td>
                                    <td><input className="form-control input-sm" name={item.key} type="text" value={item.value} onChange={(e) => handleChange(e, item.key)} /></td>
                                  </tr>

                                )
                              })
                            }
                          </tbody>
                        </>
                        :
                        null
                      }

                    </table>
                  </div>
                  <div className="widget sticky-btn">
                      <div className="row text-left">
                        <div className='form-group col-sm-3 flex-column d-flex' style={{ marginTop: '30px' }}  >
                          <button type="submit" className='btn btn-primary' onClick={handleSubmit}>SUBMIT</button>
                        </div>
                      </div>
                  </div>
                  </> : null 
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateLanguage;
