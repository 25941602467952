import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {Base64} from 'js-base64'
import { TextField } from '@mui/material';
import {useFormik} from 'formik'
import * as yup from 'yup'

function GenerateUrl({conAppId, clientId}) {
  const { REACT_APP_API_ENDPOINT, REACT_APP_UNIFIED_URL,REACT_APP_OKTA_HOST_URL} = process.env;

  const [BasicAppDetails, setBasicAppDetails] = useState(null)
  const [URLChange, setURLChange] = useState('')
  
  
  
  const ConsumerAppDetails = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/appBasicDetail`)
      .then((res) => {
        setBasicAppDetails(res.data.appBasicDetails)
      })
      .catch((err) => { console.log(err); })
  }
  
  useEffect(() => {
    ConsumerAppDetails();
    //eslint-disable-next-line
  }, [])
  const RedirectionURL = Base64.encode(BasicAppDetails?.appRedirectionURL)

  const GenerateCustomURL = () => {
     setURLChange(formik?.values?.CustomField?.trim())
     formik.setFieldValue('CustomField', '')
  }

  

  const formik = useFormik({
    initialValues:{
      CustomField: ''
    }, 
    validationSchema: yup.object({
      CustomField: yup.string().test("Required", "Enter Valid URL" , (value) => {
        //eslint-disable-next-line
        let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
        if(formik.values.CustomField.trim().match(regex)){
          return true;
        }else{
         return false;
        }
      })
    })
  })
  
  return (
    <>
    <div className='row justify-content-start text-left m-2'>
      <div className='col-sm-3'>
        <TextField 
          fullWidth
          name='CustomField'
          label="Custom Domail URL"
          value={formik.values.CustomField}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik?.errors?.CustomField ?  true : false}
          helperText={formik.errors.CustomField}
        />
      </div>
      <div className="form-group col-sm-2 flex-column d-flex">
                <button
                  className="btn btn-primary p-3"
                  type="button"
                  disabled={formik.errors.CustomField ? true : false}
                  onClick={GenerateCustomURL}
                >
                  Re-Generate URL's
                </button>
      </div>
      </div>
     
    <table className="table table-bordered m-3 GenerateURLTable" id="tb2">
      <tbody>
        <tr>
           <td className='RowName'>Create Account</td>
           <td className='RowUrl'>{URLChange ? URLChange : REACT_APP_UNIFIED_URL}/create-account/{BasicAppDetails?.appId}?LanguageCode=<p className='boldcolor'>&#60;language_code&#62;</p>&redirectUrl={RedirectionURL}</td>
        </tr>
        <tr>
          <td>SignIn</td>
          <td>
          {REACT_APP_OKTA_HOST_URL}/<p className='boldcolor'>&#60;authorization_id&#62;</p>/v1/authorize?client_id={clientId}&redirect_uri=<p className='boldcolor'>&#60;redirect_uri&#62;</p>&scope=openid%20profile%20email%20offline_access&response_type=code&response_mode=form_post&code_challenge_method=S256&<br />code_challenge=<p className='boldcolor'>&#60;code_challenge&#62;</p>&state=<p className='boldcolor'>&#60;state&#62;</p>&nonce=<p className='boldcolor'>&#60;nonce&#62;</p>&login_hint=%7B%22AppId%22%3A{conAppId}%2C%22LanguageCode%22%3A%22<p className='boldcolor'>&#60;language_code&#62;</p>%22%7D
          </td>
        </tr>
        <tr>
          <td>Forgot Password</td>
          <td>{URLChange ? URLChange : REACT_APP_UNIFIED_URL}/forgotpassword/{BasicAppDetails?.appId}?LanguageCode=<p className='boldcolor'>&#60;language_code&#62;</p></td>
        </tr>
        <tr>
          <td>Reset Password</td>
          <td>{URLChange ? URLChange : REACT_APP_UNIFIED_URL}/resetpassword/{BasicAppDetails?.appId}/<p className='boldcolor'>&#60;RECOVERY_TOKEN&#62;</p>?languageCode=<p className='boldcolor'>&#60;language_code&#62;</p>&email=<p className='boldcolor'>&#60;EMAIL_ID&#62;</p></td>
        </tr>
        <tr>
          <td>Change Password</td>
          <td>{URLChange ? URLChange : REACT_APP_UNIFIED_URL}/changepassword/{BasicAppDetails?.appId}/<p className='boldcolor'>&#60;UserID&#62;</p>?token=<p className='boldcolor'>&#60;ACCESS_TOKEN&#62;</p>&languageCode=<p className='boldcolor'>&#60;language_code&#62;</p></td>
        </tr>
        <tr>
          <td>Update Profile </td>
          <td>{URLChange ? URLChange : REACT_APP_UNIFIED_URL}/update-profile/{BasicAppDetails?.appId}/<p className='boldcolor'>&#60;UserID&#62;</p>?token=<p className='boldcolor'>&#60;ACCESS_TOKEN&#62;</p>&languageCode=<p className='boldcolor'>&#60;language_code&#62;</p></td>
        </tr>
      </tbody>
    </table>
    </>
  )
}

export default GenerateUrl