import React, { useEffect, useState } from 'react'
import axios from 'axios';
import TextField from "@mui/material/TextField";
import { useParams } from 'react-router';
import { useFormik } from "formik";
import * as yup from "yup";
import DOMPurify from 'dompurify';

function CustomConfigDetails({ onCustomConfigChange }) {

  const { REACT_APP_API_ENDPOINT } = process.env;
  const Data = useParams()
  const  conAppId  = DOMPurify.sanitize(Data.conAppId);


  const [signInButtonColorCode, setSignInButtonColorCode] = useState('')
  const [forgotPasswordColorCode, setForgotPasswordColorCode] = useState('')
  const [signInButtonBackgroundColorCode, setSignInButtonBackgroundColorCode] = useState('')
  const [inputColorCode, setInputColorCode] = useState('')
  const [HeaderColorCode, setHeaderColorCode] = useState('')
  const [SecondaryButtonColorCode, setSecondaryButtonColorCode] = useState('')

  let errorObj = {
    signInButtonColorFlag: false,
    forgotPasswordColorFlag: false,
    signInButtonBackgroundColorFlag: false,
    inputColorFlag: false,
    HeaderColorFlag: false,
    SecondaryButtonColorFlag:false,
  }

  const [errorFlag, setErrorFlag] = useState(errorObj)

 

  //Formik validation
  const formik = useFormik({
    initialValues: {
      signInButtonColor: "#000000",
      forgotPasswordColor: "#000000",
      signInButtonBackgroundColor: "#000000",
      inputColor: "#000000",
      headerColor: "#000000",
      SecondaryButtonColor: "#000000",
      fontFamily: "https://fonts.cdnfonts.com/css/sofia-pro",
    },
    validationSchema: yup.object({
      signInButtonColor: yup
        .string()
        .required("SignIn Button Color is Required"),
      forgotPasswordColor: yup
        .string()
        .required("Forgot Password Color is required"),
      signInButtonBackgroundColor: yup
        .string()
        .required("SignIn Button Background Color is Required"),
      inputColor: yup.string().required("Input Color is required"),
      headerColor: yup.string().required("HeaderColor is Required"),
      SecondaryButtonColor: yup.string().required("SecondaryButtonColor is Required"),
      fontFamily: yup.string().required("Font Family is Required"),
    }),
    onSubmit: (userInputData) => {
      const conAppIdDup = conAppId;
      if (signInButtonColorCode && forgotPasswordColorCode && signInButtonBackgroundColorCode && inputColorCode && HeaderColorCode && SecondaryButtonColorCode) {
        appconfigSubmit(
          conAppIdDup,
          signInButtonColorCode,
          forgotPasswordColorCode,
          signInButtonBackgroundColorCode,
          inputColorCode,
          HeaderColorCode,
          SecondaryButtonColorCode,
          userInputData.fontFamily
        )
      }
    },
  });


  

  async function appconfigSubmit(
    conAppId,
    signInButtonColor,
    forgotPasswordColor,
    signInButtonBackgroundColor,
    inputColor,
    headerColor,
    secondaryButtonColor,
    fontFamily
  ) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}admin/save-custom-app-config`,
        {
          signInButtonColor: signInButtonColor,
          forgotPasswordColor: forgotPasswordColor,
          signInButtonBackgroundColor: signInButtonBackgroundColor,
          inputColor: inputColor,
          fontFamily: fontFamily,
          headerColor:headerColor,
          secondaryButtonColor:secondaryButtonColor,
          // "appId": appId,
          appId: conAppId,
        })
      .then((res) => {
        onCustomConfigChange(true)
      });
  }


  const ConsumerAppDetails = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/getConsumerAppDetails/${conAppId}/customConfigFile`)
      .then((res) => {
        formik.setFieldValue("appId", res.data.customConfigDetails.appId);
        formik.setFieldValue(
          "fontFamily",
          res.data.customConfigDetails?.fontFamily ?
          res.data.customConfigDetails.fontFamily : "#000000"
        );
        formik.setFieldValue(
          "forgotPasswordColor",
          res.data.customConfigDetails?.forgotPasswordColor ? 
          res.data.customConfigDetails.forgotPasswordColor : "#000000"
        );
        formik.setFieldValue(
          "inputColor",
          res.data.customConfigDetails?.inputColor ?
          res.data.customConfigDetails.inputColor : "#000000"
        );
        formik.setFieldValue(
          "signInButtonBackgroundColor",
          res.data.customConfigDetails?.signInButtonBackgroundColor ?
          res.data.customConfigDetails.signInButtonBackgroundColor : "#000000"
        );
        formik.setFieldValue(
          "signInButtonColor",
          res.data.customConfigDetails?.signInButtonColor ?
          res.data.customConfigDetails.signInButtonColor : "#000000"
        );
        formik.setFieldValue( "headerColor", res.data.customConfigDetails?.headerColor ? res.data.customConfigDetails.headerColor : "#000000");
        formik.setFieldValue( "SecondaryButtonColor", res.data.customConfigDetails?.secondaryButtonColor ? res.data.customConfigDetails.secondaryButtonColor : "#000000")


        setSignInButtonColorCode(res.data.customConfigDetails?.signInButtonColor ? res.data.customConfigDetails.signInButtonColor : "#000000")
        setForgotPasswordColorCode(res.data.customConfigDetails?.forgotPasswordColor ? res.data.customConfigDetails?.forgotPasswordColor : "#000000" )
        setSignInButtonBackgroundColorCode(res.data.customConfigDetails?.signInButtonBackgroundColor ? res.data.customConfigDetails?.signInButtonBackgroundColor :"#000000")
        setInputColorCode(res.data.customConfigDetails?.inputColor ? res.data.customConfigDetails?.inputColor : "#000000")
        setHeaderColorCode(res.data.customConfigDetails?.headerColor ? res.data.customConfigDetails.headerColor : "#000000" )
        setSecondaryButtonColorCode(res.data.customConfigDetails?.secondaryButtonColor ? res.data.customConfigDetails.secondaryButtonColor : "#000000")
      })
      .catch()
  }

  useEffect(() => {
    ConsumerAppDetails();
    //eslint-disable-next-line
  }, [])


  return (
    <div className="container-fluid">
      <div className="widget has-shadow">
        <div className="widget-body">
          <form className="form-card">
            <div className="row justify-content-between text-left">
              <div className="signInButtonColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="SignIn Button Text Color*"
                      value={signInButtonColorCode}
                      onChange={(e) => { 
                        setSignInButtonColorCode(e.target.value) 
                        let signInBtnClrFlag = {...errorFlag, signInButtonColorFlag: true}
                        setErrorFlag(signInBtnClrFlag)
                      }}
                      error={errorFlag.signInButtonColorFlag && signInButtonColorCode === ""}
                      helperText={errorFlag.signInButtonColorFlag && signInButtonColorCode === "" ?
                        'Sign In button color is required'
                        : ' '}
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="signInButtonColor"
                      value={signInButtonColorCode}
                      onChange={(e) => { setSignInButtonColorCode(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              <div className="forgotPasswordColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="Forgot Password Text Color*"
                      value={forgotPasswordColorCode}
                      onChange={(e) => { 
                        setForgotPasswordColorCode(e.target.value) 
                        let forgotPasswordClrFlag = {...errorFlag, forgotPasswordColorFlag: true}
                        setErrorFlag(forgotPasswordClrFlag)
                      }}
                      error={ errorFlag.forgotPasswordColorFlag && forgotPasswordColorCode === ""}
                      helperText={ errorFlag.forgotPasswordColorFlag && forgotPasswordColorCode === "" ?
                        'Forgot Password text color is required'
                        : ' '
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="forgotPasswordColor"
                      value={forgotPasswordColorCode}
                      onChange={(e) => { setForgotPasswordColorCode(e.target.value) }}
                    />
                  </div>
                </div>
              </div>

              <div className="signInButtonBackgroundColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="SignIn Button Background  Color*"
                      value={signInButtonBackgroundColorCode}
                      onChange={(e) => { 
                        setSignInButtonBackgroundColorCode(e.target.value) 
                        let signInButtonBackgroundClrFlag = {...errorFlag, signInButtonBackgroundColorFlag: true}
                        setErrorFlag(signInButtonBackgroundClrFlag)
                      }}
                      error={ errorFlag.signInButtonBackgroundColorFlag && signInButtonBackgroundColorCode === ""}
                      helperText={ errorFlag.signInButtonBackgroundColorFlag && signInButtonBackgroundColorCode === "" ?
                        'SignIn button background color is required'
                        : " "
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="signInButtonBackgroundColor"
                      value={signInButtonBackgroundColorCode}
                      onChange={(e) => {setSignInButtonBackgroundColorCode(e.target.value)}}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span> */}
            <div className="row text-left">
              <div className="inputColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="Input Text Color*"
                      value={inputColorCode}
                      onChange={(e) => { 
                        setInputColorCode(e.target.value) 
                        let inputClrFlag = {...errorFlag, inputColorFlag:true}
                        setErrorFlag(inputClrFlag)
                      }}
                      error={ errorFlag.inputColorFlag && inputColorCode === ""}
                      helperText={errorFlag.inputColorFlag && inputColorCode === "" ?
                        'Input text color is required'
                        : ' '
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="inputColor"
                      value={inputColorCode}
                      onChange={(e) => { setInputColorCode(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              <div className="headerColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="Header Color*"
                      value={HeaderColorCode}
                      onChange={(e) => { 
                        setHeaderColorCode(e.target.value) 
                        let HeaderClrFlag = {...errorFlag, HeaderColorFlag:true}
                        setErrorFlag(HeaderClrFlag)
                      }}
                      error={ errorFlag.HeaderColorFlag && HeaderColorCode === ""}
                      helperText={errorFlag.HeaderColorFlag && HeaderColorCode === "" ?
                        'Header Color is required'
                        : ' '
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="headerColor"
                      value={HeaderColorCode}
                      onChange={(e) => { setHeaderColorCode(e.target.value) }}
                    />
                  </div>
                </div>
              </div>
              <div className="SecondaryButtonColor form-group col-sm-4 flex-column d-flex">
                <div className="row text-left">
                  <div className="form-group col-sm-10 flex-column d-flex">
                    <TextField
                      label="Secondary Button Color*"
                      value={SecondaryButtonColorCode}
                      onChange={(e) => { 
                        setSecondaryButtonColorCode(e.target.value) 
                        let SecondaryButtonClrFlag = {...errorFlag, SecondaryButtonColorFlag:true}
                        setErrorFlag(SecondaryButtonClrFlag)
                      }}
                      error={ errorFlag.SecondaryButtonColorFlag && SecondaryButtonColorCode === ""}
                      helperText={errorFlag.SecondaryButtonColorFlag && SecondaryButtonColorCode === "" ?
                        'Secondary Button Color is required'
                        : ' '
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 flex-column d-flex span2">
                    <input
                      type="color"
                      id="input-color"
                      className="input-sm"
                      name="SecondaryButtonColor"
                      value={SecondaryButtonColorCode}
                      onChange={(e) => { setSecondaryButtonColorCode(e.target.value) }}
                    />
                  </div>
                </div>
              </div>

            </div>
              <div className='row text-left'>
                  <div className="fontFamily form-group col-sm-4 flex-column d-flex">
                      <TextField
                        label="Font Family*"
                        name="fontFamily"
                        className="form-control"
                        value={formik.values.fontFamily}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.fontFamily ? (
                        <span
                          style={{ color: "red", fontSize: "10px" }}
                        >
                          {formik.errors.fontFamily}
                        </span>
                      ) : null}
                  </div>
              </div>
              <span
              className="input-group-btn"
              style={{ width: "20px" }}
            ></span>
            <div className="row text-left">
              <div className="form-group col-sm-3 flex-column d-flex">
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Update Custom Details
                </button>
              </div>

              <div className="form-group col-sm-2 flex-column d-flex">
                <button
                  className="btn btn-secondary"
                  type="submit"
                  onClick={formik.handleReset}
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CustomConfigDetails;
