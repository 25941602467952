import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import "./global1.css";
import DefaultLogo from "../../../assets/img/LogoBg/DefaultLogo.png";
import DefaultBack from "../../../assets/img/LogoBg/background-img.png";
import { useSelector } from "react-redux";

function SignInU() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [data4, setData4] = useState({});
  const appID_redux_updated = useSelector((state) => state);

  async function data2() {
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/rendering-service/${appID_redux_updated.allFiles.APPID}`
      )
      .then((res) => {
        setData4(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  const Header_Color = data4.custom_properties?.headerColor;
  const forgotPassword_Color = data4.custom_properties?.forgotPasswordColor;
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const Input_color = data4.custom_properties?.signInButtonColor;
  const fontFamily = data4.custom_properties?.fontFamily;

  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;
  // const fontLink = data4.custom_properties.fontFamily;

  return (
    <div>
      <>
        <Helmet>
          <link type="text/css" rel="stylesheet" href={cssFileLink} />
        </Helmet>
        <div className="body" style={{ fontFamily: fontFamily }}>
          <div
            className="main-bg sign-in-sec"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
          >
            <div className="white-wrapper">
              <div className="text-center">
                <div className="snacks-logo">
                  <img src={logo ? logo : DefaultLogo} alt="Logo" />
                </div>
                <h2
                  className="snacks-title"
                  style={{ color: `${Header_Color}`, fontFamily: fontFamily }}
                >
                  {" "}
                  Sign In
                </h2>
              </div>
              <div className=" wrapper-inner">
                <div className="form-group">
                  <input
                    className="custom-input"
                    type="text"
                    name="email"
                    style={{ color: Input_color }}
                    required
                  />
                  <label className="custom-label">Email*</label>
                </div>

                <div className="form-group">
                  <input
                    className="custom-input"
                    type="password"
                    name="password"
                    style={{ color: Input_color }}
                    required
                  />
                  <label className="custom-label">Password*</label>
                </div>

                <div className="form-group d-flex justify-content-end">
                  <Link
                    to="#"
                    className="forgot-password btn-text"
                    style={{ color: forgotPassword_Color }}
                  >
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-yellow btn-block"
                    style={{ background: SignIn_button_color }}
                  >
                    Sign In
                  </button>
                </div>
                <div className="form-group">
                  <div className="or">
                    <span>OR</span>
                  </div>
                </div>
                <div className="form-group text-center mb-0">
                  <Link
                    to="#"
                    className="create-account btn-text"
                    style={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default SignInU;
