import React, { useState, useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from 'chart.js';
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { Bar, Line } from 'react-chartjs-2';
import { Button, Card, CardContent, FormControlLabel, Grid, Typography } from '@mui/material';
import './Dashboard.css'
import { CheckPicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import AddAppIconB from '../../assets/icons/adminpanel-icons/SVGs/Add ConsumerApp_Blue.svg'
import { useOktaAuth } from '@okta/okta-react';
import { USER_LOGIN } from '../../Redux/Actions/AuthActions';
import Switch from '@mui/material/Switch';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';






ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
);



export const options = {
  
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        font: {
            size: 18,
            weight:'bold'
        },
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        font: {
            size: 14,
            weight:'bold'
        }
      },
      max:10,
    }
  },
  plugins: {
    tooltip: {
      backgroundColor: '#4F81BD',
      borderColor:'white',
      borderWidth:1,
      padding:10,
      titleFontSize: 16,
      titleFontColor: '#4F81BD',
      bodyFontColor: '#4F81BD',
      bodyFontSize: 14,
      bodyFontWeight:'bold',
      displayColors: false
  },
    legend: {
      position: 'end',
      
    },
    title: {
      display: true,
      text: 'No. of Application',
      color: '#4F81BD',
      position: 'top',
      fullSize: true,
      padding: 0,
      align: 'center',
      font: {
        family: 'Arial',
        size: 16,
        weight: 'bold',
      }
    }
  },
};


export const dOptions = {
  
  plugins: {
    tooltip: {
      backgroundColor: '#4F81BD',
      borderColor:'white',
      borderWidth:1,
      padding:10,
      titleFontSize: 16,
      titleFontColor: '#4F81BD',
      bodyFontColor: '#4F81BD',
      bodyFontSize: 14,
      bodyFontWeight:'bold',
      displayColors: false
  },
    legend: {
      position: 'bottom',
      labels:{
        font:{
          family: 'Arial',
          size: 14,
          weight: 'bold',
        }
      }
    },
    title: {
      display: true,
      text: 'No. of Languages',
      color: '#4F81BD',
      position: 'top',
      fullSize: true,
      align: 'center',
      font: {
        family: 'Arial',
        size: 16,
        weight: 'bold',
      }
    }
  },

}



function Dashboard() {
  const { REACT_APP_API_ENDPOINT } = process.env;

  //********* SIMPLE DIALOG  */
  let history = useHistory()
  const { authState } = useOktaAuth();
  const resData = JSON.parse(localStorage.getItem("okta-token-storage"));
  const ConsumerAppIds = resData.accessToken.claims.pepappconsumerroles.map( appid => appid.split('-')[1])
  let roles = resData?.accessToken?.claims?.pepappconsumerroles?.map( item => item)
  let adminFind = ConsumerAppIds?.includes("admin");
  const AccessToken = resData.accessToken.accessToken;
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)
  const [widgetData, setWidgetData] = useState(0);
  const [oktaAppIDArray, setOktaAppIDArray] = useState([])
  const [DataBaseUserData, setDataBaseUserData] = useState({})
  const dispatch = useDispatch()
  useEffect( () => {
    dispatch({ type:USER_LOGIN,payload:{authState: authState,isAuthenticated:authState?.isAuthenticated,   roles:roles} })
  //eslint-disable-next-line
  },[])

  const [mauData, setMAUData] = useState({
    startDate: new Date().setFullYear( new Date().getFullYear() - 1) ,
    endDate: new Date(),
    checkpickerFlag:false,
    chartFormData:{
      date:[new Date("2022-11-01"), new Date()],
      appName:[]
    },
    dropClose:false,
    dashboardData:[],
    userDatalabels:[],
    checked:false
  })
  
  const [costData, setCostData] = useState({
    costdropClose: false,
    dashboardCostData:[],
    userCostlabels:[],
    costChartFormData: {
      date:[new Date("2022-11-01"), new Date()],
      appName:[]
    },
    costCheckpickerFlag:false,
    costStartDate: new Date().setFullYear( new Date().getFullYear() - 1),
    costEndDate: new Date()
  })

  const [smsCostData, setSmsCostData] = useState({
    smsCostdropClose: false,
    dashboardSmsCostData:[],
    userSmsCostlabels:[],
    smsCostChartFormData: {
      date:[new Date("2022-11-01"), new Date()],
      appName:[]
    },
    smsCostCheckpickerFlag:false,
    smsCostStartDate: new Date().setFullYear( new Date().getFullYear() - 1),
    smsCostEndDate: new Date()
  })
 
  const [disableItemValues, setDisableItemValues] = useState([])
  const [disableCostItemValues, setDisableCostItemValues]= useState([])
 
  //**********  FETCHING THE RANDOM COLOR */
  function getRandomLightColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const red = Math.floor((Math.abs(Math.sin(hash) * 16777215)) % 150 + 100);
    const green = Math.floor((Math.abs(Math.sin(hash + 1) * 16777215)) % 150 + 100);
    const blue = Math.floor((Math.abs(Math.sin(hash + 2) * 16777215)) % 150 + 100);
    const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
    return color;
  }
  
  const options = {
    type:'line',
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
              weight:'bold'
          },
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: {
              weight:'bold'
          }
        },
        min:0,
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: '#4F81BD',
        borderColor:'white',
        borderWidth:1,
        padding:10,
        titleFontSize: 18,
        titleFontColor: '#4F81BD',
        bodyFontColor: '#4F81BD',
        bodyFontSize: 16,
        bodyFontWeight:'bold',
        displayColors: true
    },
      legend: {
        position: "top"
      },
      title: {
        display: true,
        text: "Monthly Active User Count Per Application",
      align: 'center',
      color: '#00000',
      font: {
        weight: 'bold',
      }
      }
    },
  };

   const data = {
    labels: mauData.userDatalabels,
    datasets: mauData.dashboardData,
  };

  const cost_option={
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
          font:{
            weight:'bold'
          }
        },
        ticks: {
          font: {
              weight:'bold'
          },
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: {
              weight:'bold'
          }
        },
        min:0,
  
      }
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: 'Monthly Costing Per Application',
        color: '#000000',
        fullSize: true,
        align: 'center',
        font: {
          weight: 'bold',
        }
      },
      tooltip: {
        callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                    label += ': ';
                }
                if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
            }
        },
      backgroundColor: '#4F81BD',
      borderColor:'white',
      borderWidth:1,
      padding:10,
      titleFontSize: 18,
      titleFontColor: '#4F81BD',
      bodyFontColor: '#4F81BD',
      bodyFontSize: 16,
      bodyFontWeight:'bold',
      displayColors: true,
    }
    }
  }
 
  const cost_data={
    labels: costData?.userCostlabels,
    datasets:costData?.dashboardCostData
  }

  const smsCost_option={
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
          font:{
            weight:'bold'
          }
        },
        ticks: {
          font: {
              weight:'bold'
          },
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: {
              weight:'bold'
          }
        },
        min:0,
  
      }
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: 'Monthly SMS Usage Per Application',
        color: '#000000',
        fullSize: true,
        align: 'center',
        font: {
          weight: 'bold',
        }
      },
      tooltip: {
      backgroundColor: '#4F81BD',
      borderColor:'white',
      borderWidth:1,
      padding:10,
      titleFontSize: 18,
      titleFontColor: '#4F81BD',
      bodyFontColor: '#4F81BD',
      bodyFontSize: 16,
      bodyFontWeight:'bold',
      displayColors: true,
    }
    }
  }

  const smsCost_data={
    labels: smsCostData?.userSmsCostlabels,
    datasets:smsCostData?.dashboardSmsCostData
  }
  
  const DateFormat = (date) => {
    const getFullYear = new Date(date).getFullYear()
    const getMonth = new Date(date).getMonth();
     return `${getFullYear}-${getMonth + 1}`
  }

  const getConsumersData = async (CharForm, appData, from, to) => {
    let From = DateFormat(from)
    let To = DateFormat(to)
    let appIds = appData.filter( item => item !== 'All-All').map( item => item.split('-')[0])
    await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-consumer-user-counts`,{
        "from":From,
        "to":To,
        "appIds": appIds,
        "consumerAppIds": adminFind ? [] : ConsumerAppIds
    },
      {
        headers: { Authorization: `Bearer ${AccessToken}` },
      }
    ).then( res => {
      if(CharForm === "MAU"){
        let userDataArray = res.data.map( item => {
          mauData.userDatalabels=[]
          mauData.dashboardData=[]
          if(mauData?.userDatalabels.length === 0){
            setMAUData((prev) => ({
               ...prev,
               userDatalabels:item.months
            }))
          }
          let userObject = {
            label : item.appName,
            data: item.userCounts,
            backgroundColor: getRandomLightColor(item.appName),
            borderColor:getRandomLightColor(item.appName)
          }
          return userObject
        })
        let filteredObject = [...mauData.dashboardData, ...userDataArray]
        setMAUData( (prev) => ({
           ...prev,
           dashboardData: [...prev.dashboardData, ...filteredObject]
        }))
      }else if(CharForm === 'COSTING'){
        let userCostArray = res.data.map( item => {
          if(costData?.userCostlabels.length === 0){
            setCostData((prev) => ({...prev, userCostlabels:item.months}))
          }
          let userObject = {
            label : item.appName,
            data: item.costCounts,
            backgroundColor: getRandomLightColor(item.appName)
          }
          return userObject
        })
       let filteredObjectForCost= [...costData.dashboardCostData,...userCostArray]
        setCostData( (prev) => ({
          ...prev,
          dashboardCostData: [...prev.dashboardCostData, ...filteredObjectForCost]
       }))
      }else if(CharForm === 'SMS'){
        let userCostArray = res.data.map( item => {
          if(smsCostData?.userSmsCostlabels.length === 0){
            setSmsCostData((prev) => ({...prev, userSmsCostlabels:item.months}))
          }
          let userObject = {
            label : item.appName,
            data: item.smsCounts,
            backgroundColor: getRandomLightColor(item.appName)
          }
          return userObject
        })
       let filteredObjectForCost= [...smsCostData.dashboardSmsCostData,...userCostArray]
        setSmsCostData( (prev) => ({
          ...prev,
          dashboardSmsCostData: [...prev.dashboardSmsCostData, ...filteredObjectForCost]
       }))

      }
    }).catch( err => {
      console.log({err})
    })
  };

  const getConsumerYearlyData = async (CharForm, appData, from , to) => {
    let From = DateFormat(from)
    let To = DateFormat(to)
    let appIds = appData.filter( item => item !== 'All-All').map( item => item.split('-')[0])
    await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-consumer-user-count-yearly`,{
      "from":From,
      "to":To,
      "appIds": appIds,
      "consumerAppIds": adminFind ? [] : ConsumerAppIds
  },
    {
      headers: { Authorization: `Bearer ${AccessToken}` },
    }
  ).then( res => {
    if(CharForm === "MAU"){
      let userDataArray = res.data.map( item => {
        mauData.userDatalabels = []
        mauData.dashboardData=[]
        if(mauData?.userDatalabels.length === 0){
          setMAUData((prev) => ({
             ...prev,
             userDatalabels:item.months
          }))
        }
        let userObject = {
          label : item.appName,
          data: item.userCounts,
          backgroundColor: getRandomLightColor(item.appName),
        }
        return userObject
      })
      
      let filteredObject = [...mauData.dashboardData, ...userDataArray]
      setMAUData( (prev) => ({
         ...prev,
         dashboardData: [...prev.dashboardData, ...filteredObject]
      }))
    }
  }).catch( err => {
    console.log({err})
  })
  }

  

// LINE CHART CONFIG AND CODE END

  const getWidgetData = async () => {
    await axios
      .get(
        `${REACT_APP_API_ENDPOINT}admin/get-widget-details`,
        {
          headers: { Authorization: `Bearer ${AccessToken}` },
        }
      )
      .then((res) => {
        setWidgetData(res.data);
      })
      .catch((err) => console.log(err));
  }

  const getUserData = async () => {
    await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-count`,{
      "consumerList" : adminFind ? [] : ConsumerAppIds
    },{
      headers: { Authorization: `Bearer ${AccessToken}` },
    }).then( res => {
      setDataBaseUserData(res?.data[0])
    }).catch( err => {
       console.log({err})
    })
  }

  const getOktaAppID = async () => {
    await axios
      .post(`${REACT_APP_API_ENDPOINT}admin/dashboard/get-dashboard-applications`,{
        "consumerList" : adminFind ? [] : ConsumerAppIds
      },
      {
        headers: {
          Authorization: `Bearer ${AccessToken}`,
        },
      })
      .then((res) => {
        let optionsOktaAppArr = []
        // optionsOktaAppArr.push({
        //   'label': "All",
        //   'id': "All",
        //   'consumerAppId': "All"
        // })
        res.data.map((item) => {
          if(adminFind){
            optionsOktaAppArr.push({
              'label': item.label,
              'id': item.id
            })
          }else if(!adminFind){
                optionsOktaAppArr.push({
                  'label': item.label,
                  'id': item.id
                })
          }  
          return item
        })
        setOktaAppIDArray(optionsOktaAppArr)
      })
      .catch(err => console.log(err))
  }

useEffect( () => {
  setMAUData((prev) => ({
    ...prev,
    dashboardData:[],
    userDatalabels:[],
    dropClose:false
  }))
//eslint-disable-next-line
},[mauData?.checked])

  useEffect(() => {
    setMAUData((prev) => ({
      ...prev,
      dashboardData:[],
      userDatalabels:[]
    }))
    if(mauData?.checked){
      mauData?.checked && getConsumerYearlyData("MAU" ,mauData?.chartFormData?.appName, mauData?.startDate, mauData?.endDate);
    }else{
      mauData?.dropClose && getConsumersData("MAU" ,mauData?.chartFormData?.appName, mauData?.startDate, mauData?.endDate);
    }
    //eslint-disable-next-line
  }, [mauData?.dropClose, mauData?.checked])

  useEffect(() => {
    setCostData((prev) => ({...prev, dashboardCostData:[]}))
   costData?.costdropClose && getConsumersData("COSTING",costData?.costChartFormData?.appName, costData?.costStartDate, costData?.costEndDate)
    //eslint-disable-next-line
  },[costData?.costdropClose])

  useEffect(() => {
    setSmsCostData((prev) => ({...prev, dashboardSmsCostData:[]}))
   smsCostData?.smsCostdropClose && getConsumersData("SMS",smsCostData?.smsCostChartFormData?.appName, smsCostData?.smsCostStartDate, smsCostData?.smsCostEndDate)
    //eslint-disable-next-line
  },[smsCostData?.smsCostdropClose])

  useEffect(() => {
    getOktaAppID();
    getWidgetData();
    getUserData()
    //eslint-disable-next-line
  }, [])

  const itemdata = oktaAppIDArray.map(
    item => ({ label: item.label, value: item.id+'-'+item.label })
  );

  const SmsData = [ {
    "label": "CEP-ConsumerGlobal",
    "value": "0oa17wqjna55muTp40h8-CEP-ConsumerGlobal"
},{
  "label": "Bake It Better SPA",
  "value": "0oa1i7tm31s9VFYQi0h8-Bake It Better SPA"
}]
  
  

  const handleChange = (e,data) => {
    setMAUData((prev) => ({
      ...prev,
      dashboardData:[],
      userDatalabels:[]
    }))
    if(data === 'date-start'){
      setMAUData((prev) => ({
        ...prev,
        startDate:e
      }))
    }else if(data === 'date-end'){
      setMAUData((prev) => ({
        ...prev,
        endDate:e
      }))
    }
    else if(e.filter(item => item === 'All-All').length > 0){
      let allData = itemdata.map( item => item.value)
      setMAUData((prev) => ({
         ...prev,
         chartFormData: {
          ...prev.chartFormData,appName:allData
         }
      }))
    }else{
      if(data === 'appName'){ 
        setMAUData((prev) => ({
          ...prev,
          chartFormData: {
           ...prev.chartFormData,appName:e
          }
       }))
       }
    }
   }
  
   const costHandleChange = (e,data) => {
    setCostData((prev) => ({
        ...prev,
        dashboardCostData:[],
        userCostlabels:[]
    }))
    if(data === 'date-start'){
      setCostData( (prev) => ({
         ...prev,
         costStartDate:e,
      }))
    }else if(data === 'date-end'){
      setCostData( (prev) => ({
        ...prev,
        costEndDate:e,
     }))
    }
    else if(e.filter(item => item === 'All-All-All').length > 0){
      let allData = itemdata.map( item => item.value)
      setCostData((prev) => ({
        ...prev,
        costChartFormData:{
          ...prev.costChartFormData,appName:allData
        }
      }))
    }else{
      if(data === 'appName'){ 
        setCostData((prev) => ({
          ...prev,
          costChartFormData:{
            ...prev.costChartFormData,appName:e
          }
        }))
       }
    }
   }

   const smsCostHandleChange = (e,data) => {
    setSmsCostData((prev) => ({
        ...prev,
        dashboardSmsCostData:[],
        userSmsCostlabels:[]
    }))
    if(data === 'date-start'){
      setSmsCostData( (prev) => ({
         ...prev,
         smsCostStartDate:e,
      }))
    }else if(data === 'date-end'){
      setSmsCostData( (prev) => ({
        ...prev,
        smsCostEndDate:e,
     }))
    }
    else if(e.filter(item => item === 'All-All-All').length > 0){
      let allData = itemdata.map( item => item.value)
      setSmsCostData((prev) => ({
        ...prev,
        smsCostChartFormData:{
          ...prev.smsCostChartFormData,appName:allData
        }
      }))
    }else{
      if(data === 'appName'){ 
        setSmsCostData((prev) => ({
          ...prev,
          smsCostChartFormData:{
            ...prev.smsCostChartFormData,appName:e
          }
        }))
       }
    }
   }

  const[BackGroundColorShadowFrom, setBackGroundColorShadowFrom] =  useState(false) 
  const[BackGroundColorShadowTo, setBackGroundColorShadowTo] =  useState(false) 
  const[costBackGroundColorShadow, setCostBackGroundColorShadow] =  useState(false) 
  const[smsBackgroundColorShadow, setSmsBackgroundColorShadow] = useState(false)
  const hasDefaultSelectionRun = useRef(false);
  function defaultSelectedItems() {
    if (itemdata && itemdata.length > 2) {
      let allData = itemdata.filter( (item,index) =>  index <=1).map( item => item.value)
      setMAUData((prev) => ({
        ...prev,
        chartFormData: {
          ...prev.chartFormData,
          appName: allData,
        },
        dropClose: true
      }));
      setCostData( (prev) => ({
        ...prev,
        costChartFormData:{
          ...prev.costChartFormData,
          appName: allData
        },
        costdropClose: true
      }))
      return allData
    } else {
     let allData =  itemdata && itemdata.map( item => item.value)
       setMAUData((prev) => ({
        ...prev,
        chartFormData: {
          ...prev.chartFormData,
          appName: allData,
        },
        dropClose: true
      }));
      setCostData( (prev) => ({
        ...prev,
        costChartFormData:{
          ...prev.costChartFormData,
          appName: allData
        },
        costdropClose: true
      }))
    }
  }
  const hasDefaultSmsSelectionRun = useRef(false);
  function defaultSmsSelectedItems(){
    if (SmsData && SmsData.length > 2) {
      let allData = SmsData.filter( (item,index) =>  index <=1).map( item => item.value)
      setSmsCostData((prev) => ({
        ...prev,
        smsCostChartFormData: {
          ...prev.smsCostChartFormData,
          appName: allData,
        },
        smsCostdropClose: true
      }));
      return allData
    } else {
     let allData =  SmsData && SmsData.map( item => item.value)
      setSmsCostData( (prev) => ({
        ...prev,
        smsCostChartFormData:{
          ...prev.smsCostChartFormData,
          appName: allData
        },
        smsCostdropClose: true
      }))
    }
  }
  if (itemdata && itemdata.length > 0 && !hasDefaultSelectionRun.current) {
    defaultSmsSelectedItems();
    hasDefaultSmsSelectionRun.current = true;
  }

if (itemdata && itemdata.length > 0 && !hasDefaultSelectionRun.current) {
      defaultSelectedItems();
      hasDefaultSelectionRun.current = true;
    }


   async function exportMauData(){
    let From = DateFormat(mauData?.startDate)
    let To = DateFormat(mauData?.endDate)
    let appIds = mauData?.chartFormData?.appName.filter( item => item !== 'All-All').map( item => item.split('-')[0])
     await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/download-consumer-user-counts-csv`,{
        "from":From,
        "to":To,
        "appIds": appIds,
        "consumerAppIds": adminFind ? [] : ConsumerAppIds
     }, {
      headers: { Authorization: `Bearer ${AccessToken}` },
      responseType:'blob'
    }).then( res => {
      const type = res.headers['content-type'];
      const blob = new Blob([res.data], {
        type: type
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `ExcelSample-${+new Date()}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(link.href);
        link.click();
     }).catch( err => {
       console.log({err})
     })
    }

    async function exportSMSData(){
      let From = DateFormat(smsCostData?.smsCostStartDate)
      let To = DateFormat(smsCostData?.smsCostEndDate)
      let appIds = smsCostData?.smsCostChartFormData?.appName.filter( item => item !== 'All-All').map( item => item.split('-')[0])
       await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard/download-consumer-sms-counts-csv`,{
          "from":From,
          "to":To,
          "appIds": appIds,
          "consumerAppIds": adminFind ? [] : ConsumerAppIds
       }, {
        headers: { Authorization: `Bearer ${AccessToken}` },
        responseType:'blob'
      }).then( res => {
        const type = res.headers['content-type'];
        const blob = new Blob([res.data], {
          type: type
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `ExcelSample-${+new Date()}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(link.href);
          link.click();
       }).catch( err => {
         console.log({err})
       })
    }
 
    async function exportCostData(){
      let From = DateFormat(costData?.costStartDate)
      let To = DateFormat(costData?.costEndDate)
      let appIds = costData?.costChartFormData?.appName.filter( item => item !== 'All-All').map( item => item.split('-')[0])
       await axios.post(`${REACT_APP_API_ENDPOINT}admin/dashboard//download-consumer-cost-csv`,{
          "from":From,
          "to":To,
          "appIds": appIds,
          "consumerAppIds": adminFind ? [] : ConsumerAppIds
       }, {
        headers: { Authorization: `Bearer ${AccessToken}` },
        responseType:'blob'
      }).then( res => {
        const type = res.headers['content-type'];
        const blob = new Blob([res.data], {
          type: type
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `ExcelSample-${+new Date()}.xlsx`;
        link.click();
        window.URL.revokeObjectURL(link.href);
          link.click();
       }).catch( err => {
         console.log({err})
       })
    }

  return (
    <>
      <div
        className={IconFlag ? "content-inner" : "content-inner-compress-data"}
      >
        <div className="container-fluid">
          <div>
            <div className="page-name">
              <h4>DashBoard</h4>
              {
                adminFind &&
              <div className="row justify-content-end" style={{paddingRight: '10px'}}>
                <Link to='/dashboard/onboardapp'>
                <div className='addClass'>
                  <img src={AddAppIconB} alt="Add Consumer App Icon" style={{marginRight:'10px'}}></img>
                  <span>OnBoard Application</span>
                  </div>
                  </Link>
               </div>
               }
            </div>
            <div className="widget has-shadow">
              <div className="d-flex widget-body">
                <div className="col-sm-4 align-items-center mb-1 mr-1">
                  <Card   
                  onClick={() => adminFind ? history.push('/dashboard/appstatus') : ''} 
                  style={adminFind ? { cursor: 'pointer'} : {}}>
                    <CardContent>
                      <Box>
                        <Grid container direction="column">
                          <Grid item >
                            <Grid container alignItems="center">
                              <Grid item >
                                <Typography
                                  sx={{ fontSize: "3rem", fontWeight: 700 }}
                                  
                                >
                                  {adminFind
                                    ? widgetData?.noOfConsumerApp
                                      ? widgetData?.noOfConsumerApp
                                      : 0
                                    : ConsumerAppIds.length
                                    ? ConsumerAppIds.length
                                    : 0}
                                </Typography>
                                {/* //* ********  DIALOG SECTION **********   */}
                                {/* {
                                  adminFind &&
                                  <SimpleDialog
                                      open={openDialoge}
                                      onClose={handleClose12}
                                  />
                                  } */}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography gutterBottom>
                                Total Applications
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: "1rem",
                                  fontWeight: 500,
                                }}
                              >
                                {DataBaseUserData?.lastUpdatedOn &&
                                  new Date(
                                    Date.parse(DataBaseUserData?.lastUpdatedOn)
                                  ).toLocaleString("en-US")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-sm-4 align-items-center mb-1 mr-1">
                  <Card>
                    <CardContent>
                      <Box>
                        <Grid container direction="column">
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Typography
                                  sx={{ fontSize: "3rem", fontWeight: 700 }}
                                >
                                  {DataBaseUserData?.userCount
                                    ? DataBaseUserData?.userCount
                                    : 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography gutterBottom>Total Users</Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: "1rem",
                                  fontWeight: 500,
                                }}
                              >
                                {DataBaseUserData?.lastUpdatedOn &&
                                  new Date(
                                    Date.parse(DataBaseUserData?.lastUpdatedOn)
                                  ).toLocaleString("en-US")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
                <div className="col-sm-4 align-items-center mb-1 mr-1">
                  <Card>
                    <CardContent>
                      <Box>
                        <Grid container direction="column">
                          <Grid item>
                            <Grid container alignItems="center">
                              <Grid item>
                                <Typography
                                  sx={{ fontSize: "3rem", fontWeight: 700 }}
                                >
                                  {DataBaseUserData?.mfaUserCount ? DataBaseUserData?.mfaUserCount : 0}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography gutterBottom>Total MFA User Count</Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                sx={{
                                  fontSize: "1rem",
                                  fontWeight: 500,
                                }}
                              >
                                {DataBaseUserData?.lastUpdatedOn &&
                                  new Date(
                                    Date.parse(DataBaseUserData?.lastUpdatedOn)
                                  ).toLocaleString("en-US")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>

            <div className="widget has-shadow">
              <div
                className="widget-body"
                style={{ position: "relative", paddingTop: "0" }}
              >
                <span
                  className="input-group-btn"
                  style={{ width: "20px" }}
                ></span>
                <div className="charts row d-flex justify-content-start text-left" >
                  <Grid container>
                    <Grid item xs={6}>
                      <Card >
                        <CardContent sx={{ margin: "1%" }} >
                          <div style={{display:'flex'}}>
                          <Grid
                            item
                            xs={8}
                            style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%', width:'100%'}}
                          >
                            {
                              !mauData.checked &&
                            <>
                            <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                            <div className="label">From </div>
                            <DatePicker
                                placeholderText='From'
                                className={ BackGroundColorShadowFrom ?  "custom-datepicker enableblurback" : "custom-datepicker" }
                                selected={mauData.startDate}
                                onChange={(e) => {
                                  handleChange(e, 'date-start')
                                  setMAUData((prev) => ({
                                    ...prev,
                                    userDatalabels:[]
                                  }))
                                }}
                                startDate={mauData?.startDate}
                                dateFormat="MMMM yy"
                                showMonthYearPicker
                                onCalendarOpen={() =>{
                                  setMAUData((prev) => ({
                                    ...prev,
                                    dropClose:false
                                  }))
                                }}
                                onCalendarClose={() => {
                                  setMAUData((prev) => ({
                                    ...prev,
                                    dropClose:true
                                  }))
                                  setBackGroundColorShadowFrom(false)
                                }}
                                onClickOutside={(e) =>{
                                  setMAUData( (prev) => ({
                                     ...prev,
                                  }))
                                }}
                                onInputClick={ () =>  setBackGroundColorShadowFrom(true)}
                                minDate={new Date('2022-11')}
                            />
                            </div>
                            <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                            <div className="label">To </div>
                            <DatePicker
                                placeholderText='To'
                                className={ BackGroundColorShadowTo ?  "custom-datepicker enableblurback" : "custom-datepicker" }
                                selected={mauData.endDate}
                                onChange={(e) => {
                                  handleChange(e, 'date-end')
                                  setMAUData((prev) => ({
                                    ...prev,
                                    userDatalabels:[]
                                  }))
                                }}
                                startDate={mauData?.endDate}
                                dateFormat="MMMM yy"
                                showMonthYearPicker
                                onCalendarOpen={() =>{
                                  setMAUData((prev) => ({
                                    ...prev,
                                    dropClose:false
                                  }))
                                  
                                }}
                                onCalendarClose={() => {
                                  setMAUData((prev) => ({
                                    ...prev,
                                    dropClose:true
                                  }))
                                  setBackGroundColorShadowTo(false)
                                }}
                                onClickOutside={(e) =>{
                                  setMAUData( (prev) => ({
                                     ...prev,
                                     chartFormData:{
                                      ...prev.chartFormData
                                     }
                                  }))
                                }}
                                onInputClick={ () =>  setBackGroundColorShadowTo(true)}
                                minDate={new Date(mauData.startDate)}
                                maxDate={new Date()}
                            />
                            </div>
                            </>
                            }
                          </Grid>
                          <Grid   
                            item                       
                            xs={8}
                            style={{display:'flex',alignItems:'center', justifyContent:'end'}}>
                          <CheckPicker
                              menuStyle={{ width: "10%" }}
                              placeholder="Select Application"
                              data={itemdata}
                              style={{
                                width: "50%",
                                display: "block",
                                marginBottom: 10,
                              }}
                              value={mauData?.chartFormData?.appName}
                              onChange={(e) => {
                                handleChange(e, "appName");
                                setMAUData((prev) => ({
                                   ...prev,
                                   userDatalabels:[]
                                }))
                              }}
                              onOpen={() =>{
                                setMAUData((prev) => ({
                                  ...prev,
                                  dropClose:false
                               }))
                              }}
                              onClose={() => { 
                                setMAUData((prev) => ({
                                  ...prev,
                                  dropClose:true
                               }))
                              }}
                              onClean={() => {
                                setMAUData((prev) => ({
                                  ...prev,
                                  checkpickerFlag:false,
                                   userDatalabels:[],
                                   dashboardData:[]
                               }))
                              }}
                              
                              onSelect={(e) => {
                                console.log({e})
                                let All = e.find(
                                  (item) => item === "All-All"
                                );
                                if (All) {
                                  setMAUData((prev) => ({
                                    ...prev,
                                    checkpickerFlag:true
                                 }))
                                } else if(e.length >= 4) {
                                 let unselectedArray = itemdata.filter( item => {
                                  if(!e.includes(item.value)){
                                    return item.value
                                  }
                                  return null
                                 }).map(item => item.value)
                                  setDisableItemValues(unselectedArray)
                                }else {
                                  setDisableItemValues([])
                                  setMAUData((prev) => ({
                                    ...prev,
                                    checkpickerFlag:false
                                 }))

                                }
                              }}
                              disabledItemValues={disableItemValues}
                            />
                          </Grid>
                          </div>
                          <Grid item xs={12}>
                            {
                              mauData?.checked ? 
                              <div className=" chart form-group col-md-12 flex-column">
                              <Bar options={options} data={data} />
                            </div>
                            : 
                            <div className=" chart form-group col-md-12 flex-column">
                            <Line options={options} data={data} />
                          </div>
                            }
                          </Grid>
                          <Grid item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center',marginTop:'10px'}}>
                          <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'start', marginLeft:'20px'}}>
                            <Button variant="contained" 
                            color="primary"
                            startIcon={<CloudDownloadIcon sx={{ fontSize: 20 }} />}
                            sx={{ padding: '5px 10px' }} // Adjust the padding her
                            onClick={exportMauData}
                           >Export
                         
                           </Button>
                          </Grid>
                          <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'end'}} >
                            <FormControlLabel 
                            control={
                              <Switch 
                            checked={mauData.checked}
                            onChange={(e) =>{
                              console.log({e})
                              setMAUData( (prev) => ({
                                ...prev,
                                checked: !mauData.checked,
                                dropClose: !e.target.checked && true
                              }))
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                            }
                            label={<Typography style={{color: '#000000', fontSize:'1rem', fontWeight:'bold'}}>yearly Data</Typography>}
                            labelPlacement='start'
                            />
                          </Grid>
                          </Grid>
                          
                        </CardContent>
                        
                      </Card>
                      
                    </Grid>
                    <Grid item xs={6}>
                      <Card>
                        <CardContent sx={{ margin: "1%" }}>
                        <div style={{display:'flex'}}> 
                          <Grid
                            item
                            xs={6}
                            style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%', width:'100%'}}
                          >
                          <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                          <div className="label">From </div>
                            <DatePicker
                              placeholderText='From'
                              className={costBackGroundColorShadow ? "custom-datepicker enableblurback" : "custom-datepicker"}
                              selected={costData?.costStartDate}
                              onChange={(e) => {
                                costHandleChange(e, 'date-start');
                                setCostData((prevData) => ({
                                  ...prevData,
                                  userCostlabels: [],
                                  costChartFormData: {
                                    ...prevData.costChartFormData
                                  },
                                }));
                              }}
                              startDate={costData?.costStartDate}
                              dateFormat="MMMM yy"
                              showMonthYearPicker
                              onCalendarOpen={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costdropClose: false
                                }));
                              }}
                              onCalendarClose={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costdropClose: true
                                }));
                                setCostBackGroundColorShadow(false);
                              }}
                              onClickOutside={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costChartFormData: {
                                    ...prevData.costChartFormData
                                  }
                                }));
                              }}
                              onInputClick={() => setCostBackGroundColorShadow(true)}
                              minDate={new Date('2022-11')}
                            />
                          </div>
                          <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                          <div className="label">To </div>
                             <DatePicker
                              placeholderText='To'
                              className={costBackGroundColorShadow ? "custom-datepicker enableblurback" : "custom-datepicker"}
                              selected={costData?.costEndDate}
                              onChange={(e) => {
                                costHandleChange(e, 'date-end');
                                setCostData((prevData) => ({
                                  ...prevData,
                                  userCostlabels: [],
                                  costChartFormData: {
                                    ...prevData.costChartFormData
                                  },
                                }));
                              }}
                              startDate={costData?.costEndDate}
                              dateFormat="MMMM yy"
                              showMonthYearPicker
                              onCalendarOpen={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costdropClose: false
                                }));
                              }}
                              onCalendarClose={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costdropClose: true
                                }));
                                setCostBackGroundColorShadow(false);
                              }}
                              onClickOutside={() => {
                                setCostData((prevData) => ({
                                  ...prevData,
                                  costChartFormData: {
                                    ...prevData.costChartFormData
                                  }
                                }));
                              }}
                              onInputClick={() => setCostBackGroundColorShadow(true)}
                              minDate={new Date(costData?.costStartDate)}
                              maxDate={new Date()}
                            />
                            </div>
                          </Grid>
                          <Grid   
                            item                       
                            xs={6}
                            style={{display:'flex',alignItems:'center', justifyContent:'end'}}>

                            <CheckPicker
                              menuStyle={{ width: "10%" }}
                              placeholder="Select Application"
                              data={itemdata}
                              style={{
                                width: "50%",
                                display: "block",
                                marginBottom: 10,
                              }}
                              value={costData.costChartFormData.appName}
                              onChange={(e) => {
                                costHandleChange(e, "appName");
                                // setUserCostLabels([]);
                               
                                setCostData( (prev) => ({
                                  ...prev,
                                  userCostlabels:[],
                                }))
                              }}
                              onOpen={() => {
                                // setCostDropClose(false)
                                setCostData( (prev) => ({
                                  ...prev,
                                  costdropClose:false
                                }))
                              }}
                              onClose={() => {
                                // setCostDropClose(true)
                                setCostData( (prev) => ({
                                  ...prev,
                                  costdropClose:true 
                                }))
                              }}
                              onClean={() => {
                                setCostData( (prev) => ({
                                  ...prev,
                                  userCostlabels:[],
                                  dashboardCostData:[],
                                  costCheckpickerFlag:false
                                }))
                              }}
                              onSelect={(e) => {
                                let All = e.find(
                                  (item) => item === "All-All-All"
                                );
                                if (All) {
                                  // setcostCheckpickerFlag(true);
                                  setCostData((prev) => ({...prev, costCheckpickerFlag:true}))
                                }else if(e.length >= 4) {
                                  let unselectedArray = itemdata.filter( item => {
                                   if(!e.includes(item.value)){
                                     return item.value
                                   }
                                   return null
                                  }).map(item => item.value)
                                   setDisableCostItemValues(unselectedArray)
                                 } else {
                                  // setcostCheckpickerFlag(false);
                                  setDisableCostItemValues([])
                                  setCostData((prev) => ({...prev, costCheckpickerFlag:false}))
                                }
                              }}
                              disabledItemValues={disableCostItemValues}
                            />
                          </Grid>
                         </div>
                          <Grid item xs={12}>
                            <div className=" chart form-group col-md-12 flex-column">
                              <Bar options={cost_option} data={cost_data} />
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center',marginTop:'10px'}}>
                          <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'start', marginLeft:'20px'}}>
                            <Button variant="contained" 
                            color="primary"
                            startIcon={<CloudDownloadIcon sx={{ fontSize: 20 }} />}
                            sx={{ padding: '5px 10px' }} 
                            onClick={exportCostData}
                           >Export
                         
                           </Button>
                          </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
                <span
                  className="input-group-btn"
                  style={{ width: "20px" }}
                ></span>
                <div className="charts row justify-content-between text-left d-flex">
                  <Grid container>
                  <Grid item xs={6}>
                      <Card>
                        <CardContent sx={{ margin: "1%" }}>
                        <div style={{display:'flex'}}> 
                          <Grid
                            item
                            xs={6}
                            style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%', width:'100%'}}
                          >
                          <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                          <div className="label">From </div>
                            <DatePicker
                              placeholderText='From'
                              className={smsBackgroundColorShadow ? "custom-datepicker enableblurback" : "custom-datepicker"}
                              selected={smsCostData.smsCostStartDate}
                              onChange={(e) => {
                                smsCostHandleChange(e, 'date-start');
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  userSmsCostlabels: [],
                                  smsCostChartFormData: {
                                    ...prevData.smsCostChartFormData
                                  },
                                }));
                              }}
                              startDate={smsCostData?.smsCostStartDate}
                              dateFormat="MMMM yy"
                              showMonthYearPicker
                              onCalendarOpen={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostdropClose: false
                                }));
                              }}
                              onCalendarClose={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostdropClose: true
                                }));
                                setSmsBackgroundColorShadow(false);
                              }}
                              onClickOutside={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostChartFormData: {
                                    ...prevData.smsCostChartFormData
                                  }
                                }));
                              }}
                              onInputClick={() => setSmsBackgroundColorShadow(true)}
                              minDate={new Date('2022-11')}
                            />
                          </div>
                          <div  style={{display:'flex',alignItems:'center', justifyContent:'center', gap:'5%'}}>
                          <div className="label">To </div>
                             <DatePicker
                              placeholderText='To'
                              className={smsBackgroundColorShadow ? "custom-datepicker enableblurback" : "custom-datepicker"}
                              selected={smsCostData?.smsCostEndDate}
                              onChange={(e) => {
                                smsCostHandleChange(e, 'date-end');
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  userSmsCostlabels: [],
                                  smsCostChartFormData: {
                                    ...prevData.smsCostChartFormData
                                  },
                                }));
                              }}
                              startDate={smsCostData?.smsCostEndDate}
                              dateFormat="MMMM yy"
                              showMonthYearPicker
                              onCalendarOpen={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostdropClose: false
                                }));
                              }}
                              onCalendarClose={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostdropClose: true
                                }));
                                setSmsBackgroundColorShadow(false);
                              }}
                              onClickOutside={() => {
                                setSmsCostData((prevData) => ({
                                  ...prevData,
                                  smsCostChartFormData: {
                                    ...prevData.smsCostChartFormData
                                  }
                                }));
                              }}
                              onInputClick={() => setCostBackGroundColorShadow(true)}
                              minDate={new Date(smsCostData?.smsCostStartDate)}
                              maxDate={new Date()}
                            />
                            </div>
                          </Grid>
                          <Grid   
                            item                       
                            xs={6}
                            style={{display:'flex',alignItems:'center', justifyContent:'end'}}>

                            <CheckPicker
                              menuStyle={{ width: "10%" }}
                              placeholder="Select Application"
                              data={SmsData}
                              style={{
                                width: "50%",
                                display: "block",
                                marginBottom: 10,
                              }}
                              value={smsCostData.smsCostChartFormData.appName}
                              onChange={(e) => {
                                smsCostHandleChange(e, "appName");
                                // setUserCostLabels([]);
                               
                                setSmsCostData( (prev) => ({
                                  ...prev,
                                  userSmsCostlabels:[],
                                }))
                              }}
                              onOpen={() => {
                                // setCostDropClose(false)
                                setSmsCostData( (prev) => ({
                                  ...prev,
                                  smsCostdropClose:false
                                }))
                              }}
                              onClose={() => {
                                // setCostDropClose(true)
                                setSmsCostData( (prev) => ({
                                  ...prev,
                                  smsCostdropClose:true 
                                }))
                              }}
                              onClean={() => {
                                // setdashboardCostData([]);
                                // setUserCostLabels([]);
                                // setcostCheckpickerFlag(false);
                                setSmsCostData( (prev) => ({
                                  ...prev,
                                  userSmsCostlabels:[],
                                  dashboardSmsCostData:[],
                                  smsCostCheckpickerFlag:false
                                }))
                              }}
                              onSelect={(e) => {
                                let All = e.find(
                                  (item) => item === "All-All-All"
                                );
                                if (All) {
                                  // setcostCheckpickerFlag(true);
                                  setSmsCostData((prev) => ({...prev, smsCostCheckpickerFlag:true}))
                                }else if(e.length >= 4) {
                                  let unselectedArray = itemdata.filter( item => {
                                   if(!e.includes(item.value)){
                                     return item.value
                                   }
                                   return null
                                  }).map(item => item.value)
                                   setDisableCostItemValues(unselectedArray)
                                 } else {
                                  // setcostCheckpickerFlag(false);
                                  setDisableCostItemValues([])
                                  setSmsCostData((prev) => ({...prev, smsCostCheckpickerFlag:false}))
                                }
                              }}
                              disabledItemValues={disableCostItemValues}
                            />
                          </Grid>
                         </div>
                          <Grid item xs={12}>
                            <div className=" chart form-group col-md-12 flex-column">
                              <Bar options={smsCost_option} data={smsCost_data} />
                            </div>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex', justifyContent:'space-between', alignItems:'center',marginTop:'10px'}}>
                          <Grid item xs={6} style={{display:'flex', alignItems:'center', justifyContent:'start', marginLeft:'20px'}}>
                            <Button variant="contained" 
                            color="primary"
                            startIcon={<CloudDownloadIcon sx={{ fontSize: 20 }} />}
                            sx={{ padding: '5px 10px' }} // Adjust the padding her
                            onClick={exportSMSData}
                           >Export
                           </Button>
                          </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;