
import * as AuthActions from '../Actions/AuthActions'

function getStorageData(){
    if(localStorage.getItem('okta-token-storage')){        
        const data = JSON.parse(localStorage.getItem('okta-token-storage'));
        // const mail = data.accessToken?.claims?.email
        let roleBase = data?.accessToken?.claims?.pepappconsumerroles?.map( item => item)
        // if(mail?.toUpperCase() === "thulasiraman.krishnamoorthy.contractor@pepsico.com".toUpperCase()){
        //     roleBase= ['partneriam-0oa1a50xcrari6TVX0h8']
        // }else if( mail?.toUpperCase() === "sudhir.patil1.contractor@pepsico.com".toUpperCase()){
        //     roleBase = ['partneriam-0oa4nvbcu9M8rMxHc5d7']
        // }else if( mail?.toUpperCase() === "Rohit.kurakula.contractor@pepsico.com".toUpperCase()){
        //     roleBase= ['partneriam-admin','partneriam-0oa1a50xcrari6TVX0h8']
        // }else if( mail?.toUpperCase() === "Tony.Kennedy@pepsico.com".toUpperCase()){
        //     roleBase= ['partneriam-0oa1a50xcrari6TVX0h8']
        // }else{
        //     roleBase= ['partneriam-0oa1a50xcrari6TVX0h8']
        // }

        // const auth = {
        //     accessToken:data?.accessToken,
        //     idToken:data?.idToken
        // }
        const authInitState = {
            // authState : auth,
            isAuthenticated : data?.accessToken ? true : false,
           // roles : ['admin']
            //  roles : ['partneriam-0oa4lotzuw5ZCrL0e5d7','partneriam-0oa2x0pragJoZ5WQb5d7','partneriam-admin']
            roles : roleBase
        }
        // console.log({authInitState})
        return authInitState   
    }else{
        const loginInitialState = {
            authState:null,
            isAuthenticated:false,
            roles: ['']
        }
        return loginInitialState;
    }
    
}

export const loginInitialState = getStorageData();

const authUserReducer  = (state=loginInitialState,action)=>{
    switch(action.type){
        case  AuthActions.USER_LOGIN:
            return {
                ...state,
                // authState:action.payload.authState,
                isAuthenticated:action.payload.isAuthenticated,
                roles:action.payload.roles
            }
        case AuthActions.USER_LOGOUT:
            return{
                ...state,
                // authState: {},
                isAuthenticated: false,
                roles:[]
            }
        default:
            return state
    }
}

export default authUserReducer;