import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import "./global1.css";
import axios from 'axios'
import "./global.css";

import DefaultLogo from '../../../assets/img/LogoBg/DefaultLogo.png'
import DefaultBack from '../../../assets/img/LogoBg/background-img.png'
import { useSelector } from 'react-redux'

function ChangepasswordU() {

    const { REACT_APP_API_ENDPOINT } = process.env
    const appID_redux_updated = useSelector((state) => state)
    const [passwordComplexity, setPaswordComplexity] = useState({});

    const [data4, setData4] = useState({})

    async function data2() {
        await axios.get(`${REACT_APP_API_ENDPOINT}admin/rendering-service/${appID_redux_updated.allFiles.APPID}`
        ).then(res => {
            setData4(res.data)
            passwordPolicies(res.data.appBasicDetails.oktaGroupID)
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        data2();
        //eslint-disable-next-line
    }, []);

    const Header_Color = data4.custom_properties?.headerColor 
   const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const SignIn_button_backgroundColor =data4.custom_properties?.signInButtonBackgroundColor;
  const Input_color = data4.custom_properties?.signInButtonColor;

    const fontFamily = data4.custom_properties?.fontFamily;
    const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
    const BackgroundImage = data4.backgroundImg
        ? data4.backgroundImg[0]
        : DefaultBack;
    const logo = data4.logo ? data4.logo[0] : DefaultLogo;


    const passwordPolicies = async (GroupId) => {
        await axios
          .get(`${REACT_APP_API_ENDPOINT}admin/policies/get-password-policies`)
          .then((res) => {
            const policyObject = res.data.filter((item) => {
              const groups = item.conditions.people.groups.include;
              if (
                groups.filter((groupId) => groupId === GroupId).length > 0
              ) {
                return true;
              }
              return false;
            });
            if (policyObject.length > 0) {
              setPaswordComplexity(policyObject[0].settings.password.complexity);
            }else{
              setPaswordComplexity(res.data[res.data.length-1].settings.password.complexity);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
    return (
        <div>
            <>
                <Helmet>
                    <link
                        type="text/css"
                        rel="stylesheet"
                        href={cssFileLink}
                    />
                </Helmet>
                <div className='body' style={{ fontFamily: fontFamily }}>
                    <div className="main-bg change-password-sec" style={{ backgroundImage: `url(${BackgroundImage})` }}>

                        <div className="white-wrapper">
                            <div className="text-center">
                                <div className="snacks-logo">
                                    <img src={logo} alt="Logo" />
                                </div>
                                <h2 className="snacks-title" style={{ fontFamily: fontFamily, color: Header_Color }}>Change Password</h2>
                            </div>
                            <div className="wrapper-inner">

                                <div className='form-group'>
                                    <input
                                        className="custom-input"
                                        type="password"
                                        name='currentPassword'
                                        required
                                    />
                                    <label className="custom-label">Current Password*</label>
                                </div>

                                <div className="form-group line-height-0 font-size-0">
                                    <span className="brd-btm"></span>
                                </div>
                                <div className='form-group'>
                                    <input
                                        className="custom-input"
                                        type="password"
                                        name='newPassword'
                                        required
                                    />
                                    <label className="custom-label">New Password*</label>

                                </div>
                                <div className='form-group'>
                                    <input
                                        className="custom-input"
                                        type="password"
                                        name='confirmPassword'
                                        required
                                    />
                                    <label className="custom-label">Confirm Password*</label>

                                </div>
                                <div className="pwd-requirement" style={{ textAlign: 'initial' }}>
                            <p className="font-weight-bold">
                            Password Requirement:
                            </p>
                            <ul className="pl-4 mb-0" style={{ listStyleType: 'disc' }}>
                              {passwordComplexity.minLength > 0 ? (
                                <li>{ `Atleast ${0} characters are required`.replace(0, passwordComplexity.minLength) }</li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minLowerCase > 0 ? (
                                <li>
                                  A lower case letter
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minUpperCase > 0 ? (
                                <li>
                                  An upper case letter
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minNumber > 0 ? (
                                <li>A Number </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.minSymbol > 0 ? (
                                <li>A Symbol</li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeUsername ? (
                                <li>
                                  No part of UserName
                                </li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeAttributes &&
                              passwordComplexity.excludeAttributes.filter(
                                (item) => item === "firstName"
                              ).length > 0 ? (
                                <li>No part of firstName</li>
                              ) : (
                                <></>
                              )}
                              {passwordComplexity.excludeAttributes &&
                              passwordComplexity.excludeAttributes.filter(
                                (item) => item === "lastName"
                              ).length > 0 ? (
                                <li>No Part of LastName</li>
                              ) : (
                                <></>
                              )}
                            </ul>
                            </div> 
                                <p
                                    className="privacy-terms">By creating an account, you agree to our
                                    <Link to="/#">Privacy Policy </Link>
                                    and <Link to="/#">Terms & conditions</Link></p>

                                <div className="form-group">
                                    <button className="btn btn-yellow btn-block"
                                    style={{
                                        backgroundColor: SignIn_button_backgroundColor,
                                        color: Input_color,
                                      }}
                                    >Change Password</button>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-yellow-brd btn-block"
                                    style={{ color: SignIn_button_color, background: SecondaryButtonColor}}
                                    >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

export default ChangepasswordU;
