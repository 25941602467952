import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import * as yup from "yup";
import "../styles.css";
import { Redirect, useHistory } from "react-router";
import TextField from "@mui/material/TextField";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import {Controller, useForm} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Chip, InputLabel, MenuItem, Typography, Select, OutlinedInput, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BasicAppConfigDetails() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  
  const IconFlag = useSelector(state => state?.allFiles?.HAMICON)

  const roles = ["uh-admin"];
  let roleNames = roles.map(role => role.split('uh-')[1])
  let adminFind = roleNames.includes('admin')

  const history = useHistory();
  const [appId, setAppId] = useState();
  const [response, setResponse] = useState();
  const [lang, setLang] = useState()
  const [oktaAppIDArray, setOktaAppIDArray] = useState([])
  const [groupIDArray, setGroupIDArray] = useState([])
  // const [selectLang, setSelectLang] = useState([])
  // const [selectedOptionAppID, setSelectedOptionAppID] = useState(null);
  // const [selectedOptionGroupID, setSelectedOptionGroupID] = useState(null);
  // let oktaID, groupID;
  // const [oktaAppIDError, setOktaAppIDError] = useState(null)
  // const [oktaGroupIDError, setOktaGroupIDError] = useState(null)

  // const selectedValues = [{ langCode: "en", langName: "English" }]

  // const [groupIDArrayIDNew, setGroupIDArrayIDNew] = useState(null);

  // const [SelectAlertAppID, setSelectAlertAppID] = useState(false);
  // const [SelectAlertGroupID, setSelectAlertGroupID] = useState(false);

  const[AppNameExists, setAppNameExists] = useState(false)

  // let langArray = []

  // if (selectLang.length === 0) {
  //   langArray = selectedValues
  // } else {
  //   langArray = selectLang
  // }

  const getAllLanguage = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/getAllLanguage`)
      .then((res) => {
        setLang(res.data.lang);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllLanguage();
    //eslint-disable-next-line
  }, []);


  const groupArr = useMemo(() => [], [])

  const getAllGroups = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/groups/get-groups`)
      .then((res) => {
        res.data.map((i) => {
          groupArr.push({
            'id': i.id,
            'label': i.profile.name
          })
          return true
        })
      })
  }

  useEffect(() => {
    getAllGroups()
    //eslint-disable-next-line
  }, [])


  const handleReset = () => {
    reset({
      appName: "",
      contactEmails: [],
      appRedirectionURL: "",
      consumerName: "",
      appDescription: "",
      languages: [],
      Multiselect: "",
      oktaAppID: "",
      oktaGroupID: "",
      privacyPolicy: "",
      termsAndConditions: "",
      otherIssueURL:"",
      createAccountInfo:"",
      policyMessage:"",
      createRedirectURL:"",
      resetRedirectURL:"",
      activatedRedirectURL:"",
      signInRedirectURL:"",
      cancelRedirectURL:"",
      enableLoginDropdown:false,
      enableGoogleCaptcha:false,

    })
  }
   //eslint-disable-next-line
  const URLvalid = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  
  const validationSchema = yup.object().shape({
    appName: yup.string().required("App Name is required"),
    appDescription: yup.string().required("Description is required"),
    appRedirectionURL: yup.string().required("App URL is required").matches(URLvalid, "Enter valid URL"),
    consumerName: yup.string().required("App Owner is required"),
    oktaAppID: yup.string().required("OKTA Application is required"),
    languages: yup.array()
    .min(1, "Select Atleast One language")
    .required("Language Field is Required")
    .nullable(), 
    oktaGroupID: yup.string().required("OKTA Group ID is required"),
    privacyPolicy: yup.string().matches(URLvalid, "Enter valid URL"),
    termsAndConditions: yup.string().matches(URLvalid, "Enter valid URL"),
    otherIssueURL: yup.string().required("Other Help Link is Required").matches(URLvalid, "Enter Valid URL"),
    contactEmails: yup.string()
    .required('Contact emails is required')
    .transform((value) => {
      if(typeof(value) === 'string'){
            
      }else{
        value = value.toString()
      }
      return Array.from(new Set( value.length > 0 ? value?.split(',') : '')).join(',')
    })
    .test(
      'emails',
      'Invalid email address',
      (value) => value && value.split(',').every(isEmail)
    ),
    createAccountInfo: yup.string(),
    policyMessage:yup.string(),
    createRedirectURL:yup.string(),
    resetRedirectURL:yup.string(),
    activatedRedirectURL:yup.string(),
    signInRedirectURL:yup.string().required("signInRedirectURL is Required"),
    cancelRedirectURL:yup.string(),
    enableLoginDropdown: yup.bool(),
    enableGoogleCaptcha: yup.bool()
  });

  const {control, reset, formState:{errors}, handleSubmit, getValues} = useForm({
    mode: 'onChange',
    defaultValues:{
      appName: "",
      contactEmails: [],
      appRedirectionURL: "",
      consumerName: "",
      appDescription: "",
      languages: ["English"],
      oktaAppID: "",
      oktaGroupID: "",
      privacyPolicy: "",
      termsAndConditions: "",
      otherIssueURL:"",
      createAccountInfo:"",
      policyMessage:"",
      createRedirectURL:"",
      resetRedirectURL:"",
      activatedRedirectURL:"",
      signInRedirectURL:"",
      cancelRedirectURL:"",
      enableLoginDropdown:false,
      enableGoogleCaptcha:false,
    },
    resolver: yupResolver(validationSchema)
  })

  const onsubmit = (data, props) => {
    //eslint-disable-next-line
    const OktaApplication = oktaAppIDArray.filter( item => {
      if(item.id === getValues("oktaAppID")){
       return item
      }  
    })

    const OktaApplicationName =  OktaApplication[0].label
    let OktaGroupObject = ''
      if(getValues("oktaGroupID")){
        groupArr.map( item => {
            if( item.id === getValues("oktaGroupID")){
              OktaGroupObject = item.label
            }
            return null
          })  
    }
    let languagesObject = []
    if(data.languages){ 
      lang.map( item => {
        data.languages.map( langauge => {
           if(item.langName === langauge){
              languagesObject.push({
                langName: item.langName,
                langCode: item.langCode
              })
           }
           return null
        })
        return null
      })
    }
    data.languages = languagesObject
    validateAsync(data, OktaApplicationName, OktaGroupObject)
  };

  // const AlllanguagesString = lang && lang.length > 0 && lang.map( language => language.langName)

  

  const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  
  

  const validateAsync = async (data, OktaApplicationName, oktaGroupName) => {
    return await axios.get(`${REACT_APP_API_ENDPOINT}admin/check-exist-app-name?appName=${data.appName}`).then(res => {
        if(res.data.isExist){
            setAppNameExists(true)
        }else{
          setAppNameExists(false)
          appconfigSubmit(
          data.appName, 
          data.contactEmails.split(","),
          data.appRedirectionURL, 
          data.consumerName, 
          data.appDescription, 
          data.oktaAppID, 
          data.oktaAppID, 
          data.languages, 
          data.privacyPolicy,
          data.termsAndConditions,
          data.otherIssueURL,
          data.createAccountInfo,
          data.policyMessage,
          data.createRedirectURL,
          data.resetRedirectURL,
          data.activatedRedirectURL,
          data.signInRedirectURL,
          data.cancelRedirectURL,
          data.enableLoginDropdown,
          data.enableGoogleCaptcha,
          OktaApplicationName,
          oktaGroupName
          )
        }
      }).catch(() => {

      })
  };

  async function appconfigSubmit(
    appName,
    emails,
    appRedirectionURL,
    appOwner,
    appDescription,
    groupID,
    oktaID,
    langArray,
    privacyPolicy,
    termsAndConditions,
    otherIssueURL,
    createAccountInfo,
    policyMessage,
    createRedirectURL,
    resetRedirectURL,
    activatedRedirectURL,
    signInRedirectURL,
    cancelRedirectURL,
    enableLoginDropdown,
    enableGoogleCaptcha,
    oktaApplicationName,
    oktaGroupName
  ) {
    await axios
      .post(
        `${REACT_APP_API_ENDPOINT}admin/save-consumner-app-basic-detail`,
        {
          appName: appName,
          contactEmails: emails,
          appRedirectionURL: appRedirectionURL,
          consumerName: appOwner,
          appDescription: appDescription,
          oktaGroupID: groupID,
          oktaApplicationID: oktaID,
          languages: langArray,
          privacyPolicy: privacyPolicy,
          termsAndConditions: termsAndConditions,
          otherIssueURL:otherIssueURL,
          createAccountInfo: createAccountInfo,
          policyMessage:policyMessage,
          createRedirectURL:createRedirectURL,
          resetRedirectURL:resetRedirectURL,
          activatedRedirectURL:activatedRedirectURL,
          signInRedirectURL:signInRedirectURL,
          cancelRedirectURL:cancelRedirectURL,
          otherIssue: enableLoginDropdown,
          enableGoogleCaptcha:enableGoogleCaptcha,
          oktaApplication: oktaApplicationName,
          oktaGroup: oktaGroupName
        })
      .then((res) => {
        setResponse(res.data.responseStatus);
        setAppId(res.data.appId);
        <Redirect to='/appconfig' />
      })
      .catch(function (error) {
        console.log(error)
      });
  }

 
  useEffect(() => {
    if (response === "SUCCESS") {
      const path = `/appconfig`;
      history.push(path);
      alert("Added consumer app with ID " + appId);
      // notifyAppAdd();
    }
    //eslint-disable-next-line
  }, [appId]);
  ;


  

  const getOktaAppID = async () => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-all-apps?limit=200`)
      .then((res) => {
        let optionsOktaAppArr = []
        res.data.map((item) => {
          optionsOktaAppArr.push({
            'label': item.label,
            'id': item.id
          })
          return item
        })
        setOktaAppIDArray(optionsOktaAppArr)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getOktaAppID();
    //eslint-disable-next-line
  }, [])


  const getGroupsByApp = async (Value) => {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/apps/get-groups-by-app/${Value}?limit=200`)
      .then((res) => {
        let GroupArrayFilter =[]
         res.data.map( item => GroupArrayFilter.push({
          'id': item._embedded.group.id,
          'label': item._embedded.group.profile.name
         })) 
        setGroupIDArray(GroupArrayFilter)
      })
      .catch(err => console.log(err))
  }

 

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      <div className={IconFlag ? 'content-inner' : "content-inner-compress-data"}>
        <div className="container-fluid">
          <div className="container-fluid">
            {
              adminFind ? <><Link to='/appconfig' style={{ color: '#0047BA' }} className='addLink'>
                <span style={{ color: '#0047BA' }}>
                  <ArrowLeftIcon className='MuiSvgIcon-fontSizeLarge' />
                </span>
                <span>
                  <p className="name" style={{ marginBottom: '0', marginLeft: '5px' }}>Back to consumer app</p>
                </span>
              </Link></> : <></>
            }
            <div className='page-name'>
              <h4>
                Add New App
              </h4>
            </div>
            <div className="widget has-shadow">
              <div className="widget-body">
                      <div className="row justify-content-between text-left">
                        <div className="consumerName form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="consumerName"
                            render={ ({field}) => (
                              <TextField {...field} label="Application Owner*" error={errors.consumerName ? true : false}/>
                            )}
                          />
                          <Typography variant="inherit" color="error" fontSize="0.75rem" >{errors?.consumerName?.message}</Typography>
                        </div>

                        <div className="appDescription form-group col-sm-4 flex-column d-flex">
                          {/* <Field
                            as={TextField}
                            label="Description"
                            variant="outlined"
                            name="appDescription"
                            multiline
                            error={Boolean(props.errors.appDescription && props.touched.appDescription)}
                            required
                            helperText={props.errors.appDescription && props.touched.appDescription && String(props.errors.appDescription)}
                            margin="dense"
                            fullWidth
                          /> */}
                          <Controller 
                              control={control}
                              name="appDescription"
                              render={( { field}) => (
                                <TextField {...field} label="Application Description" error={errors.appDescription ? true : false}/>
                              )}
                          />
                          <Typography variant="inherit" color="error" fontSize="0.75rem">{errors?.appDescription?.message}</Typography>
                        </div>
                        
                        <div className="languages form-group col-sm-4 flex-column d-flex">
                          <Controller
                           control={control}
                           name="languages"
                           render = { (props) => {
                            return(
                               <FormControl >
                                 <InputLabel id="demo-multiple-chip-label">Language</InputLabel>
                                 <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={props.field.value}
                                    onChange={(data) => props.field.onChange(data)} 
                                    input={<OutlinedInput id="select-multiple-chip" label="Language" />}
                                    renderValue={(selected) => (
                                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                          <Chip key={value} label={value}  />
                                        ))}
                                      </Box>
                                    )}
                                    error={errors.languages? true : false}
                                    MenuProps={MenuProps}
                                 >
                                   <MenuItem
                                        key='English'
                                        value='English'
                                      >
                                        English
                                      </MenuItem>
                                 </Select>
                               </FormControl>
                            )
                           }}

                          />
                          <Typography variant="inherit" color="error" fontSize="0.75rem">{errors?.languages?.message}</Typography>
                        </div>
                      </div>
                      <span
                        className="input-group-btn"
                        style={{ width: "20px" }}
                      ></span>
                      <div className="row text-left">
                        <div className="appName form-group col-sm-4 flex-column d-flex ">
                          <Controller
                            control={control}
                            name="appName"
                            render={ ( { field }) => (
                              <TextField {...field} label="Application Name*" error={errors.appName ? true : false || AppNameExists} 
                              />
                            )}
                            
                          />
                          {
                            AppNameExists ? 
                            <Typography variant='inherit' color="error" fontSize="0.75rem">App Name Already Exists</Typography>
                            : 
                            <Typography variant='inherit' color="error" fontSize="0.75rem"> {errors.appName?.message} </Typography>
                          }

                        </div>
                        <div className="contactEmails form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="contactEmails"
                            render={ ( { field}) => (
                              <TextField 
                                {...field}
                                label="Contact Email*"
                                error={errors.contactEmails ? true : false}
                              />
                            )}
                          />
                        <Typography variant='inherit' color="error" fontSize="0.75rem"> {errors.contactEmails?.message} </Typography>
                        </div>
                        <div className="oktaAppID form-group col-sm-4 flex-column d-flex">
                          <Controller
                           control={control}
                           name="oktaAppID"
                           render={ ( {field}) => {
                            return(
                              <FormControl fullWidth>
                                <InputLabel id="demo-multiple-chip-label">Okta Application Name*</InputLabel>
                                <Select
                                {...field}
                                label="Okta Application Name*"
                                error={errors.oktaAppID ? true : false}
                                MenuProps={MenuProps}
                                onChange={ (data) =>{
                                  field.onChange(data)
                                  getGroupsByApp(data.target.value)
                                }}
                                >
                                  {
                                    oktaAppIDArray.length > 0 ? 
                                    oktaAppIDArray.map( item => (
                                      <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                                    ))
                                    : null
                                  }
                                </Select>
                              </FormControl>
                            )
                           }}
                          />
                          <Typography variant='inherit' color="error" fontSize="0.75rem">{errors?.oktaAppID?.message}</Typography>
                        </div>
                        <div className="groupID form-group col-sm-4 flex-column d-flex">
                          <Controller 
                            control={control}
                            name="oktaGroupID"
                            render={ ({field}) => {
                              return(
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Group Name*</InputLabel>
                                  <Select
                                    {...field}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    error={errors.oktaGroupID ? true : false}
                                    MenuProps={MenuProps}
                                    value={field.value}
                                    onChange={ (data) => field.onChange(data)}
                                    input={ <OutlinedInput id="select-multiple-chip" label="Group Name*" />}
                                  >
                                    {
                                      groupIDArray && groupIDArray.length > 0 ?

                                      groupIDArray.map( item => {
                                        return(
                                          <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                        )
                                      }) : null
                                    }
                                  </Select>
                                </FormControl>
                              )
                            }}

                          />
                          <Typography variant='inherit' color="error" fontSize="0.75rem">{errors?.oktaGroupID?.message}</Typography>
                        </div>
                        
                      </div>
                      <span
                        className="input-group-btn"
                        style={{ width: "20px" }}
                      ></span>

                      <h5 className='heading'>Create Account Form</h5>
                      <hr />

                      <div className="row  text-left">
                        {/* <div className="policyMessage form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="policyMessage"
                            render={({field}) =>(
                              <TextField {...field} label="policyMessage" error={errors.policyMessage ? true : false} />
                            )}
                          />
                          <Typography variant="inherit" fontSize="0.75rem" color="error">{errors?.policyMessage?.message}</Typography>
                        </div> */}
                        <div className="createAccountInfo form-group col-sm-4 flex-column d-flex">
                          {/* <Field
                            as={TextField}
                            label="Description"
                            variant="outlined"
                            name="appDescription"
                            multiline
                            error={Boolean(props.errors.appDescription && props.touched.appDescription)}
                            required
                            helperText={props.errors.appDescription && props.touched.appDescription && String(props.errors.appDescription)}
                            margin="dense"
                            fullWidth
                          /> */}
                          <Controller 
                              control={control}
                              name="createAccountInfo"
                              render={( { field}) => (
                                <TextField {...field} label="Create Account Info*" error={errors.createAccountInfo ? true : false}/>
                              )}
                          />
                          <Typography variant="inherit" color="error" fontSize="0.75rem">{errors?.createAccountInfo?.message}</Typography>
                        </div>

                        <div className="privacyPolicy form-group col-sm-4 flex-column d-flex">
                          {/* <Field
                            as={TextField}
                            label="Privacy Policy"
                            variant="outlined"
                            name="privacyPolicy"
                            error={Boolean(props.errors.privacyPolicy && props.touched.privacyPolicy)}
                            required
                            helperText={props.errors.privacyPolicy && props.touched.privacyPolicy && String(props.errors.privacyPolicy)}
                            margin="dense"
                            fullWidth
                          /> */}
                          <Controller
                            control={control}
                            name="privacyPolicy"
                            render={({field}) =>(
                              <TextField {...field} label="Privacy Policy" error={errors.privacyPolicy ? true : false} />
                            )}
                          />
                          <Typography variant="inherit" fontSize="0.75rem" color="error">{errors?.privacyPolicy?.message}</Typography>
                        </div>

                        <div className="termsAndConditions form-group col-sm-4 flex-column d-flex">
                          {/* <Field
                            as={TextField}
                            label="Terms & Conditions"
                            variant="outlined"
                            name="termsAndConditions"
                            error={Boolean(props.errors.termsAndConditions && props.touched.termsAndConditions)}
                            required
                            helperText={props.errors.termsAndConditions && props.touched.termsAndConditions && String(props.errors.termsAndConditions)}
                            margin="dense"
                            fullWidth
                          /> */}
                          <Controller
                              control={control}
                              name="termsAndConditions"
                              render={({field})=>(
                                <TextField {...field} label="Terms & Conditions" error={errors.termsAndConditions ? true : false}/>
                              )}
                          />
                          <Typography variant="inherit" color="error" fontSize='0.75rem'>{errors.termsAndConditions?.message}</Typography>
                        </div>

                        <div className="otherIssueURL form-group col-sm-4 flex-column d-flex">
                            <Controller
                              control={control}
                              name='otherIssueURL'
                              render={({field}) =>(
                                <TextField
                                  {...field}
                                  label="Other Help URL*"
                                  error={errors.otherIssueURL}
                                />
                              )}
                            />
                            <Typography variant='inherit' color="error">{errors?.otherIssueURL?.message}</Typography>
                        </div>

                        <div className='enableLoginDropdown form-group col-sm-3 flex-column d-flex'>     
                                        <Controller
                                                control={control}
                                                name="enableLoginDropdown"
                                                render={({ field }) => (
                                                  <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox />}
                                                    label="Enable Login Dropdown"
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    error={errors.enableLoginDropdown}
                                                  />
                                                )}
                                            />
                                            <Typography variant="inherit" color="error">
                                                {errors.enableLoginDropdown?.message}
                                            </Typography>
                        </div>

                        <div className='enableGoogleCaptcha form-group col-sm-3 flex-column d-flex'>     
                              <Controller
                                      control={control}
                                      name="enableGoogleCaptcha"
                                      render={({ field }) => (
                                        <FormControlLabel
                                          {...field}
                                          control={<Checkbox />}
                                          label="Enable Google Captcha"
                                          checked={field.value}
                                          onChange={field.onChange}
                                          error={errors.enableGoogleCaptcha}
                                        />
                                      )}
                                  />
                                  <Typography variant="inherit" color="error">
                                      {errors.enableGoogleCaptcha?.message}
                                  </Typography>
                        </div>

                      </div>
                          

                      <span
                        className="input-group-btn"
                        style={{ width: "20px" }}
                      ></span>

                      <h5 className='heading'>Redirect URI</h5>
                      <hr />

                      <div className="row  text-left">
                        <div className="appRedirectionURL form-group col-sm-4 flex-column d-flex ">
                          <Controller
                                control={control}
                                name="appRedirectionURL"
                                render={ ({field}) => (
                                  <TextField 
                                     {...field}
                                     label="Application URL*"
                                     error={errors.appRedirectionURL ? true : false}
                                  />
                                )}
                          />
                          <Typography variant='inherit' color="error" fontSize="0.75rem"> {errors.appRedirectionURL?.message} </Typography>
                        </div>
                        <div className="signInRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="signInRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="SignIn Redirect URL*" error={errors.signInRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.signInRedirectURL?.message} </Typography>
                        </div>
                        <div className="createRedirectURL form-group col-sm-4 flex-column d-flex">
                <Controller
                  control={control}
                  name="createRedirectURL"
                  render={ ( { field}) => (
                    <TextField {...field} label="Create Account URL" error={errors.createRedirectURL} />
                  )}
                />
              <Typography variant='inherit' color="error"> {errors.createRedirectURL?.message} </Typography>
                        </div>
                        <div className="resetRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="resetRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="Reset Password URL" error={errors.resetRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.resetRedirectURL?.message} </Typography>
                        </div>
                        <div className="activatedRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="activatedRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="Activate Account URL" error={errors.activatedRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.activatedRedirectURL?.message} </Typography>
                        </div>
                        
                        <div className="cancelRedirectURL form-group col-sm-4 flex-column d-flex">
                          <Controller
                            control={control}
                            name="cancelRedirectURL"
                            render={ ( { field}) => (
                              <TextField {...field} label="Cancel Redirect URL" error={errors.cancelRedirectURL} />
                            )}
                          />
                        <Typography variant='inherit' color="error"> {errors.cancelRedirectURL?.message} </Typography>
                        </div>
                        
                      </div>

                      <div className="row justify-content-between text-left">
                        
                      </div>  

                      <span
                        className="input-group-btn"
                        style={{ width: "20px" }}
                      ></span>

                      <hr />

                      <div className="row justify-content-start text-center d-flex align-items-start ">
                        <button
                          className="btn btn-primary m-4"
                          type="button"
                          onClick={handleSubmit(onsubmit)}
                        >
                          Add Basic Details
                        </button>
                        <button
                          className="btn btn-secondary m-4 col-lg-1"
                          type="button"
                          onClick={handleReset}
                        >
                          Clear
                        </button>
                      </div>
                
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BasicAppConfigDetails;
