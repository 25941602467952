import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

import DefaultLogo from '../../../assets/img/LogoBg/DefaultLogo.png'
import DefaultBack from '../../../assets/img/LogoBg/background-img.png'

import './global1.css'

import { useSelector } from "react-redux";

function ForgotU() {
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [data4, setData4] = useState({});

  const appID_redux_updated = useSelector((state) => state)

  async function data2() {
    await axios
      .get(`${REACT_APP_API_ENDPOINT}admin/rendering-service/${appID_redux_updated.allFiles.APPID}`)
      .then((res) => {
        setData4(res.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    data2();
    //eslint-disable-next-line
  }, []);

  const Header_Color = data4.custom_properties?.headerColor 
  const SecondaryButtonColor = data4.custom_properties?.secondaryButtonColor
  const SignIn_button_color = data4.custom_properties?.signInButtonColor;
  const Input_color = data4.custom_properties?.signInButtonColor;

  const fontFamily = data4.custom_properties?.fontFamily;
  const cssFileLink = data4.cssFiles ? data4.cssFiles[0] : null;
  const BackgroundImage = data4.backgroundImg
    ? data4.backgroundImg[0]
    : DefaultBack;
  const logo = data4.logo ? data4.logo[0] : DefaultLogo;

  return (
    <div>

      <>
        <Helmet>
          <link type="text/css" rel="stylesheet" href={cssFileLink} />
        </Helmet>
        <div className="body" style={{ fontFamily: fontFamily }}>
          <div
            className="main-bg forgot-password-sec"
            style={{ backgroundImage: `url(${BackgroundImage})` }}
          >
            <div className="white-wrapper">
              <div className="text-center">
                <div className="snacks-logo">
                  <img src={logo ? logo : DefaultLogo} alt="Logo" />
                </div>
                <h2 className="snacks-title" style={{ color: Header_Color, fontFamily: fontFamily }} >Forgot Password</h2>
              </div>
              <div className="wrapper-inner">

                <div className="form-group">
                  <input
                    className="custom-input"
                    type="text"
                    required
                    name="email"
                    style={{ color: Input_color }}
                  />
                  <label className="custom-label">
                    Forgot Password
                  </label>
                </div>



                <div className="form-group">
                  <button className="btn btn-yellow btn-block" style={{ color: SignIn_button_color, backgroundColor: SecondaryButtonColor }} >Submit</button>
                </div>
                <div className="form-group d-flex justify-content-end align-items-center">
                  <span className="back-sign-in">
                    Back to Sign In?
                  </span>
                  <button
                    type="button"
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      color: "#000000",
                      fontSize: "16px",
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    Click Here
                  </button>
                </div>
                <div className="form-group">
                  <div className="or">
                    <span>Or</span>
                  </div>
                </div>
                <div className="form-group text-center mb-0">
                  <Link
                    to='#'
                    className="create-account btn-text"
                  >
                    Create Account
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default ForgotU;
