import { useHistory } from "react-router";

import { useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";


export const AuthProvider = ({children, route,isLogin,role})=>{

    const Data  =  useParams();
    const conAppId = DOMPurify.sanitize(Data.conAppId)
    // const { oktaAuth } = useOktaAuth();
    const history = useHistory()

    // const Logout = async () => {
    //     // Will redirect to Okta to end the session then redirect back to the configured `postLogoutRedirectUri`
    //     await oktaAuth.signOut()
    //   };
      
    // if(localStorage.getItem('okta-token-storage')){
    //     const resData = JSON.parse(localStorage.getItem('okta-token-storage'));
    //     const accessToken = resData?.accessToken.accessToken;
    //     const roles = resData?.accessToken.claims.pepappconsumerroles;
    //     if(accessToken){
    //         if(conAppId){
    //             if(roles.filter(item=>item==='consumeriam-admin').length>0){
    //                return component;
    //             }else if(roles.filter(item=>item==='consumeriam-'+conAppId).length>0){
    //                 return component;
    //             }else{
    //                 history.push('/dashboard')
    //                 return null
    //             }
    //         }else{
    //             return component
    //         }
    //     }else{
    //         Logout()
    //         return null
    //     }
    // }else{
    //      Logout()
    //     return null
    // }
    let authInitState = {authState:"",isAuthenticated:false};
    const authState = useSelector((state)=> state.authState);
     if(localStorage.getItem('okta-token-storage')){
        const data = JSON.parse(localStorage.getItem('okta-token-storage'));
        const auth = {
            accessToken:data.accessToken,
            idToken:data.idToken
        }
        authInitState = {
            authState : auth,
            isAuthenticated : authState?.isAuthenticated
        }
    }
    
    // else if(authState){
    //     authInitState = {
    //         authState : authState.authState,
    //         isAuthenticated : authState.isAuthenticated
    //     }
    // }

    
   
  
    
    if(authInitState?.authState?.accessToken){
        let appIds = authState?.roles?.map( item => item.split('-')[1])
        // const ExpirationTime = authInitState?.authState?.accessToken?.expiresAt * 1000
        // const CurrentTime = new Date().getTime()
        if(isLogin){
            return  history.push('/dashboard')  
        }else{
    //         setTimeout( async() =>  {
    //         await oktaAuth.tokenManager.renew('accessToken')
    //         .then( function(newToken){
    //             console.log({newToken})
    //         }).catch( err => console.log({err}))
    //  }, 10000)
            if(authState?.roles?.indexOf("consumeriam-admin")!==-1){
                return children;
            }else if(conAppId && appIds?.includes(conAppId) === false){
                
                history.push('/unauthorised')
                return null
            }
            else{
                if(role && role==="ADMIN"){
                    history.push('/unauthorised')
                    return null
                }else{  
                    return children;
                }
            }
        }
    }else{
        if(isLogin){
            return children;
        }else{
            return history.push(route)
        }
        
    } 
    

}

export default AuthProvider;

